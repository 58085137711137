const R = require('ramda')
// const { getDiff } = require('recursive-diff')

window.attemptedRuleApplications = 0
window.attemptedRuleApplicationsLocal = 0

/* TODO
 * what is the difference between an abstract product and the product that a person buys?
 * - TicketDefinition vs Ticket?
 * - Ticket vs CartItem?
 * - Product -> ProductDerivative -> (LineItem)?
 * - Product -> Item -> (LineItem)?
 */
class Product {
  constructor(data = {}) {
    this.rules = data.rules || []
    this.appliedRules = []
    this.data = R.omit(['rules', 'derivatives'], data)
    this.derivativeDefinitions = data.derivatives
    this.history = [this.data]
    this.annotations = ['@@INIT']

    this.buildDerivatives(data)
  }

  buildDerivatives(data, rules) {
    this.derivatives = Object.keys(data.derivatives || {}).map(key => {
      const derivRules = data.derivatives[key]
      const p = new Product({
        ...this.data,
        sku: key,
        rules: (data.rules || rules || []).concat(derivRules),
        parent: this,
      })
      p.applyRules()
      return p
    })

    return this.derivatives
  }

  get name() {
    return this.data.name
  }

  get id() {
    return this.data.id
  }

  talliedDiscounts() {
    const { discounts, clearDiscounts } = this.data
    const ignoreDiscounts = clearDiscounts ? Array.from(clearDiscounts) : []
    const applicableDiscounts = R.omit(ignoreDiscounts, discounts)
    const talliedDiscounts = R.sum(R.values(applicableDiscounts))

    return talliedDiscounts
  }

  get price() {
    const { price: basePrice } = this.data

    const price = Math.max(0, basePrice - this.talliedDiscounts())

    return price
  }

  get priceWithTax() {
    const taxable = this.data.taxable || false
    const { rate, included } = taxable
    const noTaxPrice = this.price

    const taxPrice = taxable ? 
      included ? noTaxPrice : noTaxPrice+noTaxPrice*(rate/100)
      : noTaxPrice
    const roundedTaxPrice = Math.floor( taxPrice * 100 + Number.EPSILON ) / 100

    return roundedTaxPrice
  }

  get priceWithTaxSubtracted() {
    const taxable = this.data.taxable || false
    const { rate, included } = taxable
    const noTaxPrice = this.price

    if(taxable && included) {
      const subtotalAdjustedForTax = noTaxPrice/(1+rate/100)
      const roundedSubtotalAdjustedForTax = subtotalAdjustedForTax === 0
        ? 0
        : Math.ceil( subtotalAdjustedForTax * 100 + Number.EPSILON ) / 100

      return roundedSubtotalAdjustedForTax
    } else {
      return noTaxPrice
    }
  }

  get body() {
    return this.data.body
  }

  get type() {
    return this.data.type
  }

  unappliedRules() {
    return R.difference(this.rules, this.appliedRules)
  }

  applyRules(cart = undefined, user = undefined, rules = this.rules) {
    window.attemptedRuleApplicationsLocal = 0
    const nextData = rules.filter(rule => !!rule).reduce((product, rule) => {
      const r = rule(product, cart, user)
      window.attemptedRuleApplications += 1
      window.attemptedRuleApplicationsLocal += 1
      const { annotation, ...intermediateData } = r
      const appliedRuleMakesNoDifference = R.equals(
        R.last(this.history),
        intermediateData
      )

      let ruleName = rule.toString().substr('function '.length)
      ruleName = ruleName.substr(0, ruleName.indexOf('('))
      if(ruleName === "") {
        // secondLine = rule.toString().split('\n')[1]
        // ruleName = ruleName.match(/[a-zA-Z0-9_-]+/)[0]
        // if(ruleName === "") {
        //   debugger
        // }
      }
      if(false && ruleName !== "") console.info('product#applyRules', {
        appliedRuleMakesNoDifference,
        annotation,
        ruleName,
        historyLength: this.history.length,
        attemptedRuleApplications: window.attemptedRuleApplications,
      })

      if (appliedRuleMakesNoDifference) {
      } else {
        // TODO only update history once per #applyRules, versus here when it's happening on every single rule?
        this.history.push(intermediateData)
        this.annotations.push(annotation)
        this.appliedRules.push(rule)
      }

      return { annotation, data: intermediateData }
    }, this)

    const noDifference = R.equals(this.data, nextData.data)

    if(this.data.slug === 'egypts-sunken-cities' && !this.parent) {
      false && console.info('Product#applyRules wrapping up', {
        noDifference,
        historyLength: this.history.length,
        attemptedRuleApplicationsLocal: window.attemptedRuleApplicationsLocal,
        attemptedRuleApplications: window.attemptedRuleApplications,
        rulesLength: rules.length,
      })
 
      // this diff tracking is super cool, but somehow it crashes the browser
      // when adding a product to the cart?!?
      // const diff = this.data && nextData.data && getDiff(this.data, nextData.data)
      // noDifference || console.table(diff)
    }

    this.data = nextData.data

    // should this return an item instead of a product?
    // do items live inside the product class?
    // how to identify items based on a set of applied rules…
    return nextData
  }

  /* this idea is that this will apply all rules independently to be able to discern
   * what each rule does, and then filter down to just certain rules.
   *
   * For example - only run 'availability' rules
   */
  partiallyApplyRules(filter, cart, user) {
    const filteredRules = this.rules.filter(rule => {
      const intermediateData = rule(this, cart)
      return filter(intermediateData)
    })

    this.applyRules(cart, user, filteredRules)
  }

  // For products that require selecting extra data,
  // that happens here?? TODO
  withData(data) {
    // this.history.push(this.data)
    this.data = { ...this.data, ...data }
    return this
  }

  get sku() {
    const { sku } = this.data
    const name = this.name.replace(/\s+/, '')
    return sku ? name + '-' + sku : name
  }

  get displayName() {
    return this.name
  }

  valid(cart, user) {
    this.applyRules(cart, cart && cart.user)
    const { valid, validate } = this.data
    const errors = this.data.errors || {}

    const noErrors = valid && R.values(errors).length === 0
    return typeof validate === 'function' ? validate(this) : noErrors
  }

  clone(newData, rules) {
    const p = new Product({
      ...this.data,
      ...newData,
      rules: [...(rules || this.rules)],
    })

    p.buildDerivatives({...this.data, derivatives: this.derivativeDefinitions}, rules)
    // should #clone #applyRules?
    p.applyRules()
    return p
  }

  getDerivative(sku) {
    const matchingDeriv =
      this.derivatives.find(deriv => deriv.data.sku === sku) || this

    return matchingDeriv.clone().withData({ ...this.data })
  }

  toLineItem(operator, transactionLocation) {
    const { price, name, id, mda_code, product_type,
      benefit_value: _benefit_value, useBucket, printer_title, printer_subtitle } = this.data
    const operatorId = operator ? operator.salesforce_user_id : null
    // const operatorLocation = operator ? operator.location : null
    const basePrice = parseFloat(price)
    const floatPrice = parseFloat(this.price)
    const floatPriceWithTaxSubtracted = this.priceWithTaxSubtracted

    // TODO handle muptiple GL codes. At this point no event requires more than one
    const gl_code = this.data.gl_codes
      ? this.data.gl_codes[0].gl_code
      : '99-9-9999-999-99999'

    const point_of_sale = transactionLocation

    // TODO `line_item_attributes` vs `item.product_attributes` -
    // what's the difference?
    const line_item_attributes = Object.keys(this.data)
      .filter(
        key =>
          /^selected/.test(key) ||
          [
            'primary_beneficiary',
            'secondary_beneficiary',
            'discounts',
            'ticket_type',
            'priceOverride',
            'paymentMethod',
            'paymentInfo',
          ].indexOf(key) > -1
      )
      .filter(key => key !== 'clearDiscounts')
      .reduce(
        (sliced, key) => {
          sliced[key] = this.data[key]

          if (key === 'selected_time') {
            const [time, meridian] = sliced.selected_time.split(/\s?(am|pm)/)
            const [hours, minutes] = time.split(':')
            const adjusted24HourTime =
              meridian.toLowerCase() === 'am' || hours === '12'
                ? time
                : `${parseInt(hours) + 12}:${minutes}`

            sliced[key] = adjusted24HourTime
          }

          if (key === 'selected_date' && sliced.selected_date) {
            sliced[key] = new Date(sliced.selected_date.setHours(12, 0, 0, 0))
          }

          if (key === 'discounts') {
            const discounts = this.data.clearDiscounts
              ? R.omit(Array.from(this.data.clearDiscounts), sliced.discounts)
              : sliced.discounts

            const largestDiscount = discounts ? R.compose(
              discountNameTransformer,
              R.pathOr(null, [0, 0]),
              R.reverse,
              R.sortBy(R.prop(1)),
              R.toPairs
            )(discounts) : null

            sliced[key] = largestDiscount
          }

          if (id === 'SUSTAINING_MEMBERSHIP') {
            if (key === 'selected_amount') {
              sliced['payment_amount'] = sliced.selected_amount
              delete sliced['selected_amount']
            }

            if (key === 'selected_payment_day') {
              sliced['payment_day'] = sliced.selected_payment_day
              delete sliced['selected_payment_day']
            }
          }

          return sliced
        },
        { ...this.data.providedInformation }
      )

    const benefit_value = R.isNil(_benefit_value)
      ? floatPriceWithTaxSubtracted
      : _benefit_value

    const reservationData = this.data.reservationInfo
      ? {
          reservation_queue_id: this.data.reservationInfo.queue_id,
        }
      : {}
    
    let capacityData
    
    /** TODO 2020-07-07
     * GENERALIZE THIS!
     * Shouldn't need to hardcode a capacityData payload per event.
     * Tie this together with timedTicketWithInterval and checkCapacity rules?
     */
    if(id === 'EX2019-001') {
      capacityData = {
        product_id: 'EX2019-001',
        providerBucket: useBucket || 'general_admission',
        // bucket: 'general_admission', // TODO rename
        // TODO this needs to track what bucket an item was actually sold
        // from - `general_admission / oversell / member_hold / rush`
        quantity: 1
      }
      console.info('product#capacityData', {useBucket, capacityData})
      // debugger
    } else if(id === 'EX2019-TOUR-001') {
      capacityData = {
        product_id: 'EX2019-001',
        providerBucket: 'public_tour',
        // bucket: 'general_admission', // TODO rename
        quantity: 1
      }
    } else if(id === 'EX2019-TOUR-002') {
      capacityData = {
        product_id: 'EX2019-001',
        providerBucket: 'tours',
        // bucket: 'general_admission', // TODO rename
        quantity: this.data.selected_quantity || 15
      }
    } else if(id === 'GEN-FY21-001') {
      capacityData = {
        product_id: 'GEN-FY21-001',
        providerBucket: useBucket || 'general_admission',
        quantity: 1
      }
    } else {
      capacityData = {}
    }

    return {
      unit_price: basePrice,
      tax: Math.round(100*(floatPrice - floatPriceWithTaxSubtracted))/100,
      tax_liability: floatPriceWithTaxSubtracted,
      subtotal: floatPriceWithTaxSubtracted,
      total: floatPrice,
      serialized_items: null, // TODO
      quantity: 1,
      point_of_sale,
      operator_id: operatorId,
      line_item_attributes,
      item_status: 'UNDEFINED',
      item: {
        // tax_liability: floatPriceWithTaxSubtracted,
        product_name: name,
        printer_title,
        printer_subtitle,
        product_id: id,
        product_type: product_type,
        product_attributes: {
          location: this.data.location,
          start_date_time: this.data.start_date_time,
          end_date_time: this.data.end_date_time,
        },
        price: floatPrice,
        MDA_code: mda_code,
        item_id: id,
        GL_code: gl_code,
        gau_code: this.data.gau_code,
        gau_description: this.data.gau_description,
        benefit_value,
      },
      discount: this.talliedDiscounts(),
      benefit_value,
      amount: floatPrice,
      ...reservationData,
      capacityData,
    }
  }
}

// TODO DEDUPE with frontend/containers/core/cart/index.js L334
const discountNameTransformer = codeName => {
  // map internal rule names to external discount names
  const nameChanges = {
    fixedReducedPriceWithMembership: 'My Mia Member',
    upToTwoFreeWithMembership: 'Investor+',
    pctDiscountWAffinity: 'Affinity Member',
    percentageDiscountWithMembership: 'Member',
    myMiaDays: 'My Mia Day',
  }

  return nameChanges[codeName] || codeName
}

export default Product
