import React from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import NewMembership from '../memberships/NewMembership'
import Affinity from './Affinity'
import DisableChromeAutofillHighlight from '../disable-chrome-autofill-highlight'

export default class SingleProductSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: props.startOpen,
    }
  }
  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
    this.props.onClose && this.props.onClose()
  }

  render() {
    const productName = this.props.product.data.name
    const dialogTitle = productName === 'Gift Membership'
      ? productName
      : "Join or Renew your My Mia Membership"

    const buttonSpacing = {
      backgroundColor: '#232323',
      color: '#ffffff',
      margin: '10px 20px 10px 0',
    }
    return (
      <div>
        <DisableChromeAutofillHighlight />
        <FlatButton
          label="Join Or Renew"
          style={buttonSpacing}
          onClick={this.handleOpen}
        />

        <Dialog
          title={dialogTitle}
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          autoScrollBodyContent={true}
          contentClassName="customWidth"
          contentStyle={{paddingTop: 0}}
          autoDetectWindowHeight={false}
          bodyClassName='body!'
          overlayClassName='ooverl lay!'
          style={{paddingTop: '0px !important', color: 'red'}}
          paperClassName='paper!'
        >
          <NewMembership
            closeDialog={this.handleClose.bind(this)}
            products={this.props.products}
            product={this.props.product}
          />
        </Dialog>
      </div>
    )
  }
}

export class AffinityDialog extends React.Component {
  constructor(props) {
    super(props)

    const affinity = this.props.products.find(
      p => p.data.product_type === 'affinity_level'
    )

    this.state = {
      open: false,
      affinity,
    }
  }
  handleOpen = () => {
    this.setState({ open: true })
  }
  handleClose = () => {
    this.setState({ open: false })
  }
  render() {
    const buttonSpacing = {
      backgroundColor: '#232323',
      color: '#ffffff',
      margin: '10px 20px 10px 0',
    }
    return (
      <div>
        <FlatButton
          label="Affinity Group"
          style={buttonSpacing}
          onClick={this.handleOpen}
        />

        <Dialog
          title="Join an Affinity Group"
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          autoScrollBodyContent={true}
          contentClassName="customWidth"
        >
          <Affinity
            products={this.props.products}
            handleClose={this.handleClose.bind(this)}
          />
        </Dialog>
      </div>
    )
  }
}
