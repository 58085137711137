import React, { lazy } from 'react'
import { Route, Link, withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import getMuiTheme from 'material-ui/styles/getMuiTheme'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import FontIcon from 'material-ui/FontIcon'
import Badge from 'material-ui/Badge'

// import Catalogue from '../../components/template/Catalogue'
// import ConfirmToken from '../account/Confirm'
// import LoginForm from '../account/Login'
// import AssignmentView from '../account/AssignmentView'
// import ResetPassForm from '../account/Reset'
// import AuthenticateFromSFDC from '../account/AuthenticateFromSFDC'
// import BankVerificator from '../account/BankVerificator'
// import MasterProductsDashboard from '../master-dashboard'
// import Cart from '../cart'
import Transaction, { TransactionsIndex } from '../transaction' // TODO lazy with destructured import?
// import DailySummary from '../transaction/report'
// import OverUnder from '../transaction/cashdrawer'
// import Product from '../product'
// import Account from '../account'
// import WebPolicy from '../about/WebPolicy'
// import OperatorInfo from '../account/ve-operator'
import Header from '../../components/mia-styleguide/Header'
import Footer from '../../components/mia-styleguide/Footer'
import MuseumClosedBanner from '../../components/MuseumClosedBanner'
// import Checkout from '../../components/checkout/MyCheckout'
import CartSaver from '../../components/cart-saver'
// import Checkout from '../../components/checkout/MyCheckout'

const Catalogue = lazy(() => import('../../components/template/Catalogue'))
const ConfirmToken = lazy(() => import('../account/Confirm'))
const LoginForm = lazy(() => import('../account/Login'))
const AssignmentView = lazy(() => import('../account/AssignmentView'))
const ResetPassForm = lazy(() => import('../account/Reset'))
const AuthenticateFromSFDC = lazy(() => import('../account/AuthenticateFromSFDC'))
const BankVerificator = lazy(() => import('../account/BankVerificator'))
const MasterProductsDashboard = lazy(() => import('../master-dashboard'))
const Cart = lazy(() => import('../cart'))

const AdminCapacity = lazy(() => import('../../admin/capacity'))

// import Transaction, { TransactionsIndex } from '../transaction'
// const { Transaction, TransactionsIndex } = lazy(() => import('../transaction'))
// const tlazy = lazy(() => import('../transaction'))
// console.info({Transaction, TransactionsIndex, tlazy})

const DailySummary = lazy(() => import('../transaction/report'))
const OverUnder = lazy(() => import('../transaction/cashdrawer'))
const Product = lazy(() => import('../product'))
const Account = lazy(() => import('../account'))
const WebPolicy = lazy(() => import('../about/WebPolicy'))
const OperatorInfo = lazy(() => import('../account/ve-operator'))
// const Header = lazy(() => import('../../components/mia-styleguide/Header'))
// const Footer = lazy(() => import('../../components/mia-styleguide/Footer'))
const Checkout = lazy(() => import('../../components/checkout/MyCheckout'))

const muiTheme = getMuiTheme({
  palette: {},
})

const LinkWSpacing = props => (
  <Link style={{ paddingRight: '1em', ...props.style }} to={props.to}>
    {props.children}
  </Link>
)

const App = props => {
  const isVE = props.account && props.account.isVE

  if (isVE) {
    const { operator } = props.account
    if (!operator.drawerOpen && props.location.pathname !== '/account/sfdc') {
      // TODO dispatch some kind of 'flash' notification to show the operator they
      // need to check in with their info?
      // a la rails: `flash[:error] = '…'`
      return <Redirect to="/account/sfdc" />
    }
  }

  const { user } = props.account
  const userHasNoEmail = user
    && (user.email_address === '' || !user.email_address)
    && user.salesforce_account_id !== '0014100000p0GPPAA2'
  const noEmailWarning =
    isVE && userHasNoEmail ? (
      <div>
        <p style={{ color: 'red' }}>
          SalesForce user "{user.first_name} {user.last_name}" has no email!!
        </p>
        <p>
          Email is required to process a credit card payment. If needed, add
          their email to Salesforce and reload this page.
        </p>
      </div>
    ) : (
      <span />
    )

  const sessionName =
    isVE &&
    props.account.operator &&
    (
      props.account.operator.operator_name ||
      props.account.operator.location ||
      ''
    ).replace(/(\d+)$/, ' $1')

  return (
    <MuiThemeProvider muiTheme={muiTheme}>
      <div>
        {props.account.isVE || <Header />}
        <MuseumClosedBanner />
        <header>
          <nav
            style={{ marginLeft: '0', paddingLeft: '0', lineHeight: '2em' }}
            className="secondary-links"
          >
            {!!user || (
              <div>
                <p style={{ paddingRight: '1em' }}>
                  <Link to="/account">Login</Link>,{' '}
                  <Link to="/account">create an account</Link>, or continue as a
                  guest.
                  <LinkWSpacing to="/cart" style={{padding: '1em', textDecoration: 'none', border: 'none'}}>
                    <CartIconWithBadge cart={props.cart} />
                  </LinkWSpacing>
                </p>
              </div>
            )}
            {user &&
              isVE && (
                <div>
                  <LinkWSpacing to="/" style={{textDecoration: 'none', border: 'none'}}>
                    Tickets & Registration
                  </LinkWSpacing>
                  <LinkWSpacing to="/cart" style={{padding: '1em', textDecoration: 'none', border: 'none'}}>
                    <CartIconWithBadge cart={props.cart} />
                  </LinkWSpacing>
                  <LinkWSpacing
                    to="/account"
                    style={{
                      textTransform: 'capitalize',
                      textDecoration: 'none',
                    }}
                  >
                    {user.first_name} {user.last_name} Account Page
                  </LinkWSpacing>
                </div>
              )}{' '}
            {user &&
              !isVE && (
                <div>
                  {user.is_docent && (
                    <LinkWSpacing
                      to="/assignments"
                      style={{
                        textDecoration: 'none',
                        fontFamily: 'Mia Black',
                      }}
                    >
                      View Assignments
                    </LinkWSpacing>
                  )}
                  {window.location.pathname === '/account' || <div>
                    <LinkWSpacing to="/cart" style={{padding: '1em', textDecoration: 'none', border: 'none'}}>
                      <CartIconWithBadge cart={props.cart} />
                    </LinkWSpacing>
                      <LinkWSpacing
                      to="/account"
                      style={{
                        textTransform: 'capitalize',
                        textDecoration: 'none',
                      }}
                    >
                      My Account
                    </LinkWSpacing>
                  </div>}
                </div>
              )}
            {isVE &&
              props.account.operator &&
              props.account.operator.location && (
                <div>
                  <LinkWSpacing
                    to="/account/ve"
                    style={{ fontFamily: 'Mia Black' }}
                  >
                    VE User: {sessionName}
                  </LinkWSpacing>
                  <LinkWSpacing to="/account/ve">Close Drawer</LinkWSpacing>
                </div>
              )}
          </nav>
          {noEmailWarning || <span />}
        </header>

        <main>
          <Route exact path="/" component={Catalogue} />
          <Route exact path="/catalog" component={Catalogue} />
          <Route exact path="/catalog/:group" component={Catalogue} />
          <Route path="/" component={GoogleAnalytics} />
          <Route exact path="/dashboard" component={MasterProductsDashboard} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/account/confirm" component={ConfirmToken} />
          <Route exact path="/account/login" component={LoginForm} />{' '}
          <Route exact path="/account/reset" component={ResetPassForm} />
          <Route exact path="/account/sfdc" component={AuthenticateFromSFDC} />
          <Route exact path="/account/ve" component={OperatorInfo} />
          <Route
            exact
            path="/account/verify_bank"
            component={BankVerificator}
          />
          <Route path="/order/:id" component={Transaction} />
          <Route path="/cashdrawer" component={OverUnder} />
          <Route path="/report" component={DailySummary} />
          <Route path="/assignments" component={AssignmentView} />
          <Route path="/txns" component={TransactionsIndex} />
          <Route path="/products/:slug" component={Product} />
          <Route path="/products/:slug/promos/:promoCode" component={Product} />
          <Route path="/admin/capacity" component={AdminCapacity} />
          <Route path="/web-user-policy" component={WebPolicy} />
        </main>
        {props.account.isVE || <Footer />}
        <CartSaver />
      </div>
    </MuiThemeProvider>
  )
}

// Show the cart icon with a badge.
// Only include the badge when items are in the cart
export const CartIconWithBadge = props => {
  const cartIcon = (
    <FontIcon className="material-icons" style={{ fontSize: '23px' }}>
      shopping_cart
    </FontIcon>
  )

  return props.cart.length > 0 ? (
    <Badge
      badgeContent={props.cart.length > 0 && `${props.cart.length}`}
      primary={true}
      badgeStyle={{ top: 12, right: 15 }}
      className="cart_count"
    >
      {cartIcon}
    </Badge>
  ) : (
    cartIcon
  )
}

const GoogleAnalytics = props => {
  if(!window.ga) return null

  const page = props.location.pathname + props.location.search
  window.ga('set', page)
  window.ga('send', 'pageview')
  return null
} // https://github.com/react-ga/react-ga/issues/122#issuecomment-319546248

const mapStateToProps = state => ({
  cart: state.products.cart,
  account: state.account,
  operator: state.account.operator,
})

// export default App
export default withRouter(connect(mapStateToProps, undefined)(App))
