import React from 'react'
import FlatButton from 'material-ui/FlatButton'
import TextField from 'material-ui/TextField'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { addToCart, modifyProductData } from '../../modules/products'
import Product from '../../core/models/product'

/**
 * A basic vertical non-linear implementation
 */
class Tours extends React.Component {
  constructor(props) {
    super()
    this.state = {
      tour_type: '',
      group_type: '',
      pricing: {
        free: [
          {
            qty: 15,
            price: '0',
            label: '0-15 people - Free',
          },
          {
            qty: 30,
            price: '0',
            label: '16-30 people - Free',
          },
          {
            qty: 45,
            price: '0',
            label: '31-45 people - Free',
          },
          {
            qty: 60,
            price: '0',
            label: '46-60 people - Free',
          },
        ],
        adult: [
          {
            qty: 15,
            price: '390',
            label: '0-15 people - $390',
          },
          {
            qty: 30,
            price: '780',
            label: '16-30 people - $780',
          },
          {
            qty: 45,
            price: '1170',
            label: '31-45 people - $1170',
          },
          {
            qty: 60,
            price: '1560',
            label: '46-60 people - $1560',
          },
        ],
        discounted: [
          {
            qty: 15,
            price: '300',
            label: '0-15 people - $300',
          },
          {
            qty: 30,
            price: '600',
            label: '16-30 people - $600',
          },
          {
            qty: 45,
            price: '900',
            label: '31-45 people - $900',
          },
          {
            qty: 60,
            price: '1200',
            label: '46-60 people - $1200',
          },
        ],
      },
      tour_cost: '',
      tour_qty: '',
    }
    this.handleSelected = this.handleSelected.bind(this)
  }
  calculateCost(qty) {
    if (this.state.group_type === 'adult') {
      let findCost = this.state.pricing.adult
      findCost = findCost.filter(bracket => bracket.qty === qty)

      this.setState({ tour_cost: parseInt(findCost[0].price) })
    }
    if (
      this.state.group_type === 'k12' ||
      this.state.group_type === 'university' ||
      this.state.group_type === 'comp'
    ) {
      this.setState({ tour_cost: 0 })
    }
    if (
      this.state.group_type === 'mia-member' ||
      this.state.group_type === 'non-profit'
    ) {
      let findCost = this.state.pricing.discounted
      findCost = findCost.filter(bracket => bracket.qty === qty)

      this.setState({ tour_cost: parseInt(findCost[0].price) })
    }
  }
  handleSelected(event) {
    var state_name = event.target.name
    this.setState({ [state_name]: event.target.value })
    if (state_name === 'tour_qty') {
      this.setState({ tour_qty: event.target.value }, () =>
        this.calculateCost(parseInt(this.state.tour_qty))
      )
    } else if(state_name === 'org_name') {
    } else {
      // if we change something other than the quantity, reset quantity because
      // now the availability is different. Exclude name as that doesn't matter
      this.setState({tour_qty: undefined})
    }
  }

  render() {
    const state = this.state
    const handleSelected = this.handleSelected

    function TourPricelist(props) {
      var tour_type = state.tour_type
      var group_type = state.group_type
      var pricing = state.pricing
      let pricelist

      if (
        (tour_type === 'guided' || tour_type === 'self-guided') &&
        (group_type === 'k12' ||
          group_type === 'comp' ||
          group_type === 'university')
      ) {
        pricelist = pricing.free.map((group, index) => {
          return <option key={index} value={group.qty} label={group.label} />
        })
      }
      if (tour_type === 'guided' && group_type === 'adult') {
        pricelist = pricing.adult.map((group, index) => {
          return <option key={index} value={group.qty} label={group.label} />
        })
      }

      if (
        tour_type === 'guided' &&
        (group_type === 'mia-member' || group_type === 'non-profit')
      ) {
        pricelist = pricing.discounted.map((group, index) => {
          return <option key={index} value={group.qty} label={group.label} />
        })
      }
      if (
        tour_type === 'self-guided' &&
        (group_type === 'adult' ||
          group_type === 'mia-member' ||
          group_type === 'non-profit')
      ) {
        return (
          <div>
            <p>Reservations not available for this group type.</p>
          </div>
        )
      }

      return (
        <div>
          <h3>Select the Tour Group Size</h3>
          <select
            name="tour_qty"
            onChange={handleSelected}
            value={state.tour_qty}
          >
            <option default>Select Group Size</option>
            {pricelist}
          </select>
        </div>
      )
    }

    return (
      <div className="row">
        <form>
          <h3> Organization Information</h3>
          <TextField
            name="org_name"
            floatingLabelText="Organization/Group Name"
            onChange={this.handleSelected}
          />
          <h3>Select a Tour Type</h3>
          <RadioButtonGroup name="tour_type" onChange={this.handleSelected}>
            <RadioButton value="guided" label="Guided Tour" />
            <RadioButton value="self-guided" label="Self Guided Tour" />
          </RadioButtonGroup>
          <h3>Group Type</h3>
          <RadioButtonGroup name="group_type" onChange={this.handleSelected}>
            <RadioButton value="k12" label="K-12 Group" />
            <RadioButton value="university" label="University Group" />
            <RadioButton value="adult" label="Adult Non-Member Group" />
            <RadioButton value="mia-member" label="Mia Member Group" />
            <RadioButton value="non-profit" label="Non-Profit Group" />
            <RadioButton value="comp" label="Comp Tour" />
          </RadioButtonGroup>

          <TourPricelist
            tour_type={this.state.tour_type}
            group_type={this.state.group_type}
            tour_qty={this.state.tour_qty}
            tour_cost={this.state.tour_cost}
          />
          <TextField
            name="quantity"
            floatingLabelText="Ticket Quantity"
            value={this.state.tour_qty}
            disabled={true}
          />
          <TextField
            floatingLabelText="Total Due"
            value={this.state.tour_cost}
            disabled={true}
          />

          <div>
            <FlatButton
              label="Add to Cart"
              primary={true}
              style={{ float: 'right' }}
              disabled={this.disableAddToCartButton()}
              onClick={this.addToCartClicked.bind(this)}
            />

            <FlatButton
              label="cancel"
              primary={false}
              style={{ float: 'right' }}
              onClick={this.props.closeDialog}
            />
          </div>
        </form>
      </div>
    )
  }

  // We want to disable the button unless all form fields have values
  disableAddToCartButton() {
    const {org_name, tour_type, tour_qty, group_type} = this.state

    const conditions = [
      org_name === '' || org_name === undefined,
      tour_type === '',
      group_type === '',
      tour_qty === undefined,
      parseInt(tour_qty) === 0,
      isNaN(tour_qty),
    ]

    return conditions.find(cond => cond)
  }

  addToCartClicked() {
    const { pricing, tour_cost, tour_qty, ...tourData } = this.state

    const { product_data, selected_date, selected_time } = this.props

    const selectedData = {
      selected_tour_type: tourData.tour_type,
      selected_non_profit: tourData.nonprofit_member && 'nonprofit',
      selected_payment_type: tourData.payment_type,
      selected_quantity: tour_qty,
      selected_org_name: tourData.org_name,
      ...(selected_date ? { selected_date } : {}),
      ...(selected_time ? { selected_time } : {}),
    }

    const product = new Product({
      id: `${product_data.id}-TOURFEE`,
      name: `${product_data.short_title} Tour`,
      product_type: 'tour',
      price: tour_cost,
      valid: true,
      gl_codes: [
        {
          gl_code: '01-1-4120-353-20010',
          calculation_type: 'percentage',
          amount: 100,
        },
      ],
      gau_code: 'Docent Tour Fee',
      unlisted: false,
      ...selectedData,
    })

    this.props.addToCart(product)
    this.props.closeDialog()
  }
}

const mapStateToProps = state => ({
  products: state.products,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addToCart,
      modifyProductData,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Tours)
