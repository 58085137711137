import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getAuthToken, getUser } from './modules/account'

const Contact = props => {
  return (
    <div>
      <p>
        {props.contact.name}
      </p>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    contact: state.account,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAuthToken,
      getUser,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
