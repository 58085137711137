/*global Plaid,Stripe */
import React from 'react'
import { Step, Stepper, StepButton, StepContent } from 'material-ui/Stepper'
import RaisedButton from 'material-ui/RaisedButton'
import FlatButton from 'material-ui/FlatButton'
import TextField from 'material-ui/TextField'
import Checkbox from 'material-ui/Checkbox'
import AddressState from '../utility/AddressState'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'
import ActionFavorite from 'material-ui/svg-icons/action/favorite'
import ActionFavoriteBorder from 'material-ui/svg-icons/action/favorite-border'
import Affinity from '../template/Affinity'
import EmployerMatchingGiftAsk, { getDTDCompanyInfo } from '../template/EmployerMatchAsk'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { addToCart, modifyProductData } from '../../modules/products'
import { chooseStripeKey } from '../../util/stripe'

const ReactMarkdown = require('react-markdown')

/**
 * A basic vertical non-linear implementation
 */
class NewMembership extends React.Component {
  constructor(props) {
    super(props)

    const addresses =
      props.account.identified &&
      props.account.user &&
      props.account.user.addresses &&
      props.account.user.addresses.length > 0 &&
      props.account.user.addresses

    const user = props.account.identified && props.account.user

    const { account, product } = this.props
    const isGiftMembership = product.data.id === 'GIFT_MEMBERSHIP'
    const enableSustaining = !isGiftMembership
    const prefillUserInfo = !isGiftMembership && account.user

    const enableACHSustainingSignup = true

    this.state = {
      isGiftMembership,
      enableSustaining,
      stepIndex: 0,
      primary_contact: {
        first_name: prefillUserInfo
          ? account.user.first_name
          : '',
        last_name: prefillUserInfo ? account.user.last_name : '',
        phone: prefillUserInfo ? account.user.phone : '',
        email_address: prefillUserInfo
          ? account.user.email_address
          : '',
        street_address_1: prefillUserInfo && addresses ? addresses[0].street_address : '',
        street_address_2: '',
        city: prefillUserInfo && addresses ? addresses[0].city : '',
        address_state: prefillUserInfo && addresses ? addresses[0].state : '',
        postal_code: prefillUserInfo && addresses ? addresses[0].postal_code : '',
        ...((user && prefillUserInfo)
          ? {
              salesforce_account_id: user.salesforce_account_id,
              salesforce_contact_id: user.salesforce_contact_id,
            }
          : {}),
      },
      secondary_contact: {
        first_name: '',
        last_name: '',
        phone: '',
        email_address: '',
        street_address_1: addresses
            ? addresses[0].street_address
            : '',
        street_address_2: '',
        city: addresses
            ? addresses[0].city
            : '',
        address_state: addresses
            ? addresses[0].state
            : '',
        postal_code: addresses
            ? addresses[0].postal_code
            : '',
      },
      membership_donation: {
        membership_donation: '60',
        paymentDay: '1'
      },
      membership_type: {sustainingMembership: enableSustaining},
      ach: false,
      plaid: false,
      sustainingMembershipPaymentMethod: enableACHSustainingSignup ? 'plaid' : 'cc_sustainer',
      enableACHSustainingSignup,
    }
  }

  handleNext = () => {
    const steps = this.steps()
    const { stepIndex } = this.state
    if (stepIndex < steps.length) {
      this.setState({ stepIndex: stepIndex + 1 })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const shouldResizeDialog = prevState.stepIndex !== this.state.stepIndex ||
      prevState.sustainingMembershipPaymentMethod !== this.state.sustainingMembershipPaymentMethod

    if(shouldResizeDialog) {
      const resize = () => window.dispatchEvent(new Event('resize'))
      resize()
      setTimeout(resize, 300)
      setTimeout(resize, 700)
      // doing this 3 times gets really jumpy… but only once doesn't really work either?
    }
  }

  handlePrev = () => {
    const { stepIndex } = this.state
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1 })
    }
  }

  renderStepActions(step) {
    const steps = this.steps()
    const numSteps = steps.length-1

    const _disFn = steps[step]['disableNext']
    const disabled = _disFn && _disFn()

    return (
      <div style={{ margin: '12px 0' }}>
        {step > 0 && (
          <FlatButton
            label="Back"
            disableTouchRipple={true}
            disableFocusRipple={true}
            onClick={this.handlePrev}
          />
        )}
        {step < numSteps && (
          <RaisedButton
            label="Next"
            disableTouchRipple={true}
            disableFocusRipple={true}
            primary={true}
            onClick={this.handleNext}
            style={{ marginRight: 12 }}
            disabled={disabled}
          />
        )}
        {step === numSteps && (
          <RaisedButton
            label="Add To Cart"
            disableTouchRipple={true}
            disableFocusRipple={true}
            primary={true}
            onClick={this.buildMembershipAndPutInCart.bind(this)}
            style={{ marginRight: 12 }}
          />
        )}
      </div>
    )
  }

  render() {
    const { stepIndex } = this.state

    return (
      <div className="row">
        <form
          onBlur={this.updateInfo.bind(this)}
          onChange={this.updateInfo.bind(this)}
        >
          <Stepper activeStep={stepIndex} linear={true} orientation="vertical">
            {this.steps().map((step, index) => {
              return <Step key={[index, step]}>
                <StepButton onClick={() => this.setState({ stepIndex: index })}>
                {step.name}
                </StepButton>
                <StepContent>
                  {step.children}

                  {this.renderStepActions(index)}
                </StepContent>
             </Step>
            })}
          </Stepper>
        </form>
      </div>
    )
  }

  steps() {
    const spacing = {
      marginRight: '10px',
    }

    const {
      membership_donation,
      membership_donation_freeform,
      paymentDay,
    } = this.state.membership_donation
    const chosenAmount = membership_donation_freeform || membership_donation
    const isSustaining = this.isSustainingMembership()
    const chosenAmountYearly =
      isSustaining && membership_donation_freeform
        ? chosenAmount * 12
        : chosenAmount
    const chosenAmountIsInvestor = chosenAmountYearly >= 150
    const { isVE } = this.props.account
    // TODO should the payment methood radio be disabled once
    // a method is successfully chosen? This makes it hard to get confused -
    // i.e. select plaid, go through the workflow, then change your mind and go for
    // manual ach…
    // we don't want multiple plaid auths because we'll get charged extra
    // but also shouldn't make the form hard to interact with by disabling fields…
    const { sustainingMembershipPaymentMethod } = this.state
    const plaidSelectedAndComplete =
      sustainingMembershipPaymentMethod === 'plaid' && this.state.plaid
    const manualACHSelectedAndComplete =
      sustainingMembershipPaymentMethod === 'ach_manual' && this.state.stripeACH
    const disablePaymentMethodSelection = !this.state.enableACHSustainingSignup ||
      plaidSelectedAndComplete || (false && manualACHSelectedAndComplete)

    const {isGiftMembership, enableSustaining} = this.state

    const { email_address } = this.state.primary_contact
    const actingUserEmail = this.props.account && this.props.account.user && this.props.account.user.email_address
    const validEmail = email_address && email_address.match(/.*@.*\..*/)
    const notActingUserEmail =  email_address !== actingUserEmail
    // TODO co-locate this validation code in the step where it's relevant somehow?

    const disableEmailModification = !isGiftMembership && isVE && actingUserEmail

    return [
      enableSustaining ? {
        name: `Membership Type`, // TODO put membership type in name if gift or sustaining
        id: 'membership_type',
        children: <div>
          {enableSustaining && <div>
            <br />
            <Checkbox
              label="Sustaining Contribution"
              checked={isSustaining}
              name="sustainingMembership"
            />
            <br />
            <Checkbox
              label="One Time Contribution"
              checked={!isSustaining}
              name="oneTimeMembership"
            />
          </div>}
        </div>
      } : false,
      {
        name: isGiftMembership ? 'Gift Membership Contribution' : 'Membership Contribution',
        id: 'membership_donation',
        disableNext: () => {
          const {
            membership_donation: level_amount,
            membership_donation_freeform: free_amount
          } = this.state.membership_donation

          const amt = free_amount || level_amount
          const disable = !amt || (isSustaining && amt === '0')

          return disable
        },
        children: <div>
          <ReactMarkdown source={this.props.product.data.summary} />

          <MembershipLevelSelector
            onChange={this.updateInfo.bind(this)}
            membership_donation={this.state.membership_donation.membership_donation}
            isSustaining={isSustaining}
            isGiftMembership={isGiftMembership}
            isVE={isVE}
          />

          {(!isGiftMembership || isVE) && <>
            <label style={{marginTop: '2em', display: 'block'}}>
              I would love to join My Mia and donate the following amount:
              <TextField
                name="membership_donation_freeform"
                floatingLabelText={isSustaining ? "Monthly Contribution Amount" : "Contribution Amount"}
                onChange={this.updateInfo.bind(this)}
                style={{marginTop: '-1em'}}
              />
            </label>
          </>}

          {this.props.product.data.messages?.map(message =>
            <p>{message}</p>
          )}

          {isSustaining && <div>
            What day of the month would you like to make your monthly donation?

            <RadioButtonGroup
              name="paymentDay"
              defaultSelected={paymentDay}
              onChange={(event, day) => this.setState({
                membership_donation: {
                  ...this.state.membership_donation,
                  paymentDay: day,
                }
              })}
            >
              <RadioButton value="1" label="The 1st of each month" />
              <RadioButton value="15" label="the 15th of each month" />
            </RadioButtonGroup>
          </div>}
        </div>
      },
      {
        name: isGiftMembership ? 'Gift Membership Recipient Information' : 'Member Information',
        id: 'primary_contact',
        disableNext: () => !this.state.primary_contact.postal_code,
        children: <div>
          <TextField
            floatingLabelText="First Name"
            name="first_name"
            value={this.state.primary_contact.first_name}
            errorText={
              !this.state.primary_contact.first_name && 'required'
            }
            style={spacing}
          />
          <TextField
            floatingLabelText="Last Name"
            name="last_name"
            errorText={
              !this.state.primary_contact.last_name && 'required'
            }
            value={this.state.primary_contact.last_name}
            style={spacing}
          />
          <TextField
            floatingLabelText="Email"
            name="email_address"
            errorText={
              (!validEmail && 'required')
                || (isGiftMembership && !notActingUserEmail && "Gift recipient email must differ from purchaser email")
                || (disableEmailModification && 'Email editing disabled - update in Salesforce contact')
            }
            errorStyle={disableEmailModification ? {color: 'green', opacity: 0.5} : {}}
            disabled={disableEmailModification}
            value={this.state.primary_contact.email_address}
            style={spacing}
          />
          <TextField
            floatingLabelText="Phone xxx-xxx-xxxx"
            name="phone"
            value={this.state.primary_contact.phone}
            style={spacing}
            maxLength="12"
          />
          <br />
          <TextField
            floatingLabelText="Address 1"
            name="street_address_1"
            errorText={
              !this.state.primary_contact.street_address_1 && 'required'
            }
            value={this.state.primary_contact.street_address_1}
            style={{ width: '75%' }}
          />
          <br />
          <TextField
            floatingLabelText="Apt.#, Unit #, etc."
            name="street_address_2"
            value={this.state.primary_contact.street_address_2}
            style={{ width: '75%' }}
          />
          <br />
          <TextField
            floatingLabelText="City"
            name="city"
            errorText={!this.state.primary_contact.city && 'required'}
            value={this.state.primary_contact.city}
            style={{ marginRight: '10px', float: 'left' }}
          />
          <AddressState
            address_state={this.state.primary_contact.address_state}
            required={true}
          />
          <TextField
            floatingLabelText="Postal Code"
            name="postal_code"
            value={this.state.primary_contact.postal_code}
            errorText={
              !this.state.primary_contact.postal_code && 'required'
            }
            style={spacing}
            maxLength="5"
          />
        </div>
      },
      (isSustaining ? {
        name: 'Monthly Payment method',
        id: 'monthly_payment',
        disableNext: () => {
          const method = this.state.sustainingMembershipPaymentMethod
          const paymentValid = method === 'cc_sustainer'
            || (method === 'plaid' && this.state.plaid)
            || (method === 'ach_manual' && this.state.stripeACH)

          return !method || !paymentValid
        },
        children: <div>
          <p>
            Thank you for your contribution. Once you complete your
            transaction you will receive a receipt and a follow up email
            with more information about your Sustaining Membership.
          </p>

          <RadioButtonGroup
            name="sustainingMembershipPaymentMethod"
            defaultSelected={this.state.enableACHSustainingSignup ? isVE ? 'ach_manual' : 'plaid' : 'cc_sustainer'}
            onChange={(event, method) => this.setState({
              sustainingMembershipPaymentMethod: method,
              // plaid: false, // should it really wipe the already authenticated method?
              // ach: false, // it would be slicker to save it?
              // we probably do want to save this for the user's sake
              // and also because plaid costs per-authentication
            })}
          >
            <RadioButton
              value="plaid"
              disabled={isVE || disablePaymentMethodSelection}
              label="Login through your online bank" />
            <RadioButton
              value="ach_manual"
              disabled={disablePaymentMethodSelection}
              label="Manually add a Bank Account" />
            <RadioButton
              value="cc_sustainer"
              disabled={disablePaymentMethodSelection}
              label="Pay with your Credit Card" />
          </RadioButtonGroup>

          {(sustainingMembershipPaymentMethod === ('plaid' && this.state.plaid)
            || (sustainingMembershipPaymentMethod === 'ach_manual' && this.state.stripeACH)
           ) ? <div style={{marginTop: '1em'}}>
            Bank info saved! <p onClick={() => this.setState({plaid: false, stripeACH: false})}>Clear</p>
          </div> : <div style={{marginTop: '1em'}}>
            {(this.state.sustainingMembershipPaymentMethod === 'plaid' || this.state.sustainingMembershipPaymentMethod === undefined) && <PlaidTrigger
              liftPlaidCredentials={this.savePlaidInfo.bind(this)}
            />}
            {this.state.sustainingMembershipPaymentMethod === 'ach_manual' && <div>
              {!this.state.stripeACH ? <div>
                Enter your bank account information
                  <BankInfoForm
                    liftToken={this.saveStripeACHInfo.bind(this)}
                    token={this.props.stripeACH}
                    primary_contact={this.state.primary_contact}
                  />
              </div> : <div><p>Bank Info Saved</p></div>}
            </div>}
					</div>}
          {this.state.sustainingMembershipPaymentMethod === 'cc_sustainer' && <div>
            The credit card you enter when checking out will be used as
            the source for this sustaining membership.
          </div>}
        </div>
      } : false),
      (chosenAmountIsInvestor ? {
        name: 'Add an Affinity',
        id: 'affinity',
        children: (isGiftMembership ? <div>
          <p>Mia’s Affinity Groups are a great way for museum members to connect more closely with special areas of art interest.
            The recipient may select their
            <a rel="noopener noreferrer" target="_blank" href="https://new.artsmia.org/join-and-invest/affinity-groups/">Affinity Group</a>
            upon receipt of the gift membership.
          </p>
        </div> : <div>
          <p>{chosenAmountIsInvestor
            ? 'Investor Members get one affinity group with their membership. Additional groups can be joined for $150. Patron and above member levels recieve additional complimentary affinity memberships. Contact Mia (call 888 642 2787 or email visit@artsmia.org) to change or add new affinity memberships.'
            : 'At the Contributor membership level ($1-149), joining an Affinity group costs $150. Upgrade to the Investor level and your affinity membership is free!'
            }</p>
          <Affinity
            primary_contact={this.state.primary_contact}
            secondary_contact={this.state.secondary_contact}
            products={this.props.products}
            onChange={this.handleAffinityChange.bind(this)}
            onlyBuildAffinity={true}
          />
        </div>)
      } : false),
      (isGiftMembership ? false : {
        name: 'Add a Secondary Contact',
        id: 'secondary_contact',
        children: (
          <div>
            <p>
              If you would like to add another member of your household to your
              membership at this time please fill out the information below. You
              may do this at another time by calling (612) 870-3000.
            </p>
            <TextField
              floatingLabelText="First Name"
              name="first_name"
              value={this.state.secondary_contact.first_name}
              style={spacing}
            />
            <TextField
              floatingLabelText="Last Name"
              name="last_name"
              value={this.state.secondary_contact.last_name}
              style={spacing}
            />
            <TextField
              floatingLabelText="Email"
              name="email_address"
              value={this.state.secondary_contact.email_address}
              style={spacing}
            />
          </div>
        ),
      }),
      (isGiftMembership ? false : {
        name: 'Add an Employer Match',
        id: 'employer_match',
        children: (
          <div>
            <EmployerMatchingGiftAsk />
          </div>
        )
      })
    ].filter(step => step) // remove false steps
  }

  handleAffinityChange(affinity) {
    console.info('handleAffinityChange', { affinity })
    this.setState({ affinityProduct: affinity, affinity: affinity })
  }

  savePlaidInfo(public_token, account_id, metadata) {
    this.setState({
      sustainingMembershipPaymentMethod: 'plaid',
      plaid: { public_token, metadata },
    })
  }
  saveStripeACHInfo(result) {
    if (result.error) return

    this.setState({
      sustainingMembershipPaymentMethod: 'ach_manual',
      stripeACH: result,
      stripeAchToken: result.id,
    })
  }

  updateInfo(event) {
    const stepNames = this.steps().map(step => step.id)
    const stepName = stepNames[this.state.stepIndex]
    const { name, value, checked } = event.target

    // nest `secondary_contact` within `enhancements` when it is updated
    // TODO would it be better to put `secondary_contact` fields directly inside
    // `enhancements` and extract them differently? Tomato / Tom-ah-to?
    const changingSecondaryContact =
      ['first_name', 'last_name', 'email_address'].indexOf(name) > -1 &&
      stepName === 'enhancements'

    // TODO does SF accept a secondary contact with just name and email fields, or
    // must address be duplicated?

    const d = {
      // Alternate between standard and gift membership checkboxes
      ...(name === 'sustainingMembership' || name === 'oneTimeMembership' ? {
        sustainingMembership: false,
      } : {}),
      // Set this value
      [name]: value === 'on' || value === 'true' || (value === "" && name.match(/membership/i)) ? checked : value,
    }

    const nextData = changingSecondaryContact
      ? {
          secondary_contact: {
            ...this.state.secondary_contact,
            ...d,
          },
        }
      : d

    const nextState = {
      [stepName]: {
        ...this.state[stepName],
        ...nextData,
      },
    }

    this.setState(nextState)
  }

  buildMembershipAndPutInCart() {
    const {
      primary_contact,
      membership_donation: {
        membership_donation,
        membership_donation_freeform,
      },
      affinity,
      affinityProduct,
      secondary_contact,
    } = this.state

    // Get membership
    // if both are provided, how do we know which one of these the user really meant?
    const customAmount =
      membership_donation_freeform &&
      parseFloat(membership_donation_freeform.replace(/[^(\d+|.)]*/g, ''))
    const intendedMembershipDonation = customAmount || membership_donation

    const isGiftMembership = this.props.product.data.id === 'GIFT_MEMBERSHIP' // this.state.membership_type.gift_membership

    const normalizeContact = contact => {
      const {
        first_name,
        last_name,
        phone,
        email_address,
        salesforce_account_id,
        salesforce_contact_id,
        // ...addressData
      } = contact

      return {
        first_name,
        last_name,
        phone,
        email_address,
        addresses: [
          {
            city: contact.city,
            country: null,
            postal_code: contact.postal_code,
            state: contact.address_state,
            street_address: [contact.street_address_1, contact.street_address_2]
              .join(' ')
              .trim(),
            type: 'Home',
          },
        ],
        ...(!isGiftMembership
          ? {
              salesforce_account_id,
              salesforce_contact_id,
            }
          : {}),
        is_cardholder: true,
      }
    }

    const contactDataNormalized = {
      primary_beneficiary: normalizeContact(primary_contact),
      secondary_beneficiary:
        secondary_contact && secondary_contact.first_name !== ''
          ? normalizeContact(secondary_contact)
          : null,
    }

    const dtdInfo = getDTDCompanyInfo()

    // # TODO handle errors when data is not available

    const membership = this.props.products
      .find(p => p.data.id === 'MEMBERSHIP')
      .clone()
      .withData({
        ...contactDataNormalized,
        selected_amount: intendedMembershipDonation,
        providedInformation: dtdInfo,
      })

    const giftMembership = this.props.products
      .find(p => p.data.id === 'GIFT_MEMBERSHIP')
      .clone()
      .withData({
        ...contactDataNormalized,
        selected_amount: intendedMembershipDonation,
        providedInformation: dtdInfo,
      })

    const paymentMethod = this.state.sustainingMembershipPaymentMethod
    const sustainingPaymentInfo =
      paymentMethod === 'plaid'
        ? this.state.plaid
        : paymentMethod === 'ach_manual' ? this.state.stripeACH : undefined

    const roundedMembershipAmount =
      intendedMembershipDonation > 150
        ? Math.round(intendedMembershipDonation / 12)
        : intendedMembershipDonation / 12
    const sustainingMembership = this.props.products
      .find(p => p.data.id === 'SUSTAINING_MEMBERSHIP')
      .clone()
      .withData({
        ...contactDataNormalized,
        paymentMethod,
        paymentInfo: sustainingPaymentInfo,
        selected_amount: membership_donation_freeform
          ? intendedMembershipDonation
          : roundedMembershipAmount,
        selected_payment_day: parseInt(
          this.state.membership_donation.paymentDay || 1
        ),
        // price is picked up and charged on the next payment date based on selected_amount
        price: 0,
        providedInformation: dtdInfo,
      })

    const product = this.isSustainingMembership()
      ? sustainingMembership
      : isGiftMembership
        ? giftMembership
        : membership
    this.props.addToCart(product)

    // TODO - somehow this.state.affinity is an object, which is should be a class:Product
    // in handleAffinityChange I'm setting a second state variable, affinityProduct, which does
    // come through as a `Product`. works for now?>???
    if (affinity && affinity.data && affinity.data.selected_affinity)
      this.props.addToCart(affinityProduct)

    this.props.closeDialog()
  }

  isSustainingMembership() {
    const { membership_type } = this.state
    return membership_type && membership_type.sustainingMembership
  }
}

const mapStateToProps = state => ({
  account: state.account,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addToCart,
      modifyProductData,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(NewMembership)

const MembershipLevelSelector = props => {
  const { onChange, membership_donation, isSustaining, isGiftMembership, isVE } = props

  const levels = [
    !isGiftMembership ? {
      value: '0',
      text1: 'No, thanks.',
      text2: 'I would love to join My Mia and donate another time.',
      sustaining: false,
    } : false,
    {
      value: '20',
      text1: '$20',
      text2: 'Donor',
      sustaining: false,
      disabled: isGiftMembership && !isVE,
      disabledMessage: 'For other pricing options, please call 612-870-3000',
    },
    {
      value: '60',
      text1: '$60',
      text2: 'Contributor',
      sustaining: false,
      initialValue: true
    },
    {
      value: '150',
      text1: '$150',
      text2: 'Investor',
      sustaining: 12.50,
    },
    {
      value: '500',
      text1: '$500',
      text2: 'Partner',
      sustaining: 42,
    },
    {
      value: '2500',
      text1: '$2500',
      text2: 'Patron',
      sustaining: 208,
    },
  ]
    .map(level => {
      // const sustainingAmt = parseFloat(level.value/12)
      const sustainingAmt = level.sustaining && level.sustaining.toFixed(2)

      if (isSustaining) {
        if (!sustainingAmt) return false // can't buy a $0 sustaining membership. Also don't promote $1 a month

        level.text1 += ` ($${sustainingAmt}/month)`
      }

      return level
    })
    .filter(level => level)

  const valueSelected = membership_donation || levels[1].value

  return (
    <><RadioButtonGroup
      name="membership_donation"
      onChange={onChange}
      defaultSelected={levels[1].value}
      valueSelected={valueSelected}
    >
      {levels
        .filter(({disabled, disabledMessage}) => !disabled)
        .map(({ value, text1, text2, disabled, disabledMessage }) => {
        return (
          <RadioButton
            key={value}
            value={value}
            checkedIcon={<ActionFavorite style={{ color: '#F44336' }} />}
            uncheckedIcon={<ActionFavoriteBorder />}
            disabled={disabled}
            label={
              <div>
                <h3>
                  {disabled || <span style={{opacity: disabled ? 0.3 : 1}}>
                    {text1}{' '}
                  </span>}
                  <span>
                    {!disabled && value !== '0' ? ' - ' : ''}
                    {disabled ? disabledMessage : text2}
                  </span>
                </h3>
              </div>
            }
          />
        )
      })}
    </RadioButtonGroup>

      {levels
          .filter(({disabled, disabledMessage}) => disabled)
          .map(({disabledMessage}) => <p>{disabledMessage}</p>)
      }
    </>
  )
}

class PlaidTrigger extends React.Component {
  componentDidMount() {
    const { liftPlaidCredentials } = this.props

    this.linkHandler = Plaid.create({
      selectAccount: true,
      clientName: 'Stripe/Plaid Test',
      env: process.env.NODE_ENV === 'production' ? 'production' : 'sandbox',
      key: 'cde8ef5a4d69fbc4cb35ce10001379',
      product: ['auth'],
      onLoad: function() {
        console.info('plaid onLoad')
      },
      onEvent: function(eventName, metadata) {
        console.info('event', {eventName, metadata})
      },
      onSuccess: function(public_token, metadata) {
        console.info('onSuccess', {public_token, metadata})
        liftPlaidCredentials(public_token, metadata.account_id, metadata)
      },
      onExit: function(err, metadata) {
        // The user exited the Link flow.
        console.error(err)
        if (err != null) {
          // The user encountered a Plaid API error prior to exiting.
        }
      },
    })
  }

  render() {
    return <div
      onClick={() => this.linkHandler.open()}
      style={{textDecoration: 'underline'}}
    >
      <p>Authenticate through online banking</p>
      <p>Clicking here will open a service that authorizes a monthly direct transfer from your bank account.</p>
    </div>
  }
}

class BankInfoForm extends React.Component {
  componentDidMount() {
    const stripePublishableKey = chooseStripeKey(this.props.account)
    this.stripe = Stripe(stripePublishableKey)
    console.info('setting stripe key', {stripePublishableKey, Stripe, _thisStripe: this.stripe})
    Stripe.setPublishableKey(stripePublishableKey)
  }

  saveBankInfo() {
    const {routing_number, account_number} = this.state || {} // processing
    const {primary_contact} = this.props
    const name = `${primary_contact.first_name} ${primary_contact.last_name}`

    this.setState({processing: true})
    const _this = this
    this.stripe.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      routing_number,
      account_number,
      account_holder_name: name,
      account_holder_type: 'individual',
    }).then(function(result) {
      console.info('stripe bank token', {result, _this}, this)

      // TODO error handling

      _this.setState({token: result.id, processing: false, error: result.error})
      _this.props.liftToken(result)
      // Once there's a token, lift it up to the mega-form so
      // it can be passed on as a `line_item_attribute`
      // While doing the stripe validation, change state to 'processing'?
    })
  }

  updateInfo(event) {
    const { name, value } = event.target

    this.setState({[name]: value})
  }

  render() {
    const spacing = {
      marginRight: '10px',
    }

    const {error, processing} = this.state || {}

    return <div>
      {this.props.token ? 'Bank info saved' : <form
        onBlur={this.updateInfo.bind(this)}
        onChange={this.updateInfo.bind(this)}
        action="#"
        onSubmit={(event) => {
          event.preventDefault()
          event.stopPropagation()
          return false
        }}
      >
        <TextField
          floatingLabelText="Routing Number"
          name="routing_number"
          // value={routing_number}
          errorText={
            error && error.param === 'bank_account[routing_number]' && error.message
          }
          style={spacing}
          disabled={processing}
        /><br />
        <TextField
          floatingLabelText="Account Number"
          name="account_number"
          // value={account_number}
          errorText={
            error && error.param === 'bank_account[account_number]' && error.message.split('.')[0]
          }
          style={spacing}
          disabled={processing}
        /><br />
        {processing ?
          <p>Saving…</p> :
          <button onClick={this.saveBankInfo.bind(this)}>Save</button>
        }

        <p>Within a few days of providing your bank account information, you should
           see two small transactions in your accouont from Mia. You'll recieve an email
           asking you to verify these amounts, which will allow monthly bank transfers to
           begin.</p>
      </form>}
    </div>
  }
}
