/* global doublethedonation */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import R from 'ramda'
import Moment from 'react-moment'
import qr from 'qr-image'

import { Link, Redirect } from 'react-router-dom'
import products from '../../data/productsJson.js'
import { adjustDataForPrint } from '../../util/print'
import { selectDetailsFromData } from '../cart'
import { txnLocationFromAccount } from '../../util/stripe'

class Transaction extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ticketSelection: [],
      initialPrint: true,
      pushToDataLayer: true || props.pushToDataLayer,
    }
  }

  componentWillMount() {
    this.fetchTransaction()
  }
  componentWillUpdate(nextProps, nextState) {
    if (nextProps.match.params.id !== this.props.match.params.id)
      this.fetchTransaction(nextProps.match.params.id)
  }
  fetchTransaction(_id) {
    const id = _id || this.props.match.params.id
    axios.get(`/v1/orders/id/${id}`).then(response => {
      const dataWithPrintableTickets = adjustDataForPrint(response.data)

      this.setState({
        response,
        data: response.data,
        ticketSelection: dataWithPrintableTickets.line_items.map(li => {
          return li.print && li.status !== 'refunded'
        }),
      })
    })
  }

  printViaWebsocket(data) {
    var ws = new WebSocket('ws://localhost:8081/echo')
    console.group('printViaWebsocket')
    console.info(data)

    ws.onopen = function() {
      // Web Socket is connected, send data using send()
      ws.send(JSON.stringify(data))
      console.info('data sent to websocket')
    }

    ws.onmessage = function(evt) {
      console.info('websocket received', evt)
    }

    ws.onclose = function() {
      console.info('websocket closed')
    }

    ws.onerror = err => console.error('websocket print error', err)
    console.groupEnd()
  }

  printSelectedTickets() {
    const { data: rawData, ticketSelection } = this.state
    const dataWithPrintableTickets = adjustDataForPrint(rawData)
    const printableItems = dataWithPrintableTickets.line_items.map(
      (line, index) => {
        return { ...line, print: line.print && ticketSelection[index] }
      }
    )

    const data = { ...dataWithPrintableTickets, line_items: printableItems }

    this.printViaWebsocket(data)
  }

  refundSelectedItems() {
    this.setState({ refundProcessing: true })
    try {
      const { data, ticketSelection } = this.state

      const selectedItems = data.line_items.filter(
        (item, index) => ticketSelection[index]
      )

      const {operator} = this.props.account
      const { location, salesforce_user_id, operator_name } = operator

      const refundJson = {
        originating_transaction_id: data.transaction_id,
        amount: R.sum(selectedItems.map(item => item.amount)),
        line_item_ids: selectedItems.map(item => item.line_item_id),
        point_of_sale: txnLocationFromAccount(this.props.account),
        operator_detail: {
          salesforce_user_id,
          location,
          operator_name,
          salespoint: txnLocationFromAccount(this.props.account),
        },
      }

      console.info('refundSelectedItems', {
        selectedItems,
        refundJson,
        j: JSON.stringify(refundJson),
      })

      axios.post(`/v1/orders/refund`, refundJson).then(response => {
        // TODO update somehow
        // handle errors
        console.info('/v1/orders/refund response', { response })

        if (typeof response.data === 'string') {
          console.error('Refund problem! response.data is a string')
          return this.setState({
            refundError: 'Bad data received from API after attempting refund',
            refundProcessing: false,
          })
        }

        if (response.data.error) {
          return this.setState({
            refundError: response.data.error,
            refundProcessing: false,
          })
        }

        axios
          .get(`/v1/orders/id/${data.transaction_id}?date=${new Date()}`)
          .then(response => {
            const dataWithPrintableTickets = adjustDataForPrint(response.data)

            console.info('refunded transaction re-fetched', {
              line_items: response.data.line_items,
              lineItemStats: response.data.line_items.map(li => li.status),
            })

            this.setState({
              refundError: false,
              refundProcessing: false,
              response,
              data: response.data,
              ticketSelection: dataWithPrintableTickets.line_items.map(li => {
                return li.print && !li.status === 'refunded'
              }),
            })
          })
      })
    } catch (e) {
      console.error('Refund problem!', { e })
      this.setState({
        refundError: 'could not initiate refund',
        refundProcessing: false,
        error: e,
      })
    }
  }

  updateSelectedTickets(item) {
    const line = this.state.data.line_items.find(
      i => i.line_item_id === item.line_item_id
    )
    const index = this.state.data.line_items.indexOf(line)
    const { ticketSelection } = this.state
    const nextTicketSelection = ticketSelection.splice(
      index,
      1,
      !ticketSelection[index]
    )
    console.info('updateSelectedTickets', {
      index,
      ticketSelection,
      nextTicketSelection,
    })

    this.setState({
      ticketSelection,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    // This is here because the data is fetched in cWM and isn't in state until
    //  cDU. This would work a lot in cWM not needing to wait for state to be updated?
    this.printDefaultTxn()

    if(this.state.pushToDataLayer) this.emitDataLayerJSON()
  }

  render() {
    const data = this.state.data

    const DEBUGDEBUGDEBUG = false
    return data ? (
      <TransactionList
        _data={data}
        isVE={DEBUGDEBUGDEBUG || this.props.isVE}
        isDev={DEBUGDEBUGDEBUG || this.props.isDev}
        error={this.props.error}
        selectedTickets={this.state.ticketSelection}
        printSelectedTickets={this.printSelectedTickets.bind(this)}
        refundSelectedItems={this.refundSelectedItems.bind(this)}
        updateSelectedTickets={this.updateSelectedTickets.bind(this)}
        refundError={this.state.refundError}
        refundProcessing={this.state.refundProcessing}
        account={this.props.account}
      />
    ) : (
      <p>transaction loading</p>
    )
  }

  // When this page is opened, try printing out the transaction. Set state
  // that the `initialPrint` happened, and then don't repeatedly spam the printer
  // with subsequent transactions.
  printDefaultTxn() {
    const data = this.state.data
    const temporarilyPrintAllTickets = true
    // Lobby is the only location where tickets should print by default
    const printByDefault =
      data && (data.point_of_sale === 'lobby' || temporarilyPrintAllTickets)

    const isRefund =
      this.state.refundProcessing ||
      (data && data.transaction_type === 'refund')
    const shouldPrintTxn =
      data && printByDefault && !isRefund && this.state.initialPrint

    if (shouldPrintTxn) {
      try {
        this.printViaWebsocket(adjustDataForPrint(data))
        this.setState({ initialPrint: false })
      } catch (e) {
        console.error('problem printing', e)
      }
    }
  }

  emitDataLayerJSON() {
    const { data, pushToDataLayer } = this.state

    if(!data) return

    const layeredData = {
      ecommerce: {
        purchase: {
          actionField: {
            id: data.transaction_id,
            affiliation: '',
            revenue: data.subtotal,  //no commas for orders over 1000
            tax: data.total_tax,
            shipping: 0,
            coupon: ''
          },
          products: data.line_items.map(item => {
            const { name, category, brand, variant } = augmentItemData(item)

            return {
              name,  //special characters must be escaped
              id: item.line_item_id,
              price: item.total,  //unit price
              category,
              brand,
              variant,
              coupon: item.line_item_attributes.discounts,
              quantity: 1
            } 
          })
        }
      }
    }

    if(!data || !pushToDataLayer) {
      console.info('emitDataLayerJSON skipped', {data, pushToDataLayer, layeredData})
      debugger
      return
    }
    console.info('emitDataLayerJSON', {layeredData})
    // debugger

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(layeredData)
    this.setState({pushToDataLayer: false})
  }
}

const mapStateToProps = state => {
  return {
    account: state.account,
    isVE: state.account && state.account.isVE,
    isDev: state.account && state.account.isDev,
    error: state.products.error,
    pushToDataLayer: state.products.pushToDataLayer,
  }
}

const TransactionList = ({
  _data,
  isVE,
  error,
  printSelectedTickets,
  refundSelectedItems,
  updateSelectedTickets,
  selectedTickets,
  refundError,
  refundProcessing,
  isDev,
  account,
}) => {
  const data = adjustDataForPrint(_data)
  const txnIsRefund = data.transaction_type === 'refund'
  const totalPaidOrDue = txnIsRefund ? data.total_due : data.total_paid

  const txnDate = data && data.timestamp && new Date(data.timestamp)
  const showBenefitValue = !isVE && txnDate && txnDate.getUTCFullYear() > 2018 // show benefit value column for 2019 orders

  const cellStyles = {
    padding: '10px 20px',
    textAlign: 'left',
    verticalAlign: 'top',
    borderBottom: '1px solid #f1f1f1',
  }

  function ProductInfo(props) {
    const get_product = props.id
    const selectedProducts = products.filter(p => p.data.id === get_product)
    const _product = products.find(p => p.id === props.id)
    const allProducts = products

    let message = R.path(
      ['data', 'custom_messaging', 0, 'message'],
      selectedProducts[0]
    )

    const logistics = R.path(['data', 'event_logistics'], _product)

    if(logistics) {
      message = <p>Connect to this event at the following event video link: <a href={logistics}>{logistics}</a></p>
    }

    return <>
      {!message || (message && message.match && message.match(/^Please print or present tickets/))
        ? <span />
        : <p>
            {message}
          </p>}
    </>
  }

  const enableRefunds = isVE
  // && isDev
  // && account.operator && account.operator.location.match(/manager|teamlead/i)

  return (
    <div className="row two-thirds">
      <div className="col-9 col">
        <h3>Thank you from Mia.</h3>
        {data.payments.map(function(payment) {
          return payment.payment_type === 'credit_card' ? (
            <p>
              The credit card ending {payment.last4Digits} has been{' '}
              {!txnIsRefund ? 'charged' : 'credited'} a total of ${
                totalPaidOrDue
              }{' '}
              USD.
            </p>
          ) : (
            <p key={payment}>
              Your {payment.payment_type} payment of $ {data.total_paid} USD has
              been {txnIsRefund ? 'refunded' : 'accepted'}.
            </p>
          )
        })}
        <p>
          <strong>
            You have {txnIsRefund ? 'returned' : 'purchased'} the following
            items:
          </strong>
        </p>
        <p>
          <Moment format="dddd, MMMM D, YYYY h:mm a">{data.timestamp}</Moment>
        </p>
        <table style={{ width: '100%' }}>
          <tbody>
            <tr>
              <th style={cellStyles}>Item Name</th>
              <th style={cellStyles}>Item Details</th>
              <th style={cellStyles}>Price</th>
              {isVE && (
                <th style={cellStyles} title="select for printing and refunds">
                  Select items
                </th>
              )}
              {showBenefitValue && (
                <th style={cellStyles}>
                  Benefit Value
                </th>
              )}
            </tr>

            {data.line_items.map((line, i) => {
              console.info({ i, selectedTickets, ch: selectedTickets[i] })
              const { item } = line
              /* eslint-disable */ /* does this really never get used?  */
              const details = R.values(
                R.mapObjIndexed((value, key) => {
                  const humanKey = key
                    .replace('selected_', '')
                    .replace('_', ' ')
                  if (!key.match(/^selected/)) return ''
                  return (
                    <p key={key + value}>
                      {transformDetailValue(humanKey, value, key)}
                    </p>
                  )
                }, line.line_item_attributes)
              )

              // TODO - generate this in the server when running in prod
              // to minimize bundle size?
              const simpleCode = line.line_item_id
              const richCode = {
                id: line.line_item_id,
                transaction_id: data.transaction_id,
                ...line.line_item_attributes,
                ...R.pick(['item_id', 'product_id'], line.item),
                url: `artsmia.org/_qr/${line.line_item_id}`,
              }
              const useRichCode = false
              const code = useRichCode ? JSON.stringify(richCode) : simpleCode
              const qrCode = qr.imageSync(code, {
                type: 'svg',
              })

              const itemIsRefunded = line.status === 'refunded' || txnIsRefund
              if (itemIsRefunded) console.info('refunded line', line)

              return (
                <tr key={`${item.product_name}-${i}`}>
                  <td style={cellStyles}>{item.product_name}</td>
                  <td style={cellStyles}>
                    {itemIsRefunded && (
                      <div
                        style={
                          txnIsRefund || itemIsRefunded
                            ? { backgroundColor: 'tomato', padding: '0 0.3em' }
                            : {}
                        }
                      >
                        <p>
                          <strong>{txnIsRefund ? 'REFUND' : 'REFUNDED'}</strong>
                          <br />
                          {txnIsRefund ? (
                            <Link
                              to={`/order/${_data.originating_transaction_id}`}
                            >
                              View original order
                            </Link>
                          ) : (
                            <Link to={`/order/${line.refund_transaction_id}`}>
                              View refund
                            </Link>
                          )}
                        </p>
                      </div>
                    )}

                    {selectDetailsFromData(
                      {
                        ...line.line_item_attributes,
                        ...item.product_attributes,
                      },
                      line
                    )}
                    <ProductInfo id={item.product_id} />

                    {line.print &&
                      !itemIsRefunded && (
                        <div>
                          <div
                            style={{ maxWidth: '10em' }}
                            dangerouslySetInnerHTML={{ __html: qrCode }}
                          />
                          <p>Please print or present on a mobile device.</p>
                        </div>
                      )}
                  </td>
                  <td style={cellStyles}>${item.price}</td>
                  {isVE &&
                    !itemIsRefunded && (
                      <td style={cellStyles}>
                        <label>
                          <input
                            id={i}
                            type="checkbox"
                            style={{
                              position: 'relative',
                              left: 0,
                              display: 'inline',
                            }}
                            checked={selectedTickets[i]}
                            onClick={() => updateSelectedTickets(line)}
                          />
                        </label>
                      </td>
                    )}
                    {showBenefitValue && (
                      <td style={cellStyles}>
                        ${line.benefit_value}
                      </td>
                    )}
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="3">Sub total: ${data.subtotal}</td>
            </tr>
            <tr>
              <td colSpan="3">Tax: ${txnIsRefund ? 0 : data.total_tax}</td>
            </tr>
            <tr>
              <td colSpan="3">Total: ${totalPaidOrDue}</td>
            </tr>
            {isVE && (
              <tr>
                <td />
                <td />
                <td />
                {txnIsRefund || (
                  <td colSpan="3">
                    <button onClick={printSelectedTickets}>
                      Print Tickets
                    </button>
                    {enableRefunds && (
                      <span>
                        <br />
                        <button
                          onClick={refundSelectedItems}
                          disabled={refundProcessing}
                          style={refundProcessing ? { opacity: 0.3 } : {}}
                        >
                          Refund Items
                        </button>
                      </span>
                    )}
                  </td>
                )}
              </tr>
            )}
          </tfoot>
        </table>

        <DoubleTheDonation data={data} isDev={isDev} />

        {refundError && (
          <div
            style={{
              border: '1px solid red',
              padding: '0.5em',
              marginBottom: '1em',
            }}
          >
            <p style={{ color: 'red' }}>Error processing refund</p>
            <pre>{refundError}</pre>
          </div>
        )}
        <p>
          Please call 612-870-3000 or email{' '}
          <a href="mailto:tickets@artsmia.org">tickets@artsmia.org</a> with
          questions.
        </p>
        <p>
          Please retain this receipt for your records. This letter will serve as
          the required documentation for your contribution(s) and must be
          retained to substantiate the donation for federal tax purposes.
        </p>

        <h3>Thanks again and we look forward to seeing you soon.</h3>

        {isVE && (
          <div style={{ maxWidth: '30em' }}>
            <p>
              <span style={{ color: 'red' }}>{error}</span> This won't affect
              the overall purchase - it means something went wrong after the
              purchase was finalized and the tickets/products had been reserved.
              There will be a delay before this is visible in salesforce.
            </p>
          </div>
        )}

        {isVE && (
          <details>
            <summary>Transaction Data</summary>
            <pre>{JSON.stringify(data, null, 2)} </pre>{' '}
          </details>
        )}
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Transaction)

class UnwrappedTransactionsIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentWillMount() {
    const date = new Date()
      .toISOString()
      .split('T')[0]
      .replace('-', '')
    const response = await axios.get('/v1/orders/date/20170825')
    this.setState({ rawTxns: response.data })
  }

  render() {
    const { rawTxns } = this.state
    const transactions = (rawTxns || []).map(t => JSON.parse(t))

    if (!this.props.isVE) return <Redirect to="/" />

    return !transactions ? (
      <p>loading…</p>
    ) : (
      <ul>
        {transactions.map(trans => {
          const { transaction_id, total_paid, purchaser } = trans
          const { first_name, last_name } = purchaser

          return (
            <li>
              <Link to={`/order/${trans.transaction_id}`}>
                {first_name} {last_name} {trans.total_paid}
              </Link>
            </li>
          )
        })}
      </ul>
    )
  }
}

// Do some sleuthing into certain item types to add bonus data
// that improves reporting to analytics.
function augmentItemData(item) {
  const { item: { product_name, product_type, item_id } } = item
  let name = product_name === 'Donate'
    ? 'Donation'
    : product_name

  let type = product_type === 'lecture'
    ? 'Talk'
    : product_type

  const pluralized = type.split('_')
    .map(([h, ...t]) => h.toUpperCase() + t.join('')).join(' ') + 's'

  let category, brand, variant
  
  if(item_id === 'MEMBERSHIP' || item_id === 'SUSTAINING_MEMBERSHIP') {
    let _category = 'Memberships'
    let [_name, _brand, _variant] = calculateMembershipInfo(item)
    name = _name
    brand = _brand
    variant = _variant
    category = _category
  } else {
    name = name
    category = pluralized
    brand = pluralized
    variant = item.line_item_attributes.ticket_type || pluralized
  }

  const augmented = {
    _product_name: product_name,
    name,
    category,
    brand,
    variant,
  }

  console.info('augmentItemData', {item, augmented})

  return augmented
}

function calculateMembershipInfo(membership) {
  const isSustaining = membership.item.item_id === "SUSTAINING_MEMBERSHIP"
  const dollarAmount = isSustaining
    ? membership.line_item_attributes.payment_amount*12
    : membership.line_item_attributes.selected_amount

  const membershipLabel = dollarAmount <= 0
    ? 'Free'
    : dollarAmount < 150
      ? 'Contributor'
      : dollarAmount < 500
        ? 'Investor'
        : dollarAmount < 2500
          ? "Patron's Circle"
          : "Director's Circle"

  const membershipDuration = isSustaining ? 'Sustaining' : 'One-Time'
  const joinOrRenewal = 'TBD'
  console.info({dollarAmount, membershipLabel, membershipDuration, joinOrRenewal})

  return [membershipLabel, membershipDuration, joinOrRenewal]
}

export const TransactionsIndex = connect(mapStateToProps)(
  UnwrappedTransactionsIndex
)

const transformDetailValue = (humanKey, value, key) => {
  if (humanKey === 'date') {
    return <Moment format="dddd, MMMM D, YYYY">{value}</Moment>
  } else {
    return (
      <div>
        {humanKey}: {value}
      </div>
    )
  }
}

const DoubleTheDonation = (props) => {
  const donation = props && props.data && props.data.line_items.find(item => 
    item.item.product_id === 'DONATE'
  )

  const {purchaser: {email_address}} = props.data || {}

  const {
    REACT_APP_DTD_PUBLIC_KEY: dtdPublicKey,
    REACT_APP_DTD_CAMPAIGN: dtdCampaign,
  } = process.env
  const {companyId} = donation ? donation.line_item_attributes : {}
  const donationId = donation && donation.line_item_id
  const userEmailAddress = email_address

  useEffect(() => {
    if(props.isDev || !donation) return null

    const DDCONF = { API_KEY: dtdPublicKey }
    var dtd_selected_company = companyId

    if (userEmailAddress && window.doublethedonation) {
      doublethedonation.plugin.set_donation_identifier(donationId)
      doublethedonation.plugin.set_donation_campaign(dtdCampaign)

      if (dtd_selected_company) {
        doublethedonation.plugin.set_company(dtd_selected_company)
      } else {
        var domain = doublethedonation.integrations.core.strip_domain(
          userEmailAddress
        )
        doublethedonation.plugin.email_domain(domain)
      }
    }
  }, [userEmailAddress, props.isDev, donationId, companyId, donation])

  if(props.isDev || !donation || !userEmailAddress) return null

  return <div>
		<div id="dd-container"></div>
  </div>
}
