import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import products from './products'
import account from './account'
import hardware from './hardware'

export default combineReducers({
  routing: routerReducer,
  products,
  account,
  hardware,
})
