import React from 'react'
import moment from 'moment'
import Moment from 'react-moment'

export default props => {
  const { id, start_date_time, end_date_time, product_type } = props.product.data

  if(product_type === 'donation') return <span style={{display: 'inline-block', lineHeight: '1.1em'}} />
  if(id === 'GEN-FY21-001')
    return <time style={{display: 'inline-block', color: '#232323'}} className="uppercase">Reserve Now</time>

  const startDate = moment(start_date_time)
  const endDate = moment(end_date_time)

  const singleDay = startDate.isSame(endDate, 'day')

  const isCurrentYear = moment(startDate).isSame(new Date(), 'year')
  const format = `dddd, MMMM D${isCurrentYear ? '' : ' YYYY'} h:mm a`

  const bothDatesAreSameYear = moment(startDate).isSame(endDate, 'year')

  return singleDay ? (
    <div className="uppercase">
      <Moment format={format}>{startDate}</Moment>
    </div>
  ) : (
    <div className="uppercase">
      {bothDatesAreSameYear ? <Moment format="MMMM D">{startDate}</Moment> : <Moment format="MMMM D, YYYY">{startDate}</Moment>}{' '}
      <span style={{ fontFamily: 'Mia Bold', fontSize: '0.8rem' }}>to</span>{' '}
      <Moment format="MMMM D, YYYY">{endDate}</Moment>
    </div>
  )
}
