import { getOverallLocationFromStation } from '../containers/account/operator-checks'

export const txnLocationFromAccount = account => {
  const location = getOverallLocationFromStation(
    account && account.operator && account.operator.location
  )

  return location || 'web'
}

export const chooseStripeKey = account => {
  const {
    REACT_APP_STRIPE_TEST: testKey,
    REACT_APP_STRIPE_LIVE: liveKey,
    REACT_APP_STRIPE_ENV,
  } = process.env

  const testPublishableKeys = {
    // web: 'pk_test_j5BWNOmn255jpUxr0hxBGImi',
    // phone: 'pk_test_lvezzXcXyXmzPrrIM8qcnkZ1',
    // lobby: 'pk_test_RBfOwTgx8UIKg5A6YJlzFOj7',
    web: 'pk_test_aW6CdVAwgW7MGnvdmiJb1IU9',
    phone: 'pk_test_aW6CdVAwgW7MGnvdmiJb1IU9',
    lobby: 'pk_test_aW6CdVAwgW7MGnvdmiJb1IU9',
  }

  const livePublishableKeys = {
    web: 'pk_live_Ho1FKcgIT3mDzUzN5CIRb5uU',
    phone: 'pk_live_HNaGUjPwMk6Az3CHLdBahocn',
    lobby: 'pk_live_IRtYF9si7CjQZjqZutMW7YPz',
  }

  // const TEST = true
  const TEST = REACT_APP_STRIPE_ENV !== 'LIVE'

  const transactionLocation = txnLocationFromAccount(account)
  const oldPublishableKey = (TEST ? testPublishableKeys : livePublishableKeys)[
    transactionLocation
  ]

  const newPublishableKey = TEST ? testKey : liveKey

  const changeOverDateTime = new Date('2018-03-10T20:00:00-0600')
  const useOld = new Date() <= changeOverDateTime

  const publishableKey = useOld ? oldPublishableKey : newPublishableKey

  console.info('chooseStripeKey', {
    TEST,
    oldPublishableKey,
    newPublishableKey,
    changeOverDateTime,
    useOld,
    REACT_APP_STRIPE_ENV,
    publishableKey,
  })
  return publishableKey
}
