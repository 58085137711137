import React from 'react'
import { addToCart, modifyProductData } from '../../modules/products'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import RaisedButton from 'material-ui/RaisedButton'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'
import ActionFavorite from 'material-ui/svg-icons/action/favorite'
import ActionFavoriteBorder from 'material-ui/svg-icons/action/favorite-border'
import {
  PriceOverrideSelector,
} from './SingleProductSelect'

class Affinity extends React.Component {
  constructor(props) {
    super(props)

    const affinity = this.props.products.find(
      p => p.data.product_type === 'AFFINITY_LEVEL'
    )

    this.state = {
      open: false,
      affinity,
      primary_contact: this.props.primary_contact,
      secondary_contact: this.props.secondary_contact,
    }
  }

  handleOpen = () => {
    this.setState({ open: true })
  }
  handleClose = () => {
    this.setState({ open: false })
    this.props.handleClose && this.props.handleClose()
  }

  updateInfo(event) {
    // prettier-ignore
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const affinityChoiceChanged = this.state.affinities !== prevState.affinities

    /* If an `onChange` prop has been passed, and the selected affinity
     * has changed, build the new affinity product and pass it up to the
     * parent component.
     *
     * This is used to allow the affinity to be selected from within the Membership
     * creation process.
     */
    if (this.props.onChange && affinityChoiceChanged) {
      const affinity = this.buildAffinityAndPutInCart(true)
      this.props.onChange(affinity)
    }
  }

  render() {
    const affinities = this.state.affinity.data.require_type

    return (
      <div>
        <div
          className="col-2 grid-full"
          style={{ backgroundColor: 'transparent' }}
        >
          <p>
            Receive invitations to talks and events. Get the inside scoop on
            departmental news and acquisitions. Enjoy special opportunities to
            socialize with others who have similar passions. Mia’s Affinity
            Groups are a great way for museum members to connect more closely
            with special areas of art interest.
          </p>
          <RadioButtonGroup
            name="affinities"
            defaultSelected="None"
            onChange={this.updateInfo.bind(this)}
          >
            {affinities &&
              affinities.map(affinity => {
                return (
                  <RadioButton
                    key={affinity}
                    value={affinity}
                    checkedIcon={
                      <ActionFavorite style={{ color: '#F44336' }} />
                    }
                    uncheckedIcon={<ActionFavoriteBorder />}
                    label={
                      <div>
                        <h3>{affinity}</h3>
                      </div>
                    }
                  />
                )
              })}
          </RadioButtonGroup>
          <br />

          {this.props.isVE && (
            <PriceOverrideSelector
              handleDiscount={this.handleDiscount.bind(this)}
              product={this.state.affinity}
              priceOverride={this.state.priceOverride}
            />
          )}

          <br />
          {!this.props.onlyBuildAffinity && (
            <RaisedButton
              label="Add To Cart"
              disableTouchRipple={true}
              disableFocusRipple={true}
              primary={true}
              onClick={this.buildAffinityAndPutInCart.bind(
                this,
                this.props.onlyBuildAffinity
              )}
              style={{ marginRight: 12 }}
            />
          )}
        </div>
      </div>
    )
  }

  /* build an affinity product and by default put it directly in the global cart.
   * if `onlyBuildAffinity` is passed a truthy value, return the built affinity 
   * instead of putting it directly into the cart.
   */
  buildAffinityAndPutInCart(onlyBuildAffinity = false) {
    const { affinities, primary_contact } = this.state

    const user = this.props.account.identified && this.props.account.user

    const affinityGroupChoice = affinities

    const actingUser = { ...user, ...primary_contact }
    const aff = this.state.affinity.clone().withData({
      ...(actingUser
        ? {
            primary_beneficiary: {
              salesforce_account_id: actingUser.salesforce_account_id,
              salesforce_contact_id: actingUser.salesforce_contact_id,
              first_name: actingUser.first_name,
              last_name: actingUser.last_name,
              email_address: actingUser.email_address,
            },
          }
        : {}),
      selected_affinity:
        affinityGroupChoice === 'None' ? undefined : affinityGroupChoice,
    })

    if (onlyBuildAffinity) {
      return aff
    } else {
      this.props.addToCart(aff)
      this.handleClose()
    }
  }

  // TODO can't figure out how to import this and use it from `SingleProductSelect`
  // do that so this code isn't copy/pasted
  handleDiscount = (event, index, value) => {
    const product = this.state.affinity
    if (!product) return

    const { price, originalPrice, _originalPrice } = product.data
    const currentPrice = _originalPrice || price
    const nextPrice =
      value === '-1'
        ? _originalPrice || originalPrice || price
        : currentPrice - currentPrice * parseInt(value) / 100
    const overrideType = event.target.innerText

    const nextData = {
      priceOverride: value,
      product: product.withData({
        _originalPrice: _originalPrice || price,
        price: nextPrice,
        originalPrice: nextPrice,
        selected_price_override_type: overrideType,
      }),
    }

    this.setState(nextData)
    // FIXME write this function once and import/bind it correctly
    // can't quite figure it out
    // return handlePriceOverrideDiscount.apply(this, args)
  }
}

const mapStateToProps = state => ({
  isVE: state.account && state.account.isVE,
  account: state.account,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addToCart,
      modifyProductData,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Affinity)
