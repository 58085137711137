import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Redirect } from 'react-router-dom'
import { RepCheckOut } from './operator-checks'
import { closeDrawer, logOut } from '../../modules/account'

const VEOperatorInfo = ({ account, closeDrawer }) => {
  if (!account.isVE) return <Redirect to="/" /> 

  return (
    <div>
      {account.isVE &&
        closeDrawer &&
        <RepCheckOut operator={account.operator} closeDrawer={closeDrawer} />}
    </div>
  )
}

const mapStateToProps = state => ({
  account: state.account,
  isVE: state.account && state.account.isVE,
})
const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeDrawer, logOut }, dispatch)
const reduxComponent = connect(mapStateToProps, mapDispatchToProps)(VEOperatorInfo)

export default reduxComponent
