const months = `january february march april may
  june july august september october november december`
  .split(/\W/)
  .filter(val => val !== '')
const days = `sunday monday tuesday wednesday thursday friday saturday`
  .split(/\W/)
  .filter(val => val !== '')

const monthIndex = m =>
  m && m.toLowerCase ? months.indexOf(m.toLowerCase()) + 1 : m
const dayIndex = d => (d && d.toLowerCase ? days.indexOf(d.toLowerCase()) : d)

function firstDayOfMonth(month, year = new Date().getFullYear()) {
  return month
    ? new Date(`${year}/${month}/1`)
    : new Date(new Date().setDate(1))
}

function nthDayOfMonth(n, day, month, year) {
  if (n < 1) return false

  const firstDay = firstDayOfMonth(month, year)

  const targetDate = new Date(
    new Date(firstDay).setDate(
      (n - 1) * 7 +
        (firstDay.getDate() + (dayIndex(day) - firstDay.getDay()) % 7)
    )
  )

  return targetDate.getMonth() + 1 === monthIndex(month) ? targetDate : false
}

function nthFullWeekend(n, month, year) {
  const nthSat = n => nthDayOfMonth(n, 'saturday', month, year)
  const nthSun = n => nthDayOfMonth(n, 'sunday', month, year)

  const firstSaturday = nthSat(1)
  const firstSunday = nthSun(1)
  // const secondSunday = nthSun(2)

  return firstSunday < firstSaturday
    ? [nthSat(n), nthSun(n + 1)]
    : [nthSat(n), nthSun(n)]
}

function secondFullWeekend(date = new Date()) {
  return nthFullWeekend(2, date.getMonth() + 1, date.getFullYear())
}

function secondFullFutureWeekend(date = new Date()) {
  const thisMonth = nthFullWeekend(2, date.getMonth() + 1, date.getFullYear())

  const nextMonth = date.getMonth() + 2
  const secondFullWeekendNextMonth = nextMonth < 12
    ? nthFullWeekend(2, date.getMonth() + 2, date.getFullYear())
    : nthFullWeekend(2, (date.getMonth() + 2) % 12, date.getFullYear() + 1)

  // console.info('secondFullWeekend', {
  //   thisMonth,
  //   nextMonth,
  //   secondFullWeekendNextMonth,
  // })

  return thisMonth[1] < date
    ? secondFullWeekendNextMonth
    : thisMonth
}

module.exports = {
  nthFullWeekend,
  firstDayOfMonth,
  nthDayOfMonth,
  monthIndex,
  dayIndex,
  months,
  days,
  secondFullWeekend,
  secondFullFutureWeekend,
}

// FIXME better error handling - return false for invalid dates?
