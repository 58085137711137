import React from 'react'
import axios from 'axios'
import VEOperatorInfo from './ve-operator'
import StripeTerminal from '../../components/stripe-terminal'

export const groupedSalesLocations = {
  lobby: `Lobby1 Lobby2 Lobby3 Lobby4 Lobby5 Lobby6 OldMain1 Atrium1 Target1 Target2 TargetAudio1 TargetAudio2 Laptop1 Mobile1 Mobile2 Mobile3 Mobile4 Mobile5`
    .trim()
    .split(/\s+/g),
  phone: `Phone1 Phone2 Phone3 Phone4 Phone5 Phone6 Phone7 Phone8 Phone9 Phone10 Phone11 TeamLead1 TeamLead2 TeamLead3 Manager1 Manager2 Manager3 Tours1 Tours2 Advancement1 Advancement2 Advancement3 Webdev1 Webdev2 Webdev3`
    .trim()
    .split(/\s+/g),
}

export const getOverallLocationFromStation = station => {
  const groups = groupedSalesLocations
  const lowerStation = station && station.toLowerCase()

  return groups['lobby'].find(s => s && s.toLowerCase() === lowerStation)
    ? 'lobby'
    : groups['phone'].find(s => s && s.toLowerCase() === lowerStation)
      ? 'phone'
      : 'web'
}

const salesLocations = groupedSalesLocations['lobby'].concat(
  groupedSalesLocations['phone']
)

export class RepCheckIn extends React.Component {
  constructor(props) {
    super(props)

    this.state = { selectedLocation: null }
  }

  handleOpenDrawer() {
    if (!this.valid())
      return this.setState({ error: 'you need to select a location' })

    const { dispatchCheckIn, operator } = this.props
    const {
      selectedLocation: location,
      operatorName: operator_name,
      initialCashDrawerCount: starting_amount,
    } = this.state

    const tzoffset = new Date().getTimezoneOffset() * 60000
    const localISOTime = new Date(Date.now() - tzoffset)
      .toISOString()
      .split('T')[0]
      .replace(/-/g, '')

    const payload = {
      operator_detail: {
        date: localISOTime,
        salesforce_user_id: operator.salesforce_user_id,
        location,
        starting_amount,
        operator_name,
      },
    }

    axios
      .post('/v1/drawers/check_in', payload)
      .then(response => {
        if (response.data.error)
          return this.setState({ error: response.data.error })

        const [a, b, c, name, location] = response.data.success.split('_') // eslint-disable-line no-unused-vars
        dispatchCheckIn({ ...this.state, name })
      })
      .catch(error => {
        console.error('error checking in operator cash drawer', error)
      })
  }

  render() {
    const { selectedLocation, error } = this.state
    return (
      <div>
        <p>check in here with sales location and cash drawer info</p>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {selectedLocation ? (
          <>
            <p>Checking in at {selectedLocation}</p>
            <StripeTerminal
              readerLocation={selectedLocation}
              handleReadersDiscovered={this.readerFound}
              handleReaderConnected={this.readerConnected}
              isDev={this.props.isDev}
            />
            <hr />
          </>
        ) : (
          salesLocations.map(location => (
            <button
              onClick={() =>
                this.setState({ selectedLocation: location, error: null })
              }
            >
              {location}
            </button>
          ))
        )}

        <p>
          Name:{' '}
          <input
            type="string"
            onChange={event => {
              this.setState({ operatorName: event.target.value })
            }}
          />
        </p>

        <p>
          with cash count:{' '}
          <input
            type="number"
            onChange={event => {
              this.setState({ initialCashDrawerCount: event.target.value })
            }}
          />
        </p>

        <button
          onClick={this.handleOpenDrawer.bind(this)}
          disabled={!this.valid()}
        >
          Open Drawer
        </button>

        <VEOperatorInfo account={this.props.account} />
      </div>
    )
  }

  valid() {
    const v = !!this.state.selectedLocation && !!this.state.operatorName
    console.info('can we open this drawer yet?', v, this.state)
    return v
  }


  readerFound = async ({discoveredReaders}) => {
    // console.info('found readers', discoveredReaders)
  }

  readerConnected = async ({reader, terminal}) => {
    // console.info('connected to reader', reader)
    this.setState({connectedReader: reader, terminal})
  }
}

export class RepCheckOut extends React.Component {
  handleCloseDrawer(finalCashDrawerCount) {
    const { operator } = this.props
    const {
      finalCashDrawerCount: ending_amount,
      finalCheckDollarAmount: ending_check_amount,
    } = this.state

    const tzoffset = new Date().getTimezoneOffset() * 60000
    const localISOTime = new Date(Date.now() - tzoffset)
      .toISOString()
      .split('T')[0]
      .replace(/-/g, '')

    const payload = {
      operator_detail: {
        date: localISOTime,
        salesforce_user_id: operator && operator.salesforce_user_id,
        location: operator && operator.location,
        operator_name: operator && operator.operator_name,
        ending_amount,
        ending_check_amount,
      },
    }

    axios
      .post('/v1/drawers/check_out', payload)
      .then(response => {
        this.props.closeDrawer()
      })
      .catch(error => {
        console.error(error)
        // dispatchCheckIn(this.state)
      })
  }

  render() {
    const {name, location} = this.props.operator
    const formattedName =
      name &&
      name
        .split(':')
        .map(([first, ...rest]) => `${first.toUpperCase()}${rest.join('')}`)
        .join(' ')

    return (
      <div>
        <p>
          When you are finished with your shift, please enter the final counts
          from your sales drawer ({this.props.operator.location},{' '}
          {formattedName || 'VE Operator'}) before closing your session.
        </p>
        <p>
          <label>
            Final Cash Count
            <br />
            <input
              type="number"
              onChange={event =>
                this.setState({ finalCashDrawerCount: event.target.value })
              }
            />
          </label>
        </p>
        <p>
          <label>
            Total dollar amount of checks tendered
            <br />
            <input
              type="number"
              onChange={event =>
                this.setState({ finalCheckDollarAmount: event.target.value })
              }
            />
          </label>
        </p>

        <button onClick={this.handleCloseDrawer.bind(this)}>
          Close Drawer
        </button>
        <hr />
        <StripeTerminal
          readerLocation={location}
          handleReadersDiscovered={this.readerFound}
          handleReaderConnected={this.readerConnected}
          isDev={this.props.isDev}
        />
      </div>
    )
  }
}
