import React from 'react'

class NavIcon extends React.Component {
  render() {
    return (
      <div>
        <div
          id="nav-icon"
          onClick={this.props.onClick}
          className={this.props.isToggleOn ? 'close' : 'open'}
        >
          <span />
          <span />
          <span />
        </div>
      </div>
    )
  }
}

export default NavIcon
