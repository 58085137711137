import R from 'ramda'
import Product from '../core/models/product'
import {
  scheduleOnce,
  scheduleDaily,
  scheduleRepeat__secondFullWeekend,
  scheduleRepeat__eachWeekend,
  percentageDiscountWithMembership,
  maxPerCart,
  requireData,
  upToTwoFreeWithMembership,
  myMiaDays,
  fixedReducedPriceWithMembership,
  requireTimedTicket,
  flexiblePricing,
  assignLevelBasedOnPricePaid,
  museumClosed,
  requiresCompanionProductWithComparison,
  membersOnly,
  affinityMembersOnly,
  pctDiscountWAffinity,
  friendsMembersOnly,
  promo,
  timedTicketWithInterval,
  vipCompanionChangesPrice,
  youthInvestorFree,
  membersOnlyWhenMemberHold,
  myMiaDaysAfter5pm,
  freeAffinityBasedOnMembership,
  surgePricing,
  clearDiscounts
} from '../core/models/rules'

import {
  secondFullFutureWeekend,
} from '../core/utils/calendar'

import { toWorldTime } from '../containers/cart'

const uuid = require('uuid/v4')
const moment = require('moment')

const isDev = process.env.NODE_ENV === 'development' || window.location.host === 'dev-ticket.artsmia.org'

function buildEntitlement(data) {
  return {
    entitlement_id: `ent_${uuid()}`,
    number_available: 'infinite',
    discount_type: 'percentage',
    price_override: 0,
    max_per_order: 0,
    ...data,
  }
}

const makePriceOverrides = R.map(([name, pctDiscount]) => {
  return buildEntitlement({
    entitlement_name: name,
    price_override: pctDiscount,
  })
})

const lecturePriceOverrideEntitlements = makePriceOverrides([
  ['MCAD', 100],
  ['VIP Comp', 100],
  ['Staff', 100],
])

export const productData = require('./products/index.js')

const getSelectedDay = R.tryCatch(
  product => product.data.selected_date.getDay(),
  R.F
)

const onlySunday = R.compose(R.equals(0), getSelectedDay)
// const onlyThursday = R.compose(R.equals(4), getSelectedDay)
const onlySaturday = R.compose(R.equals(6), getSelectedDay)
const [secondSaturday, secondSunday] = secondFullFutureWeekend(new Date())
// sell PCH tickets 3 months ahead of time - 13 weeks
const futureSunday = moment(secondSunday).add(13, 'w')

function checkCapacity(product, cart, user) {
  const { selected_date: date, selected_time: time } = product.data

  if (date) {
    const worldConvertedTime = time ? toWorldTime(time) : time
    const capacityTracker = `${
      date.toISOString().split('T')[0]
    }-${worldConvertedTime}`

    return { ...product.data, checkCapacity: capacityTracker }
  } else {
    return product.data
  }
}

// all non-my mia days
// 11:30, 2 + 7pm on thursday
const evMyMiaTour = new Product({
  id: 'MYMIATOUR2018-001',
  product_type: 'tour',
  name: 'Eyewitness Views My Mia Tour',
  price: 0,
  body: '',
  gl_codes: [
    {
      gl_code: '99-9-9999-999-99999',
      calculation_type: 'percentage',
      amount: 100,
    },
  ],
  gau_code: 'Free Items',
  rules: [
    requiresCompanionProductWithComparison(
      // requires companion parent
      (product, companion) => {
        return companion.data.id === product.data
          ? product.data.parentId
          : 'EX2019-001'
      },
      'You need to also purchase exhibition tickets on a valid My Mia Tour day'
    ),
    // membersOnly(false, 'You must be a member'),
    requireTimedTicket([
      '11:30am',
      '2pm',
      [
        '7pm',
        product => {
          const { selected_date } = product.data
          return selected_date && selected_date.getDay() === 4
        },
      ],
    ]),
    museumClosed([
      [
        new Date('2017-09-09'),
        new Date('2017-09-16'),
        `My Mia Tours are not available the opening week of Eyewitness Views`,
      ],
      [
        new Date('2017-12-24'),
        new Date('2017-12-31'),
        `My Mia Tours are not available the closing week of Eyewitness Views`,
      ],
    ]),
    (product, cart, user) => {
      // not on my Mia Days
      const { companion, selected_date } = product.data
      const companion_selected_date = companion && companion.data.selected_date

      const myMiaDays = R.path(['data', 'myMiaDays'], product)
      const companionDateIsMyMiaDay =
        myMiaDays &&
        myMiaDays.find(d =>
          moment(d + '/2018').isSame(moment(selected_date), 'day')
        )

      if (companion_selected_date && companionDateIsMyMiaDay) {
        return { ...product.data, valid: false }
      } else {
        return product.data
      }
    },
  ],
  unlisted: true,
  parentId: 'EX2018-001',
})

const egyptPublicTour = evMyMiaTour.clone().withData({
  id: 'EX2019-TOUR-001',
  name: 'Egypt Public Tour',
  parentId: 'EX2019-001',
})
egyptPublicTour.rules = [
  requiresCompanionProductWithComparison(
    // requires companion parent
    (product, companion) => {
      return companion.data.id === (product.data.parentId || 'EX2019-001')
    },
    'You need to also purchase exhibition tickets on a valid My Mia Tour day'
  ),
  requireTimedTicket([
    '12:00 pm',
    [
      '6:00 pm',
      product => {
        const { selected_date } = product.data

        return (
          selected_date &&
          (selected_date.getDay() === 4 || selected_date.getDay() === 5)
        )
      },
    ],
  ]),
  museumClosed([
    [
      new Date('2018-10-27'),
      new Date('2018-11-10'),
      `Tours are not available the first two weeks of 'Egypt'`,
    ],
    [
      new Date('2018-12-17'),
      new Date('2019-01-14'),
      `Tours are not available at this time`,
    ],
    [
      new Date('2019-03-25'),
      new Date('2019-04-15'),
      `My Mia Tours are not available the closing weeks of 'Egypt'`,
    ],
  ]),
]
egyptPublicTour.history = [egyptPublicTour.data]

// var context = require.context('./products', true, /\.js$/)
// const productJson = {}
// context.keys().forEach(function(key) {
//   console.info('require individual files', { key })
//   productJson[key] = context(key)
// })

// const productData = require('./products/index.js')

const productFromJson = json => {
  var rules = []

  const {
    id,
    title,
    short_title,
    max_per_order,
    entitlements,
    product_type,
    surge_pricing,
    require_attendee_name,
    require_attendee_email,
    require_time,
    require_meal,
    require_invited_by,
    require_student_birthdate,
    require_student_last_name,
    require_student_first_name,
    require_parent_email,
    require_parent_address,
    require_sliding_scale,
    require_seating_preference,
    require_accessibility_considerations,
  } = json

  if(!id) {
    console.error('bad JSON passed to productFromJson', {json})
    return false
  }

  if (max_per_order !== 'infinite') rules.push(maxPerCart(max_per_order))

  if (require_attendee_name) rules.push(requireData('selected_attendee_name'))
  if (require_attendee_email) rules.push(requireData('selected_attendee_email'))
  if (require_student_birthdate)
    rules.push(requireData('selected_student_birthdate'))
  if (require_student_first_name)
    rules.push(requireData('selected_student_first_name'))
  if (require_student_last_name)
    rules.push(requireData('selected_student_last_name'))
  if (require_parent_email) rules.push(requireData('selected_parent_email'))
  if (require_parent_address) rules.push(requireData('selected_parent_address'))
  if (require_sliding_scale)
    rules.push(requireData('selected_sliding_scale', [require_sliding_scale]))
  if (require_time) rules.push(requireData('selected_time', [require_time]))
  if (require_meal) rules.push(requireData('selected_meal', [require_meal]))
  if (require_invited_by)
    rules.push(requireData('selected_invited_by', [require_invited_by]))
  if(require_seating_preference)
    rules.push(requireData('selected_seating_preference'))
  if(require_accessibility_considerations)
    rules.push(requireData('selected_accessibility_considerations'))

  if(surge_pricing) {
    rules.push(surgePricing)
  }

  if (entitlements) {
    entitlements.filter(e => e).forEach(entitlement => {
      // TODO apply entitlements to members > My Mia level for P&D fair
      if (entitlement.entitlement_name === 'My Mia Members') {
        rules.push(percentageDiscountWithMembership(entitlement.price_override))
      }

      if (entitlement.affinity_type) {
        rules.push(
          pctDiscountWAffinity(
            entitlement.price_override,
            entitlement.affinity_type
          )
        )
      }

      if (entitlement.membershipLevel) {
        rules.push(
          percentageDiscountWithMembership(
            entitlement.price_override,
            entitlement.membershipLevel
          )
        )
      }
    })
  }

  if (id === 'DONATE') {
    [
      flexiblePricing({ pricePoints: [5, 10, 25, 50, 100] }),
      requireData('selected_amount'),
      maxPerCart(1),
    ].map(rule => rules.push(rule))
  }

  if (id === 'MEMBERSHIP' || id === 'GIFT_MEMBERSHIP') {
    [
      flexiblePricing({ pricePoints: [0, 60, 150, 500, 2500] }),
      assignLevelBasedOnPricePaid,
      requireData('selected_amount'),
      maxPerCart(1),
    ].map(rule => rules.push(rule))
  }

  if (id === 'AFFINITY') {
    [
      requireData('selected_affinity'),
      freeAffinityBasedOnMembership,
    ].map(rule => rules.push(rule))
  }

  if (id === 'FRIENDS_MEMBERSHIP') {
    [
      flexiblePricing({ pricePoints: [50, 90, 125, 250, 500] }),
      assignLevelBasedOnPricePaid,
      requireData('selected_amount'),
      maxPerCart(1),
    ].map(rule => rules.push(rule))
  }

  if (id === 'SUSTAINING_MEMBERSHIP') {
    [
      flexiblePricing({ chargeLater: true, pricePoints: [0, 12.5, 42, 209] }),
      assignLevelBasedOnPricePaid,
      requireData('selected_amount'),
      maxPerCart(1),
    ].map(rule => rules.push(rule))
  }

  if (id === 'PCHTOUR') {
    [
      scheduleRepeat__secondFullWeekend,
      requireTimedTicket(
        ['10:00 am', onlySaturday],
        ['11:00 am', onlySaturday],
        '12:00 pm',
        '1:00 pm',
        ['2:00 pm', onlySunday]
      ),
      checkCapacity,
      upToTwoFreeWithMembership(),
      fixedReducedPriceWithMembership,
      maxPerCart(6),
      museumClosed([
        [
          new Date('2020-03-21T00:00:00'),
          new Date('2021-12-23T00:00:00'),
          'To preserve the health and safety of museum staff and guests, Purcell Cutts tours are currently suspended.',
        ],
      ]),
      clearDiscounts,
    ].map(rule => rules.push(rule))

    json.start_date_time = secondSaturday && secondSaturday.toISOString()
    json.end_date_time = futureSunday.toISOString()
    json.customDerivatives = {
      child: [
        ticket => ({ ...ticket.data, ticket_type: 'Child', price: 0 }), // child tickets are all free
      ],
    }
  }

  if (id === 'PCHTOUR-WINTERLIGHTS') {
    [
      scheduleRepeat__eachWeekend,
      requireTimedTicket(
        ['10:15 am', onlySaturday],
        ['11:15 am', onlySaturday],
        '12:15 pm',
        '1:15 pm',
        '2:15 pm',
        '3:15 pm'
      ),
      checkCapacity,
      upToTwoFreeWithMembership(), 
      fixedReducedPriceWithMembership,
      maxPerCart(6),
      museumClosed([
        [
          new Date('2017-12-24T00:00:00'),
          new Date('2017-12-25T00:00:00'),
          'Closed on Christmas Eve',
        ],
      ]),
      clearDiscounts,
    ].map(rule => rules.push(rule))

    json.customDerivatives = {
      child: [
        ticket => ({ ...ticket.data, ticket_type: 'Child', price: 0 }), // child tickets are all free
      ],
    }
  }

  if (title === 'Eyewitness Views') {
    rules.push(upToTwoFreeWithMembership({ atLevel: 150 }))
    rules.push(fixedReducedPriceWithMembership)
    json.myMiaDays = [
      '9/9', // Member Preview Day
      '9/10', // Family Day/Opening Day
      '9/21', // Third Thursday
      '10/3', // Tuesday My Mia Day
      '10/8', // Family Day
      '10/19', // Third Thursday
      '11/7', // Tuesday My Mia Day
      '11/12', // Family Day
      '11/16', // Third Thursday
      '12/10', // Family Day
      '12/21', // Third Thursday
    ]
    rules.push(myMiaDays(...json.myMiaDays))

    const closedExceptions = json.exceptions.map(exc => {
      return [
        new Date(exc.exc_start_date_time),
        new Date(exc.exc_end_date_time),
        `Closed for ${exc.exception_name}`,
      ]
    })
    rules.push(museumClosed(closedExceptions))

    rules.push(promo)

    json.hasMyMiaTour = true
    json.myMiaTourProduct = evMyMiaTour
  }

  if (short_title === 'Power and Beauty') {
    rules.push(timedTicketWithInterval(10))
    rules.push(upToTwoFreeWithMembership({ atLevel: 150 }))
    rules.push(fixedReducedPriceWithMembership)
    json.myMiaDays = [
      '2/9', // F
      '2/28', // W
      '3/2', // F
      '3/21', // W
      '3/30', // F
      '4/4', // W
      '4/13', // F
      '4/25', // W
      '5/4', // F
      '5/9', // W
      '5/23', // W
      '6/10', // Sun
    ]
    rules.push(myMiaDays(...json.myMiaDays))

    rules.push(promo)

    rules.push(checkCapacity)

    json.hasMyMiaTour = false
  }

  if (id === 'EX2019-001') {
    // Egypt
    rules.push(timedTicketWithInterval(15))
    rules.push(upToTwoFreeWithMembership({ atLevel: 150 }))
    rules.push(fixedReducedPriceWithMembership)
    json.myMiaDays = ['11/11', '03/10/2019']
    json.myMiaDaysTT = ['11/15', '02/21/2019']
    json.myMiaContributorDays = ['01/29/2019', '01/30/2019','01/31/2019','02/01/2019','02/02/2019', '02/03/2019']
    rules.push(myMiaDays(...json.myMiaDays))
    rules.push(myMiaDaysAfter5pm(...json.myMiaDaysTT))
    rules.push(myMiaDays(...json.myMiaContributorDays, {atLevel: 1}))

    rules.push(promo)

    rules.push(checkCapacity)

    json.customDerivatives = {
      youth: ticket => ({ ...ticket.data, ticket_type: 'Youth', price: 16 }),
      student: ticket => ({
        ...ticket.data,
        ticket_type: 'Student',
        price: 18,
      }),
    }

    json.hasMyMiaTour = true
    json.myMiaTourProduct = egyptPublicTour

    rules.push(
      membersOnly(
        [
          new Date('2018-10-28T12:00'),
          new Date('2018-10-30T12:00'),
          new Date('2018-10-31T12:00'),
          new Date('2018-11-01T12:00'),
          new Date('2018-11-02T12:00'),
          new Date('2018-11-03T12:00'),
        ],
        `During Member Preview Week (10/28-11/03) tickets are only available to Mia
         members.`
      )
    )
    rules.push(youthInvestorFree)

    rules.push(membersOnlyWhenMemberHold)
  }

  if (id === 'EX2019-001-AUDIOGUIDE') {
    // Egypt audio guide
    rules.push(upToTwoFreeWithMembership({ atLevel: 150 }))
    // rules.push(upToTwoFreeWithMembership({ atLevel: 150, numFree: 2 }))
    // rules.push(upToTwoFreeWithMembership({ atLevel: 2500, numFree: 44, ruleName: 'Patron' }))
  }

  if (id === 'EX2019-002') {
    // Hearts of Our People
    // rules.push(timedTicketWithInterval(15))
    rules.push(upToTwoFreeWithMembership({ atLevel: 150 }))
    rules.push(fixedReducedPriceWithMembership)
    // json.myMiaDaysTT = []
    json.myMiaDays = ['06/02/2019', '06/04/2019', '06/05/2019', '06/06/2019', '06/07/2019', '06/08/2019', '06/09/2019', '06/20/2019', '07/14/2019']
    json.myMiaContributorDays = ['07/16/2019', '07/17/2019','07/18/2019','07/19/2019','07/20/2019', '07/21/2019']
    // json.myMiaContributorDays = ['01/29/2019', '01/30/2019','01/31/2019','02/01/2019','02/02/2019', '02/03/2019']
    rules.push(myMiaDays(...json.myMiaDays))
    // rules.push(myMiaDaysAfter5pm(...json.myMiaDaysTT))
    rules.push(myMiaDays(...json.myMiaContributorDays, {atLevel: 1}))

    rules.push(promo)

    rules.push(checkCapacity)

    json.customDerivatives = {
      youth: ticket => ({ ...ticket.data, ticket_type: 'Youth', price: 0 }),
    }

    // json.hasMyMiaTour = true
    // json.myMiaTourProduct = egyptPublicTour

    rules.push(
      membersOnly(
        [
          new Date('2018-10-28T12:00'),
          new Date('2018-10-30T12:00'),
          new Date('2018-10-31T12:00'),
          new Date('2018-11-01T12:00'),
          new Date('2018-11-02T12:00'),
          new Date('2018-11-03T12:00'),
        ],
        `During Member Preview Week (10/28-11/03) tickets are only available to Mia
         members.`
      )
    )
    rules.push(youthInvestorFree)

    rules.push(membersOnlyWhenMemberHold)
  }

  if (id === 'EX2019-002-AUDIOGUIDE') {
    // Hearts of Our People
    rules.push(upToTwoFreeWithMembership({ atLevel: 150 }))
    // rules.push(upToTwoFreeWithMembership({ atLevel: 150, numFree: 2 }))
    // rules.push(upToTwoFreeWithMembership({ atLevel: 2500, numFree: 44, ruleName: 'Patron' }))
  }

  if (id === 'EX2020-001') {
    // Artists Respond: American Art and the Vietman War, 1965-1975
    // rules.push(timedTicketWithInterval(15))
    rules.push(upToTwoFreeWithMembership({ atLevel: 150 }))
    rules.push(fixedReducedPriceWithMembership)

    json.myMiaDaysTT = ['10/17/2019', '11/21/2019', '12/19/2019']
    // rules.push(myMiaDaysAfter5pm(...json.myMiaDaysTT))
    json.myMiaDays = ['09/29/2019', '08/30/2019', '10/01/2019', '10/02/2019', '10/03/2019', '10/04/2019', '10/05/2019', '10/06/2019', '10/07/2019']
    json.myMiaContributorDays = ['12/10/2019','12/11/2019','12/12/2019','12/13/2019','12/14/2019','12/15/2019']
    rules.push(myMiaDays(...json.myMiaDays, ...json.myMiaDaysTT))
    rules.push(myMiaDays(...json.myMiaContributorDays, {atLevel: 1}))

    rules.push(promo)

    rules.push(checkCapacity)

    json.customDerivatives = {
      youth: ticket => ({ ...ticket.data, ticket_type: 'Youth', price: 0 }),
    }

    // json.hasMyMiaTour = true
    // json.myMiaTourProduct = egyptPublicTour

    // rules.push(
    //   membersOnly(
    //     [
    //       new Date('2018-10-28T12:00'),
    //       new Date('2018-10-30T12:00'),
    //       new Date('2018-10-31T12:00'),
    //       new Date('2018-11-01T12:00'),
    //       new Date('2018-11-02T12:00'),
    //       new Date('2018-11-03T12:00'),
    //     ],
    //     `During Member Preview Week (10/28-11/03) tickets are only available to Mia
    //      members.`
    //   )
    // )
    // rules.push(youthInvestorFree)

    rules.push(membersOnlyWhenMemberHold)
  }

  // For all exhibitions that don't have their rules set above -
  // checked by counting the rules that have been assigned so far (if `rules.length == 1`, then this exhibition only has a `maxPerCart` rule assigned above)
  if (product_type === 'exhibition' && rules.length === 1) {
    rules.push(upToTwoFreeWithMembership({ atLevel: 150 }))
    rules.push(fixedReducedPriceWithMembership)
    rules.push(promo)

    json.customDerivatives = {
      youth: ticket => ({ ...ticket.data, ticket_type: 'Youth', price: 0 }),
    }

    rules.push(membersOnlyWhenMemberHold)

    // TODO enable these contextually based on product file
    
    // rules.push(timedTicketWithInterval(15))
    rules.push(checkCapacity)

    const myMiaDayEntitlements = entitlements
      .filter(({entitlement_name}) => ['My Mia Day', 'Contributor Week'].indexOf(entitlement_name) > -1)
    false && console.group('myMiaDayEntitlements')
    false && console.info({myMiaDayEntitlements})
    // Three entitlements to handle -
    // 1. My Mia Day (free all day to all members)
    // 2. Third Thursday My Mia Day - free to all members (when timed, only after 5pm)
    // 3. Contributor Day - free to members who have given dollars
    const myMiaEntitlementDates = myMiaDayEntitlements.reduce((datesByName, entitlement) => {
      var enumerateDaysBetweenDates = function(_startDate, _endDate) {
        var startDate = moment(_startDate)
        var endDate = moment(_endDate)
        var now = startDate.clone(), dates = [];

        while (now.isSameOrBefore(endDate)) {
          dates.push(now.format('MM/DD/YYYY'));
          now.add(1, 'days');
        }
        return dates;
      } // https://stackoverflow.com/questions/23795522

      const {entitlement_name, ent_start_date_time, ent_end_date_time} = entitlement
      const days = enumerateDaysBetweenDates(ent_start_date_time, ent_end_date_time)

      datesByName[entitlement_name] = (datesByName[entitlement_name] || []).concat(days)

      return datesByName
    }, {})

    Object.entries(myMiaEntitlementDates).forEach(([name, days]) => {
      if(name === 'My Mia Day') rules.push(myMiaDays(...days))
      if(name === 'Contributor Week') rules.push(myMiaDays(...days, {atLevel: 1}))
      if(false) rules.push(myMiaDaysAfter5pm(...days)) // TODO enable third thursday with timing for next timed exhibition
    })

    // TODO handle members only week similarly, with a custom entitlement?
    // rules.push(
    //   membersOnly(
    //     [
    //       new Date('2018-10-28T12:00'),
    //       ...
    //     ],
    //     `During Member Preview Week (10/28-11/03) tickets are only available to Mia
    //      members.`
    //   )
    // )
    false && console.groupEnd('myMiaDayEntitlements')

    // json.hasMyMiaTour = true
    // json.myMiaTourProduct = egyptPublicTour

    // rules.push(youthInvestorFree)
    //
  }

  if(id === 'EX2020-002') {
    rules.push(requiresCompanionProductWithComparison(
      // requires companion parent
      // WITH SAME selected_date!!
      (product, companion) => {
        const productDate = product.data.selected_date
        const companionDate = companion.data.selected_date
        const productDatesMatch = productDate && companionDate && moment(productDate).isSame(companionDate, 'day')
        console.info('WHWLYS companion rule', {
          productDatesMatch, productDate, companionDate,
          companionId: companion.data.id,
        })

        return companion.data.id === 'GEN-FY21-001' && productDatesMatch
      },
      'Purchasing an exhibit ticket requires a General Admission ticket for the same day'
    ))

    // Only allow one days worth of tickets in the cart at a time
    const limitToSingleDay = (product, cart) => {
      const {id, selected_date} = product.data
      const alreadyHasProductInCart = cart && cart.products
        && cart.products.filter(p => p.data.id === id)
      const selectedDatesDiffer = selected_date 
        && (alreadyHasProductInCart || []).find(p => !moment(p.data.selected_date).isSame(selected_date, 'day'))

      const cartProductDate = alreadyHasProductInCart
        && alreadyHasProductInCart[0].data.selected_date
      const cartProductDateFmt = cartProductDate
        && moment(cartProductDate).format("dddd, MMMM Do YYYY")

      const multipleDaysMessage = `
        There's already a reservation for ${cartProductDateFmt} in your cart.
        Reservations are currently available for one day at a time.
      `

      if(alreadyHasProductInCart && selectedDatesDiffer) {
        return {
          ...product.data,
          valid: false,
          errors: {
            ...product.data.errors,
            mustBeSameDay: multipleDaysMessage,
            // TODO - it would be nice to name this error `selectedDate`
            // but that causes problems with another selectedDate rule?
          },
        }
      } else {
        return {
          ...product.data,
          errors: R.omit(['mustBeSameDay'], product.data.errors),
        }
      }
    }
    rules.push(limitToSingleDay)
    // END Only for sale some <weeks> in the future
  }

  if(product_type === 'admission' || id === 'GEN-FY21-001') {
    rules.push(timedTicketWithInterval(15))
    rules.push(checkCapacity)

    json.customDerivatives = {
      youth: ticket => ({ ...ticket.data, ticket_type: 'Youth', price: 0 }),
    }

    const closedExceptions = json.exceptions.map(exc => {
      return [
        new Date(exc.exc_start_date_time),
        new Date(exc.exc_end_date_time),
        `Closed for ${exc.exception_name}`,
      ]
    })
    rules.push(museumClosed(closedExceptions))

    // Only allow sale for <weeks|days> in the future.
    // If product has a `firstAvailableDate`, use that to determine if the product's first available
    // date is in the future and measure a week from then, otherwise a week from today's date.
    rules.push((product, cart) => {
      const futureSaleWindowWeeks = 12
      const windowMessage = `Tickets are only available ${futureSaleWindowWeeks} week${futureSaleWindowWeeks > 1 ? 's' : ''} in advance`

      const dateNow = new Date()
      const startDate = moment.max(moment(dateNow), moment(product.data.firstAvailableDate))
      const productDate = product.data.selected_date
      // const endDate = startDate.add(17, 'days').endOf('day')
      // Idea: compute a window for valid dates starting soon, and ending in `futureSaleWindowWeeks`
      // full weeks. "Full week" means go all the way through Sunday
      const endDate = startDate.add(futureSaleWindowWeeks, 'weeks').endOf('week')
      const isThursOrLater = moment().day() >= 4
      const endDateWAdjustment = isThursOrLater ? endDate.add(1, 'day') : endDate.add(-4, 'day')
      const withinWindow = moment(productDate)
        .isBefore(endDateWAdjustment)

      if(!productDate || withinWindow) {
        return {
          ...product.data,
          errors: R.omit(['selectedDate'], product.data.errors),
          messages: R.omit(['withinWindow'], product.data.messages),
        }
      } else {
        return {
          ...product.data,
          valid: false,
          errors: {
            ...product.data.errors,
            selectedDate: windowMessage,
          },
          messages: {
            ...product.data.messages,
            withinWindow: windowMessage,
          }
        }
      }
    })
    // END Only for sale some <weeks> in the future
    
    // RULE that adds a 'note' to the product window when a
    // Thu 10-12 'vulnerable persons' timeslot is chosen
    const addTimeBasedMessaging = (product) => {
      const {selected_date, selected_time} = product.data
      const isThursday = selected_date && selected_date.getDay() === 4
      const isTenToNoon = selected_time && Number(selected_time.split(':')[0]) < 12
      const hasTimeBasedMessage = isThursday && isTenToNoon

      if(hasTimeBasedMessage) {
        return {
          ...product.data,
          messages: {
            ...product.data.messages,
            reservedHours: 'Thursdays 10AM–noon are reserved for older adults and those defined by the CDC as vulnerable or at-risk. If you do not identify with these groups, we ask that you visit at other times.'
          }
        }
      } else {
        return {
          ...product.data,
         messages: R.omit(['reservedHours'], product.data.messages),
        }
      }
    }
    false && rules.push(addTimeBasedMessaging)

    // TODO tie this in to other products?
    const requiresSocialDistancing = product => {
      return {
        ...product.data,
        requiresMaskAssent: true,
      }
    }
    if(false) rules.push(requiresSocialDistancing)

    // RULE that limits the selectable time of the GA product to that of the
    // exhibition if the 'and exhibition ticket' box is checked
    //
    // This is tricky because where the rule needs to run (on the GA page)
    // the companion product hasn't been confirmed for inclusion yet. And it's not an
    // issue until closer to the end of the show, so I'm putting it on hold.
    //
    const ensureValidDateForCompanionProduct = (product, cart) => {
      const {companionProducts, selected_date} = product.data
      const hasDatedCompanion = companionProducts && companionProducts.find(prod => {
        return prod.type === 'ongoing'
      })
      const {
        start_date_time: companionStart,
        end_date_time: companionEnd,
        short_title: companionTitle,
      } = hasDatedCompanion 
        ? hasDatedCompanion.data 
        : {}
      const dateWindowsMatch = selected_date && moment(selected_date).isBetween(companionStart, companionEnd)
      // TODO ^^ would checking for a valid companion date be easier just running the companion rules?!

      if(!selected_date || (hasDatedCompanion && dateWindowsMatch)) {
        return {
          ...product.data,
          errors: R.omit(['companionDatesMustMatch'], product.data.errors),
        }
      } else {
        return {
          ...product.data,
          valid: false,
          errors: {
            ...product.data.errors,
            companionDatesMustMatch: `${companionTitle} is only available between ${companionStart} and ${companionEnd}`,
          },
        }
      }
    }
    false && rules.push(ensureValidDateForCompanionProduct)
    // END limit selectable time
    
    const flagHistoricAdmissionTimes = (product, cart) => {
      const { selected_date } = product.data
      const isHistoric = moment(selected_date).isBefore(new Date(), 'day')

      console.info('flagHistoricAdmissionTimes', {
        selected_date,
        isHistoric
      })

      return {
        ...product.data,
        valid: !isHistoric,
        errors: {
          ...R.omit('mustBeFutureDate', product.data.errors),
          ...(isHistoric ? {mustBeFutureDate: 'Your selected date has passed. Please choose a new day to visit Mia'} : {}),
        }
      }
    }
    rules.push(flagHistoricAdmissionTimes)
  }


  if (
    product_type === 'lecture' &&
    lecturePriceOverrideEntitlements &&
    !json.id.match(/AIBTLK/) &&
    false // disable these per Steve Lang's desire to control them per-product -KO 2019-08-19
  ) {
    json.entitlements = [...entitlements, ...lecturePriceOverrideEntitlements]
  }

  if (json.companionProducts && json.companionProducts.length > 0) {
    // save the data as it was defined
    json.companionProductsData = json.companionProducts

    // use data to define the products
    json.companionProducts = json.companionProductsData
      .map(companion => {

        const productData = typeof(companion) === 'object'
          ? companion
          : allProducts.find(p => p.id === companion)

        if(typeof(companion) === 'string') {
          false && console.info('hydrating reference to companionProduct', {companion, productData})
          // debugger
        }

        return productData
      })
      .filter(p => typeof(p) === 'object')
      .map(data => {
        const rulesFromData = (data.rules || []).map(ruleString => {
        // TODO - how to apply arbitrary rules defined in JSON data?
        // prettier-ignore
        return {
          "membersOnly": membersOnly(false, 'You must be a member'),
          "vipCompanionChangesPrice": vipCompanionChangesPrice,
          "companionChangesPrice": vipCompanionChangesPrice,
        }[ruleString]
      }).filter(rule => rule)

      // TODO first check that a product with `data.id` isn't already built?
      const existingCompanion = products.find(p => p.id === data.id)
      const companion = existingCompanion || new Product({
        ...data,
        rules: [
          requiresCompanionProductWithComparison(
            (companion, product) => {
              const parentId = product.data.id
              const companionParentId = companion.data.parentId
              const comparison = parentId === companionParentId

              if(companionParentId.match(/CLASS2020.*-SUMMERYOUTH/)) return true

              console.info('companionProducts requiresCompanionProductWithComparison', {
                product, companion,
                comparison,
                parentId,
                companionParentId,
              })

              return comparison
            },
            // (product, companion) => { return companion.data.id === id },
            'Cannot be purchased by itself'
          ),
          ...rulesFromData,
        ],
        unlisted: true,
        parentId: id,
      })

      if(json.companionProductsData[0] === 'DONATE') {
        console.info('companionProducts', {companion, existingCompanion})
        // debugger
      }

      return companion
    })
  }

  if (json.friendsMembersOnly) {
    rules.push(friendsMembersOnly({ untilDate: json.friendsMembersOnly }))
  }

  if (id === 'SPEV20181027-001') {
    rules.push(promo)
  }

  if (id === 'REG2018-GTR-WORKSHOP') {
    // Adjust price based on meal selection
    rules.push((product, cart) => {
      const { selected_meal } = product.data

      return {
        ...product.data,
        price: ['No Lunch', '', undefined].indexOf(selected_meal) > -1 ? 0 : 20,
      }
    })
  }

  if (id === 'TLK20180303-001') {
    // same as above - jank! Extract into a proper rule?

    rules.push((product, cart) => {
      const { selected_meal } = product.data
      const hasSelectedMeal =
        ['No Lunch', '', undefined].indexOf(selected_meal) > -1

      return {
        ...product.data,
        price: hasSelectedMeal ? 30 : 42,
        nonDiscountedAmount: hasSelectedMeal ? 0 : 12,
      }
    })
  }

  const yup = require('yup')

  if (
    id.match('GRP2018-YOUTHSTD-SPRING') ||
    id.match('GRP2018-YOUTHSTD-SUMMER') ||
    id.match('GRP2018-YOUTHSTD-FALL2018') ||
    id.match('GRP2019-YOUTHSTD-WINTER') ||
    id.match('GRP2019-YOUTHSTD-WINTER2019-VISUAL') ||
    id.match('GRP2019-YOUTHSTD-SUMMER') ||
    id.match('GRP2019-YOUTHSTD-MEA') ||
    id.match('GRP2020-02-04-03-17-TODDLER') ||
    json.useForm === 'StudentInfoForm'
  ) {
    json.gau_code = 'Youth Studio Classes'
    // this is used define and build the form inputs as well as provide validation
    // after it's recursively converted to a `yup` schema and wrapped by
    // `yup.object().shape(…)`. cool
    json.information = {
      student: {
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        birthDate: yup.date().required(),
      },
      address: {
        street: yup.string().required(),
        streetLine2: yup.string(),
        zip: yup
          .string()
          .required()
          .min(5),
        state: yup.string().required(),
        city: yup.string().required(),
      },
      parentGuardian: {
        label: 'Parent/Legal Guardian Name', // TODO use yup `mixed.label` for this? also `meta` could make going between a definition and validat`able schema easier…
        name: yup.string().required(),
        email: yup
          .string()
          .required()
          .email(),
        phone: {
          extra: '(Please include an area code)',
          yup: yup
            .string()
            .required()
            .min(10),
        },
        secondaryPhone: yup.string().min(10),
      },
      parentGuardian2: {
        label: 'Other Parent/Legal Guardian/Pick Up Contact',
        extra:
          "If we need to make contact while your student is in class we will try to contact both primary and secondary guardians. We strongly recommend providing both but understand if that's not possible.",
        name: yup.string(),
        phone: yup.string(),
        secondaryPhone: yup.string(),
      },
      primaryCarePhysician: {
        name: yup.string().required(),
        phone: yup
          .string()
          .required()
          .min(10),
      },
      pickupNotAllowedBy: {
        label:
          'Is there a specific person or persons NOT allowed to pick up your child?',
        component: 'textarea',
        yup: yup.string().required(),
      },
      allergiesSpecialLearningMedical: {
        label:
          'List any allergies, special learning needs, medical conditions or medications.',
        component: 'textarea',
        yup: yup.string().required(),
      },
      additionalInfo: {
        label: 'Is there anything else we should know about your child?',
        component: 'textarea',
        yup: yup.string().required(),
      },
      permissionForPhotography: {
        extra:
          'I understand and accept that photographs may be taken of my child and used for educational or promotional purposes.',
        type: 'radio',
        choices: ['Yes', 'No'],
        yup: yup
          .string()
          .matches(/yes|no/i)
          .required(),
      },
    }

    if(!json.entitlements.find(e => e.entitlement_name === 'Mia Staff Discount'))
      json.entitlements.push(
        buildEntitlement({
          entitlement_name: 'Mia Staff Discount',
          price_override: '50',
          user_type: 'VE',
        })
      )
  }

  // const buildYupSchema = require('./useForm')

  if(json.useForm && json.useForm !== 'StudentInfoForm') {
    const {useForm} = json
    if(useForm.__type && useForm.__type === 'yup') {
      const {__type, ...fields} = useForm

      // json.information = buildYupSchema(useForm)
      json.information = Object.keys(fields).reduce((obj, fieldName) => {
        const fieldSpecification = useForm[fieldName]
        const isSimpleSpec = fieldSpecification.length
        // differentiate field spec: array is a simple yup definition
        // object means there is more complexity and it should contain a `yup`
        // key with the 'yup steps'
        const yupSteps = isSimpleSpec
          ? fieldSpecification
          : fieldSpecification.yup

        const yupObj = yupSteps.reduce((composed, step) => {
          return composed[step]()
        }, yup)

    obj[fieldName] = isSimpleSpec
      ? yupObj
      : {...fieldSpecification, yup: yupObj }

    return obj
  }, {})
    } else if(useForm === 'requireSchoolName') {
      json.information = {
        schoolName: yup.string().required(),
      }
    }
  }

  if (id.match('AIBPREV20180425-001') || id.match('AIBPATDON20180425-001') ||
    id.match('AIBPATDON20190410-001') || id.match('AIBPREV20190410-001')
    || id.match('AIBPREV20190410-002')
  ) {
    const baseInfo = {
      name: {
        label: 'Name(s)',
        yup: yup.string().required(),
        component: 'textarea',
      },
      remainAnonymous: {
        label: 'I/we wish to remain anonymous',
        type: 'radio',
        choices: ['No', 'Yes'],
        yup: yup
          .string()
          .matches(/yes|no/i)
          .required(),
      },
      // attendingParty: {
      //   label: 'I/we will attend the April 25 Preview Party',
      //   type: 'radio',
      //   choices: ['Yes', 'No'],
      //   yup: yup
      //     .string()
      //     .matches(/yes|no/i)
      //     .required(),
      // },
      // seatingPreferences: {
      //   label: 'Please list the names of guests you wish to be seated with',
      //   yup: yup.string(),
      //   component: 'textarea',
      // },
      address: {
        street: yup.string().required(),
        streetLine2: yup.string(),
        zip: yup
          .string()
          .required()
          .min(5),
        state: yup.string().required(),
        city: yup.string().required(),
      },
      phone: yup.string().required(),
      email: yup
        .string()
        .email(),
    }

    rules.push(requireData('selected_sliding_scale'))
    json.showSlidingScale = true
    json.slidingScaleLabel = 'Support Level'

    // The Donation product doesn't care about where someone sits
    json.information = R.omit(
      id === 'AIBPATDON20180425-001' ? ['seatingPreferences'] : [],
      baseInfo
    )
  }

  if(id === 'AIBPREV20200422-001') {
    rules.push(requireData('selected_sliding_scale'))
    json.showSlidingScale = true
    json.slidingScaleLabel = 'Support Level'
  }

  if (json.companionProducts && json.companionProducts.find(product => product.data && product.data.companion_price_change)) {
    rules.push(vipCompanionChangesPrice)
  }

  if(id === 'AIBPREV20200422-001') {
    rules.push(requireData('selected_sliding_scale'))
    json.showSlidingScale = true
    json.slidingScaleLabel = 'Support Level'
  }

  // AIB lecture with two different times
  if (id === 'AIBWKSP20180427-001') {
    rules.push(requireTimedTicket(['5:30 pm'], ['7:30 pm']))
    rules.push(checkCapacity)

    json.selected_date = new Date(json.start_date_time)
    json.require_time = ['5:30 pm', '7:30 pm']
    json.trackInstanceCapacity = true
    json.defaultCapacity = 40
    json.simpleInstanceTracking = true
  }

  // Enable strict capacity tracking for June 22 talk only.
  // Also don't let it show in production until it's ready.
  if (id === 'TLK20180622-001' || id === 'TLK20180622-002') {
    rules.push(maxPerCart(2))
    json.strictCapacity = true

    if (
      process.env.NODE_ENV === 'development' ||
      window.location.origin.match('https://dev-ticket')
    ) {
      if (id === 'TLK20180622-001') json.unlisted = false
      json.onSaleDate = '2018-05-21T09:45:00'
    }
  }

  if (id === 'SPEV20180802-001') {
    rules.push(maxPerCart(2))
    rules.push(
      affinityMembersOnly(
        'This event is open only to Affinity Members. For more information please call Mia staff at 612-870-6323 during museum hours.'
      )
    )
  }

  if (id === 'SPEV20200116-001') {
    rules.push(
      affinityMembersOnly(
        'This event is open only to Affinity Members. For more information please call Mia staff at 612-870-6323 during museum hours.'
      )
    )
  }

  if (id.match('GRP2018-SKETCHING-FALL2018')) {
    json.information = {
      name: yup.string().required(),
      email: yup
        .string()
        .required()
        .email(),
    }

    json.require_attendee_name = false
    // json.entitlements.push(
    //   buildEntitlement({
    //     entitlement_name: 'Mia Staff Discount',
    //     price_override: '50',
    //     user_type: 'VE',
    //   })
    // )
  }
  if (json.group_id === 'GRP2018-SKETCHING-FALL2018') {
    rules.push(maxPerCart(2))
  }

  if(id === 'AIBDEDICATIONS2019-001') {
    rules.push(requireData('selected_sliding_scale'))
    json.showSlidingScale = true
    json.slidingScaleLabel = 'Support Level'
    json.information = {
      dedication: {
        yup: yup.string().required(),
        component: 'textarea',
      },
      name: {
        label: 'Dedicated By',
        yup: yup.string().required(),
        component: 'textarea',
      },
      email: yup
        .string()
        .email()
        .required(),
      phone: yup.string(),
      address: {
        street: yup.string().required(),
        streetLine2: yup.string(),
        zip: yup
          .string()
          .required()
          .min(5),
        state: yup.string().required(),
        city: yup.string().required(),
      },
    }
  }

  if (json.membersOnly) {
    // TODO handle products that are members only just until a certain date
    rules.push(membersOnly(false, 'This event is open only to My Mia Members.'))
  }

  if (json.userSelectedPrice) {
    const hasEntitlements = json.entitlements && json.entitlements.length > 0
    const pricePoints = hasEntitlements
      ? json.entitlements.map(({price_override}) => price_override/100)
      : [5, 10, 25, 50, 100]

    rules.push(flexiblePricing({ pricePoints }))
  }

  if(json.affinityMembersOnly) {
    const {untilDate, eligibleGroup} = typeof json.affinityMembersOnly === 'object'
      ? json.affinityMembersOnly
      : { untilDate: json.affinityMembersOnly }

    const defaultMessage = 'This event is open only to Affinity Members. For more information please call Mia staff at 612-870-6323 during museum hours.'
    const limitDatePassed = untilDate && new Date(untilDate) <= new Date()
    const affinityOnlyRule = affinityMembersOnly(defaultMessage, eligibleGroup)

    if(!limitDatePassed) {
      rules.push(affinityOnlyRule)
    }
  }

  if(json.type === 'ongoing' || json.type === 'onetime') {
    const startDate = moment(json.start_date_time)
      .startOf('day')
      .toDate()
    const endDate = moment(json.end_date_time)
      .endOf('day')
      .toDate()
    const scheduleRule =
      json.type === 'onetime'
        ? scheduleOnce(startDate)
        : scheduleDaily(startDate, endDate, json.product_type === 'group')

    rules.push(scheduleRule)

    const closedExceptions =
      json.exceptions &&
      json.exceptions.map(exc => {
        return [
          new Date(exc.exc_start_date_time),
          new Date(exc.exc_end_date_time),
          exc.message || `Closed for ${exc.exception_name}`,
        ]
      })
    if (closedExceptions && closedExceptions.length > 0)
      rules.push(museumClosed(closedExceptions))
  }

  // Allow promos for all products, not just special exhibitions
  // But if the promo rule is already added, don't duplicate it
  if(rules.indexOf(promo) <= -1) {
    rules.push(promo)
  }

  const splitContent = json.__content?.split('--\n') ?? ['', '']
  const summary = splitContent && splitContent[0]
  const details = splitContent && splitContent[1]

  json.ticket_type = json.ticket_type || 'Adult'

  const product = new Product({
    product_type: json.product_type,
    name: json.title,
    body: summary + '. ' + details,
    price: json.base_price / 100,
    rules,
    derivatives: {
      adult: [],
      child: [
        ticket => ({ ...ticket.data, ticket_type: 'Child', price: 0 }), // child tickets are all free
      ],
      ...(json.customDerivatives || {}),
    },
    valid: true,
    ...json,
    summary,
    details,
    taxable: json.taxable || false,
  })

  // TESTING NEW RULE - if `clearDiscounts` is set, remove those specified discounts from this product instance.
  product.rules.push(clearDiscounts)

  return product
}

// IDEA - one product file can "blow up" into more than one product. Example: companion products and repetitive youth classes.
//
// For the friends lecture, accompanying tours are defined as companionProducts. Those IDs need to go into the capacity CMS, which requires a product 
//
// For Youth classes there is a group file and then several classes belonging to that. The info is mostly the same, save dates and class info
//
// This looks through a single product file, and possibly returns an array of products: the parent and any sub-products that should be created
function hydrateSubProducts(product) {
  if(product.product_type === 'group' && product.products && typeof(product.products[0]) === 'object') {
    const useForm = product.useForm || (product.id.match(/AIB/) ? undefined : 'StudentInfoForm') 

    const parent = {
      ...product,
      products: product.products.map(({id}) => id), // TODO is this working right now?
      useForm,
    }

    const children = product.products.map(child => {
      return {
        ...product,
        ...child,
        product_type: parent.group_product_type,
        useForm: useForm,
        group_id: product.id,
        __content: child.description,
      }
    })

    return [ parent, ...children ]
  } else if(product.companionProducts && product.companionProducts.length > 0) {
    const companionProducts = product.companionProducts
    const shouldHydrateCompanions = true

    if(shouldHydrateCompanions) return [
      product,
      ...companionProducts
        .filter(data => typeof data === 'object') // don't hydrate companion products expressed as an ID string
        .map(companion => {
        return {
          ...companion,
          // Some companion products have their own special rules, defined as text.
          // That's janky. This allows us to "promote" those products into a full
          // hive product, which means they can run through the same rule system!
          rules: [],
          // TODO how to enable custom rules on companion Products? ^^
          title: companion.name,
          trackCapacity: true
        }
      })
    ]

    // else
    return product
  } else {
    return product
  }
}

const allProducts = productData
  .map(product => hydrateSubProducts(product))
  .flat()

let products = []

allProducts
  .forEach(product => products.push(productFromJson(product)))

// add any internally-built products here.
// Leaving this as a vestige of when we had products only built in the code.
products = products.concat([])
  .filter(p => p)

export default products
