/* eslint-disable no-mixed-operators */
// TODO check these 
const R = require('ramda')
const moment = require('moment')

const DEBUG = window.DEBUG

// TODO how to do this with babel7?
require('array.prototype.find').shim()

const fiveDollarsOff = ticket => {
  // fiveDollarsOff
  return {
    ...ticket.data,
    // TODO can't have negative price on a product
    annotation: 'apply five dollar discount',
    discounts: {
      ...ticket.data.discounts,
      fiveDollarsOff: 5,
    },
  }
}

const addArbitraryInformationRule = ticket => ({
  ...ticket.data,
  newInfo: 'something',
  annotation: 'added newInfo',
})

// FIXME applyRules doesn't pass the full Product object through the reducer
const upToTwoFree = (ticket, cart, { onDates, ruleName, numFree } = {}) => {
  // upToTwoFree
  const { discounts } = ticket.data

  const actingRuleName = ruleName || 'upToTwoFree'
  const numberFree = numFree || 2

  const isEquivalentTicketType = p =>
    p.data.ticket_type === ticket.data.ticket_type ||
    (p.data.ticket_type === 'Adult' && ticket.data.ticket_type === 'Member') ||
    (p.data.ticket_type === 'Member' && ticket.data.ticket_type === 'Adult')
  const onSameDay = p =>
    ticket.data.type === 'timed'
      ? p.data.selected_date === ticket.data.selected_date
      : true
  // FIXME - comparing against name here probably is not right. Wait for product IDs
  const matchingProducts =
    cart &&
    cart.products.filter(
      p =>
        p.name === ticket.data.name && isEquivalentTicketType(p) && onSameDay(p)
    )

  const alreadyDiscountedMatchingProducts = matchingProducts
    ? matchingProducts.filter(
        // if other products have the same discount, OR they have a my mia day discount,
        // tally them as 'already discounted'. We don't want the my mia day tickets to
        // add extra free tickets to a cart
        p =>
          p.data.discounts &&
          (p.data.discounts[actingRuleName] || p.data.discounts['myMiaDays'])
      )
    : [ticket]

  let nextData

  if (cart && alreadyDiscountedMatchingProducts.length < numberFree) {
    nextData = {
      ...ticket.data,
      discounts: {
        ...ticket.data.discounts,
        [actingRuleName]: ticket.data.price,
      },
      ticket_type: 'Member',
      previous_ticket_type: ticket.data.ticket_type,
      annotation:
        "discounted 100% for 'up to two free tickets' - " + actingRuleName,
    }
  } else if (cart && alreadyDiscountedMatchingProducts.length > numberFree) {
    nextData = {
      ...ticket.data,
      discounts: R.omit([actingRuleName], discounts),
      ticket_type: 'Member',
      previous_ticket_type: ticket.data.previous_ticket_type,
    }
  } else {
    nextData = {
      ...ticket.data,
      ticket_type:
        ticket.data.ticket_type || ticket.data.previous_ticket_type || 'Adult',
      previous_ticket_type: null,
    }
  }

  return nextData
}

function hasMembership(user) {
  if (!user) return false

  if (
    user.salesforce_account_id === '001q000000hiXnDAAU' ||
    user.salesforce_account_id === '0014100000p0GPPAA2'
  )
    return false

  const membershipInData = user.memberships &&
      user.memberships.find(
        m => m.member_type === 'Mia Membership' && m.member_status !== 'Expired'
      )
  return (
    (user.isMember && user.isMember()) ||
    (user.data && user.data.membership) ||
    membershipInData
  )
}

function _containsMembership(cart, minimumLevel = 0) {
  if (!cart) return false

  const membershipInCart =
    cart &&
    cart.products.find(
      p =>
        p.data.product_type === 'mia_membership' ||
        p.data.id === 'SUSTAINING_MEMBERSHIP'
    )

  const { user } = cart
  const activeMembership = membershipInCart || (user && hasMembership(user))

  if (!activeMembership) return false

  // TODO handle sustaining membership passed through SF data?
  const isSustaining =
    activeMembership &&
    activeMembership.data &&
    activeMembership.data.id === 'SUSTAINING_MEMBERSHIP'

  // TODO extract dollar value <-> membership label logic into a helper
  // it's needed in multiple places
  const baseMembershipLevelPrices = {
    Free: 0,
    Contributor: 50, // TODO identify cash value of these memberships
    Dual: 150, // …
    Life: 150, // …
    // Reciprocal: 150, // …
    Investor: 150,
    Partner: 500,
    "Patron's Circle": 2500,
    "Patrons' Circle": 2500,
    "Chairmans' Circle": 2500,
    "Chairman's Circle": 2500,
    "Director's Circle": 2500,
    "Directors' Circle": 2500,
  }
  // TODO what if selected_amount isn't an even increment of our desired membership structure?

  const { data: cartMembershipData } = // , member_level: cartMembershipLevel
    membershipInCart || {}

  const membershipLevelAmount =
    cartMembershipData && cartMembershipData.selected_amount
      ? cartMembershipData.selected_amount
      : // ? baseMembershipLevelPrices[cartMembershipLevel]
        baseMembershipLevelPrices[activeMembership.member_level]

  const membershipLevel = isSustaining
    ? membershipLevelAmount * 12.5
    : membershipLevelAmount

  const adequateMembershipLevel =
    activeMembership && membershipLevel >= minimumLevel

  if (DEBUG) {
    console.info('_containsMembership', {
      activeMembership,
      membershipLevel,
      adequateMembershipLevel,
    })
    // debugger
  }

  return adequateMembershipLevel ? activeMembership : false
}

// prettier-ignore
const upToTwoFreeWithMembership = ({ atLevel, onDates, ruleName, numFree } = {}) => (ticket, cart) => {
  // upToTwoFreeWithMembership
  const membership = _containsMembership(cart, atLevel || 0)

  if (membership) {
    const investorMembership = _containsMembership(cart, 150)
    const partnerAndUpMembership = _containsMembership(cart, 500)
    const patronAndUpMembership = _containsMembership(cart, 2500)
    let _numFree = numFree || (partnerAndUpMembership ? 4 : investorMembership ? 2 : 0)
    // if(ticket.data.id === 'AFFINITY') _numFree = 1
    if(ticket.data.id === 'EX2019-001-AUDIOGUIDE' && patronAndUpMembership) _numFree = 100
    /* FIXME: for exhibition tickets numFree should be overrideable,
     * but for affinity memberships it shouldn't override the same way?
     * Does this entitlement need to be defined per-product?
     * So 'Power and Beauty' would have rules that look like:
     *     upToNumFreeWithMembership({ atLevel: 150, numFree: 2 }),
     *     upToNumFreeWithMembership({ atLevel: 500, numFree: 4 }),
     */

    return upToTwoFree(ticket, cart, { atLevel, onDates, ruleName: ruleName || 'upToTwoFreeWithMembership', numFree: _numFree })
  } else {
    // TODO this is tricky because we pass onto the next rule and the name changes
    // but it's probably better this way?

    return { ...ticket.data, discounts: R.omit(['upToTwoFree', 'upToTwoFreeWithMembership', 'myMiaThursday', ruleName], ticket.data.discounts) }
  }
}

const fixedReducedPriceWithMembership = (ticket, cart, user) => {
  // fixedReducedPriceWithMembership
  // TODO: why don't getters for name and price work here?
  // A: because ticket is a simplified array `{annotation: , data: {…}}`
  // grumble
  const nonFreeExhibitionTicket = p => {
    const discountedPrice =
      p.data.price -
      R.sum(
        R.values(R.omit(['fixedReducedPriceWithMembership'], p.data.discounts))
      )
    return discountedPrice !== 0 && discountedPrice >= 16
  }

  if (_containsMembership(cart) && nonFreeExhibitionTicket(ticket)) {
    // handle discounts differently based on ticket_type
    // TODO why isn't this working? the || 16 is a bad hack
    const discountedPrice =
      ticket.data.ticket_type === 'Youth' || ticket.data.price === 16
        ? ticket.data.price * (0.25 / 2) // 16*.25/2 = 2, resulting in $14 Youth tickets
        : ticket.data.ticket_type === 'Student'
          ? ticket.data.price * 0.222222222222222
          : ticket.data.price * 0.2 // 20% off, 20 -> 16

    if (DEBUG) {
      console.info('fixedReducedPriceWithMembership w membership', {
        discountedPrice,
        ticket_type: ticket.data.ticket_type,
      })
    }

    return {
      ...ticket.data,
      discounts: {
        ...ticket.data.discounts,
        fixedReducedPriceWithMembership: discountedPrice,
      },
      ticket_type: 'Member',
      previous_ticket_type: ticket.data.ticket_type,
      annotation: 'reduced membership price',
    }
  } else {
    return {
      ...ticket.data,
      discounts: R.omit(
        ['fixedReducedPriceWithMembership'],
        ticket.data.discounts
      ),
      ticket_type:
        ticket.data.ticket_type || ticket.data.previous_ticket_type || 'Adult',
      previous_ticket_type: null,
    }
  }
}

const percentageDiscountWithMembership = (
  discountRate,
  membershipLevel = 0
) => (product, cart, user) => {
  // percentageDiscountWithMembership
  const {
    price,
    discounts,
    ignoreDiscounts,
    nonDiscountedAmount,
  } = product.data

  const pctDiscount = (price - (nonDiscountedAmount || 0)) * discountRate / 100

  const hasMembership = _containsMembership(cart, membershipLevel)
  const shouldApply = hasMembership && !ignoreDiscounts

  const previousTicketType =
    product.data.previous_ticket_type || product.data.ticket_type

  const nextData = shouldApply
    ? {
        discounts: {
          ...discounts,
          percentageDiscountWithMembership: pctDiscount,
        },
        ticket_type: 'Member',
        previous_ticket_type: product.data.ticket_type,
        annotation: `pct discount with membership - at level ${membershipLevel}`,
      }
    : {
        discounts: R.omit(['percentageDiscountWithMembership'], discounts),
        ticket_type: previousTicketType || 'Adult',
        previous_ticket_type: null,
        annotation: 'membership discount removed',
      }

  return {
    ...product.data,
    ...nextData,
  }
}

const halfPriceWithMembership = percentageDiscountWithMembership(50)

const scheduleOnce = date => {
  // scheduleOnce
  return (product, cart, user) => {
    return { ...product.data, scheduleType: 'onetime', date: date }
  }
}

/* This actually discounts all same tickets by 50%, so
 * two for one
 * 3 for 1.5
 * 4 for two
 * …
 */
const buyOneGetOneRule = (ticket, cart, user) => {
  // buyOneGetOneRule
  const { price, discounts } = ticket.data

  const moreThanOneOfTheSameProduct =
    (cart && cart.products.filter(p => p.name === ticket.name).length > 1) ||
    (cart && cart.products.filter(p => p.title === ticket.title).length > 1)

  if (moreThanOneOfTheSameProduct) {
    return {
      ...ticket.data,
      discounts: { ...discounts, buyOneGetOneRule: price / 2 },
    }
  } else {
    return {
      ...ticket.data,
      discounts: R.omit(['buyOneGetOneRule'], discounts),
    }
  }
}

function flexiblePricing({ pricePoints, userSelected, chargeLater = false }) {
  return function(product, cart, user) {
    return {
      ...product.data,
      suggestedPricePoints: pricePoints,
      price: chargeLater ? 0 : product.data.selected_amount,
      userSelected: !!userSelected,
      // TODO error handling
      // - when price isn't selected
      // - is there an invalid price? (negative dollars?)
      // valid: true,
    }
  }
}

function scheduleDaily(startDate, endDate, skipValidation = false) {
  return function(product, cart, user) {
    if (skipValidation) return { ...product.data, valid: true }

    function checkDate(date) {
      return date >= startDate && date <= endDate && date.getDay() !== 1
    }

    const { selected_date } = product.data
    const validDate = selected_date && checkDate(selected_date)

    // FIXME how to remove rule-specific validation when a data error is resolved?
    if (selected_date && validDate) {
      return {
        ...R.omit(['errorMessage'], product.data),
        valid: true,
      }
    } else {
      const { dataNeeded } = product.data
      const nextDataNeeded = R.uniq(
        (dataNeeded || []).concat(['selected_date'])
      )

      let errorMessage = 'Invalid date selected.'

      // TODO change this to pass `errors: {}` - but I think the calendar is hardcoded
      // to depend on errorMessage here
      return {
        ...product.data,
        valid: false,
        dataNeeded: nextDataNeeded,
        errorMessage,
        startDate,
        endDate,
      }
    }
  }
}

function friendsMembersOnly({ untilDate }) {
  return function(product, cart, user) {
    const _user = (cart && cart.user) || user
    const hasFriendsMembership =
      (_user &&
        _user.memberships &&
        _user.memberships.find(
          m => m.member_type === 'Friends Membership' && m.member_level != null
        )) ||
      (cart && cart.products.find(p => p.data.type === 'friendsMembership'))

    const date = untilDate && new Date(untilDate)
    const withinRestrictedTime = date && new Date() < date

    if (!withinRestrictedTime || hasFriendsMembership) {
      return {
        ...product.data,
        errors: R.omit(['friendsOnly'], product.data.errors),
        valid: true,
      }
    } else {
      const errorMessage = `A Friends membership is required to purchase this`
      return {
        ...product.data,
        valid: false,
        errors: {
          ...product.data.errors,
          friendsOnly: errorMessage,
        },
      }
    }
  }
}

/**
 * Declares a 'member preview day' only available to eligible members
 *
 * @param {Date} date
 *
 * @returns {Object} data
 */
function memberPreviewDay(date) {
  return membersOnly(
    [date],
    `${date.toDateString()} is a Member Preview Day - become a My Mia Member to attend.`
  )
}

function filterProductsByDateList(products, dateList) {
  return products.filter(p => {
    const pDate = p.data.selected_date
    return dateList.find(restrictedDate => {
      return (
        restrictedDate.setHours(0, 0, 0) < pDate &&
        pDate < restrictedDate.setHours(23, 59, 59)
      )
    })
  })
}

/**
 * Makes a product available only to members on a list of dates
 *
 * @param {Array.<Date>} dates the dates of the restricted days
 * @param {String} errorMessage what to say when the restriction is applied to a product
 *
 * @returns {Object} data
 */
function membersOnly(onDates = [], errorMessage) {
  return function(product, cart, user) {
    const membership = _containsMembership(cart)

    // FIXME deduplicate with `upToTwoFree`
    const matchingProducts = cart
      ? cart.products.filter(p => p.name === product.data.name)
      : [product]

    // find matching products with a selected date. These must be checked
    // against restricted dates, and if they fall on a restricted date
    // the restriction must be exceeded with a valid membership
    const datedMatchingProducts =
      onDates && onDates.length > 0
        ? filterProductsByDateList(matchingProducts, onDates)
        : matchingProducts

    const hasPromo = product.data.promo

    if (datedMatchingProducts.length > 0) {
      if (membership || hasPromo) {
        return {
          ...product.data,
          valid: true,
          errors: R.omit(['membersOnly'], product.data.errors),
        }
      } else {
        return {
          ...product.data,
          valid: false,
          errors: {
            ...product.data.errors,
            membersOnly: errorMessage || 'membership required on that date',
          },
        }
      }
    } else {
      return {
        ...product.data,
        errors: R.omit(['membersOnly'], product.data.errors),
      }
    }
  }
}

/**
 * Restricts a product to members joined to an Affinity group.
 *
 * @returns {Object} data
 */
function affinityMembersOnly(errorMessage, eligibleGroup) {
  return function(product, cart, user) {
    const _hasMembership = _containsMembership(cart, 150) || hasMembership(user)

    const membershipWithAffinity =
      _hasMembership &&
      ((cart && cart.user && cart.user.memberships) || [_hasMembership]).find(
        memb => memb.affinities && memb.affinities.length > 0
      )

    const hasAffinity = !!membershipWithAffinity
    const {member_level} = membershipWithAffinity || {}
    // A membership with both 'Free' and an Affinity listed has expired, and dropped 
    // down from Investor+ to Free, meaning we cannot continue to grant affinity
    // privileges
    const membershipExpired = member_level === 'Free'
    // TODO - factor in expiration date?
    // const membershipExpirationDate = expiration_date && new Date(expiration_date)

    // TODO this only works for a single eligible group! Fix that.
    // For now I'll rename to variable to `eligibleGroup` to reflect that
    const activeAffinities = R.flatten(R.uniq([
      cart && cart.user && cart.user.memberships && cart.user.memberships.map(m => m.affinities),
      _hasMembership.affinities
    ].filter(array => array)))
    const hasEligibleAffinity = hasAffinity && eligibleGroup && activeAffinities.indexOf(eligibleGroup) > -1

    if (hasAffinity && !membershipExpired && (eligibleGroup ? hasEligibleAffinity : true)) {
      return {
        ...product.data,
        valid: true,
        errors: R.omit(['affinityMembersOnly'], product.data.errors),
      }
    } else {
      return {
        ...product.data,
        valid: false,
        errors: {
          ...product.data.errors,
          affinityMembersOnly: errorMessage || 'Affinity membership required',
        },
      }
    }
  }
}

function assignLevelBasedOnPricePaid(product, cart, user) {
  const { price, selected_amount } = product.data
  const isSustaining = product.data.id === 'SUSTAINING_MEMBERSHIP'
  if (price || selected_amount) {
    const membershipLevel = getMembershipLevelFromPrice(
      selected_amount || price,
      isSustaining
    )
    return {
      ...product.data,
      membershipLevel,
    }
  } else {
    return product.data
  }
}

function getMembershipLevelFromPrice(price, isSustaining) {
  const yearlyPrice = isSustaining ? price * 12.5 : price
  const level =
    yearlyPrice < 150
      ? 'free'
      : yearlyPrice >= 150 && yearlyPrice < 500
        ? 'investor'
        : yearlyPrice >= 500 && yearlyPrice < 5000
          ? 'yearlyPriceatron'
          : "director's circle"

  return level
}

const { secondFullWeekend } = require('../utils/calendar')

function scheduleRepeat__secondFullWeekend(product, cart, user) {
  function checkDate(date) {
    const [secondSaturday, secondSunday] = secondFullWeekend(date)
    const endOfSunday = new Date(
      new Date(secondSunday).setDate(secondSunday.getDate() + 1)
    )

    const conditions = [
      [0, 6].indexOf(date.getDay()) > -1, // saturday or sunday
      secondSaturday <= date,
      date <= endOfSunday,
      // regular PCH tours aren't available in december
      product.data.id === 'PCHTOUR' && date.getMonth() !== 11,
      // winterlights only available in dec. Test this when winterlights
      // comes back up for sale
      // product.data.id === 'WINTERLIGHTS' && date.getMonth() == 11,
    ]

    return conditions.every(cond => cond)
  }

  const { selected_date } = product.data

  const { dataNeeded } = product.data
  const nextDataNeeded = R.uniq((dataNeeded || []).concat(['selected_date']))

  if (selected_date && checkDate(selected_date)) {
    return {
      ...product.data,
      errors: R.omit(['selectedDate'], product.data.errors),
      dataNeeded: R.without(['selected_date'], nextDataNeeded),
      valid: true,
    }
  } else {
    return {
      ...product.data,
      valid: false,
      dataNeeded: nextDataNeeded,
      errors: {
        ...product.data.errors,
        selectedDate:
          'Purcell Cutts Tours are offered the second full weekend of each month.',
      },
    }
  }
}
function scheduleRepeat__eachWeekend(product, cart, user) {
  function checkDate(date) {
    const conditions = [
      [0, 6].indexOf(date.getDay()) > -1, // saturday or sunday
    ]

    return conditions.every(cond => cond)
  }

  const { selected_date } = product.data

  const { dataNeeded } = product.data
  const nextDataNeeded = R.uniq((dataNeeded || []).concat(['selected_date']))

  if (selected_date && checkDate(selected_date)) {
    return {
      ...product.data,
      errors: R.omit(['selectedDate'], product.data.errors),
      dataNeeded: R.without(['selected_date'], nextDataNeeded),
      valid: true,
    }
  } else {
    return {
      ...product.data,
      valid: false,
      dataNeeded: nextDataNeeded,
      errors: {
        ...product.data.errors,
        selectedDate:
          'Winterlights Tours are offered six weekends from November 25 - December 31 (excluding December 24).',
      },
    }
  }
}

const requireData = (...keys) => (product, cart, user) => {
  // requireData
  const { data } = product
  const { dataNeeded, errors } = product.data
  const nextDataKey = keys[0]
  const nextDataNeeded = R.uniq((dataNeeded || []).concat([nextDataKey]))

  const errorKey = 'requireData:' + keys.join(',')

  const validity =
    data && data[nextDataKey]
      ? {
          valid: true,
          errors: R.omit([errorKey], errors),
        }
      : {
          valid: false,
          errors: {
            ...errors,
            [errorKey]: `missing required ${nextDataKey}`,
          },
          dataNeeded: nextDataNeeded,
          [`suggested${nextDataKey}`]: keys[1],
        }

  return {
    ...data,
    ...validity,
  }
}

/**
 * Configures one or more windows of time that a product should be for sale.
 *
 * @param {...Array.<[startDate, endDate, addlConditions]>} dates
 *
 * or
 *
 * @param {Date} startDate
 * @param {Date} endDate
 *
 * @returns {Object} data
 */
function restrictOnSaleDates(...dates) {
  const dateNow = new Date()

  // accept two dates for beginning and end
  // or an array of `[begin, end]` dates
  const saleDates = dates[0].map ? dates : [dates]

  const [firstOnSale, firstOffSale] = saleDates[0]

  return function(product, cart, user) {
    const baseNextData = {
      ...product.data,
      on_sale_date: firstOnSale,
      off_sale_date: firstOffSale || product.data.date,
    }

    const withinValidSalesWindow = saleDates.find(
      ([onSale, offSale, ...additionalRules]) => {
        return (
          additionalRules.every(rule => rule(product, cart, user)) &&
          onSale < dateNow &&
          dateNow < (offSale || product.data.date)
        )
      }
    )

    const validity = withinValidSalesWindow
      ? { valid: true }
      : {
          valid: false,
          errorMessage: 'product is not ready for sale',
        }

    return { ...baseNextData, ...validity }
  }
}

function requireTimedTicket(...times) {
  return function(product, cart, user) {
    const { selected_time } = product.data

    const { dataNeeded } = product.data
    const nextDataNeeded = R.without(
      ['selected_time'],
      R.uniq((dataNeeded || []).concat(['selected_time']))
    )

    // accept a mishmash of simple times `['1am', '2am']` or a nested array of
    // `[time, addlCond]` where addlCond is a function that determines
    // whether that time is valid for in this instance
    const validTimes = (times.length === 1 && times[0].map ? times[0] : times)
      .map(time => (time.map ? time : [time]))
      .filter(([time, ...rules]) =>
        rules.every(rule => rule(product, cart, user))
      )

    const validity = validTimes.find(([time, ...addlConds]) => {
      return (
        addlConds.every(rule => rule(product, cart, user)) &&
        time === product.data.selected_time
      )
    })
      ? {
          valid: R.isNil(product.data.valid) ? true : product.data.valid,
          // dataNeeded: nextDataNeeded,
          errors: R.omit(['requireTimedTicket'], product.data.errors),
        }
      : {
          valid: false,
          errors: {
            ...product.data.errors,
            requireTimedTicket: selected_time
              ? 'Invalid time.'
              : 'Choose a time.',
          },
          dataNeeded: nextDataNeeded.concat(['selected_time']),
        }

    return {
      ...product.data,
      ...validity,
      availableTimes: R.map(R.head)(validTimes),
    }
  }
}

const timedTicketWithInterval = interval => {
  // timedTicketWithInterval
  return (product, cart, user) => {
    let availableHours =
      '10 am_11 am_12 pm_1 pm_2 pm_3 pm_4 pm_5 pm_6 pm_7 pm_8 pm'
    // for now we only have 10 an 15 minute intervals…
    let availableTimes = interval === 10 ? '00 10 20 30 40 50' : '00 15 30 45'

    const times = R.flatten(
      availableHours.split('_').map(hour => {
        return availableTimes.split(' ').map(minute => {
          return hour.replace(/(\D+)/, `:${minute}$1`)
        })
      })
    )
      .filter(time => {
        const dateAttending = product.data.selected_date
        const dayOfWeek = dateAttending && dateAttending.getDay()

        /* filter down available times based on day of week
         * for now crudely, with a hardcoded switch statement defining regex matches
         * … … …
         * TODO
         * build these rules somehow by JSON in the product definition instead of hardcoding
         * Or use the capacity system to truly pull the list of valid time slots
         */
        switch (dayOfWeek) {
          case 0: // sunday 10-5 during reduced covid hours
            return !time.match(/^(5|6|7|8):/)
          case 1: // monday - wednesday during reduced COVID hours
          case 2:
          case 3:
            return false
          case 6:
          case 4:
            // no late hours thurs/friday during reduced hours
          case 5:
            return !time.match(/^(5|6|7|8):/)
          default:
            // handles undefined, when a day hasn't been chosen yet
            return false
        }
      })
      // .slice(1) // remove the first <hour:00> entry - entrance starts at :10
      .slice(0, -1) // remove the last two :40 and :50 - entrance stops at :30

    return requireTimedTicket(...times)(product, cart, user)
  }
}

const requiresCompanionProductWithComparison = (comparison, errorMessage) => {
  // requiresCompanionProductWithComparison
  return (product, cart, user) => {
    // bind the product into the comparison
    const comparisonWithBoundProduct = comparison.bind(this, product)
    // const companion = cart && cart.products.find(comparisonWithBoundProduct)
    const cartProducts = cart ? cart.products : []
    const dependents = cartProducts.filter(p => p.data.id === product.data.id)
    const companions = cartProducts.filter(comparisonWithBoundProduct)

    // do the products match?
    // …There can't be 3 My Mia tours with just 2 exhibition tickets
    const hasCompanions =
      dependents.length > 0 && companions.length >= dependents.length

    // we can't do anything if we don't have something to do?
    if (dependents.length === 0 && companions.length === 0)
      return { ...product.data, valid: true }

    const { errors } = product.data
    return hasCompanions
      ? {
          ...product.data,
          errors: R.omit(['requiresCompanion'], errors),
          valid: true,
          companion: companions[0],
          selected_date:
            product.data.selected_date || companions[0].data.selected_date,
          // TODO this (^) is a kludge, find the real fix
          // somehow My Mia Tours are losing their `selected_date` when added to cart?
        }
      : {
          ...product.data,
          valid: false,
          errors: {
            ...errors,
            requiresCompanion: errorMessage || 'Must have a matching product',
          },
          companion: false,
        }
  }
}

const requiresCompanionProduct = companionProduct => {
  // requiresCompanionProduct
  return (product, cart, user) => {
    const companion =
      cart &&
      cart.products.find(p => companionProduct.data.name === p.data.name)
    if (cart && companion) {
      return {
        ...R.omit(['errorMessage'], product.data),
        valid: true,
        companion,
      }
    } else {
      return {
        ...product.data,
        valid: false,
        errorMessage: 'must also purchase an eyewitness views ticket',
        companion: false,
      }
    }
  }
}

function myMiaDays(...datesWithOptionalTrailingArgs) {
  const hasArgs = R.type(R.last(datesWithOptionalTrailingArgs)) === 'Object'
  const dates = hasArgs ? R.init(datesWithOptionalTrailingArgs) : datesWithOptionalTrailingArgs
  const args = hasArgs ? R.last(datesWithOptionalTrailingArgs) : {}
  const atLevel = args.atLevel || 0

  return function(product, cart, user) {
    const defaultYear = new Date(product.data.start_date_time).getFullYear()
    // TODO what if an exhibition wraps around a new year? This logic will break. Dates are hard
    const dateObjects = dates.map(dateString => {
      const dateParts = dateString.split('/')
      const hasYear = dateParts.length > 2
      const year = hasYear ? Number(dateParts[2]) : defaultYear

      return new Date(hasYear ? dateString : `${year}/${dateString}`)
    })

    const { selected_date } = product.data
    const isMyMia = dateObjects.find(d =>
      moment(d).isSame(moment(selected_date), 'day')
    )

    // Name the discount differently on Thursday from other My Mia days
    // Third Thursday discounts are only applied after 5pm
    let ruleName =
      isMyMia && isMyMia.getDay() === 11 ? 'myMiaThursday' : 'myMiaDays'
    if(atLevel > 0) {
      // TODO this is not elegant. How to link two discounts to one numeric limit?
      // Contributor week is for all members giving > $1
      // Investor+ is for all Investor and above members.
      // But during contributor week, investors should still only get 2 tickets, so
      // the contributor week incantation of this discount shouldn't apply…
      //
      // This accomplishes the thing by not adding the contributor week rule if 
      // a membership exceeds the $150 level
      const membership = _containsMembership(cart, atLevel || 0)
      const memberTotal = membership ? membership.current_giving_totals >= 150 || membership.price : 0
      if(membership && memberTotal >= 150) {
        console.info('myMiaDays atLevel', membership, memberTotal)
        return { ...product.data, discounts: R.omit([ruleName], product.data.discounts) }
      }

      ruleName = 'Contributor Week'
    }

    // prettier-ignore
    return !!isMyMia
      ?  upToTwoFreeWithMembership({ atLevel, ruleName })(product, cart, user)
      : { ...product.data, discounts: R.omit([ruleName], product.data.discounts) }
  }
}

function maxPerCart(maximum) {
  return (product, cart, user) => {
    if (!cart) return product.data

    const { name: productName, id: productId, errors } = product.data

    const matchingProducts = cart.products.filter(
      p => productId === p.data.id && productName === p.data.name
    )

    if (matchingProducts.length > maximum) {
      return {
        ...product.data,
        errors: { maxPerCart: `This has a maximum of ${maximum} per person` },
        valid: false,
      }
    } else {
      return {
        ...product.data,
        valid: true,
        errors: R.omit(['maxPerCart'], errors),
      }
    }
  }
}

const museumClosed = closedPeriods => product => {
  const { selected_date } = product.data
  const invalidDate = closedPeriods.find(([start, end, reason]) => {
    // TODO this only handles full days -
    // adapt to consider both date and time so closing i.e. for an afternoon works
    return moment(selected_date).isBetween(start, end, 'day', '[]')
  })

  const { errors } = product.data

  if (invalidDate) {
    const [closedReason] = invalidDate
    return {
      ...product.data,
      errors: { ...errors, museumClosed: closedReason },
    }
  } else {
    return { ...product.data, errors: R.omit(['museumClosed'], errors) }
  }
}

const pctDiscountWAffinity = (discountRate, affinity) => (
  product,
  cart,
  user
) => {
  // pctDiscountWAffinity
  const { price, discounts } = product.data
  const hasMembership = _containsMembership(cart)
  const memberships =
    hasMembership && cart && cart.user && cart.user.memberships
  const membershipWithAffinity =
    memberships &&
    memberships.find(
      memb => memb.affinities && memb.affinities.indexOf(affinity) > -1
    )

  const hasAffinity = !!membershipWithAffinity
  const isPatronCircleLevel =
    memberships && !!memberships.find(m => m.member_level === "Patrons' Circle")

  // BUG! when a user has two affinities, this rule applies twice. If the first time
  // it matched successfully, the second time it will not, and it will revert the discount?
  const ruleName = `${affinity} Affinity`

  const next =
    hasAffinity || isPatronCircleLevel
      ? {
          discounts: {
            ...R.omit('percentageDiscountWithMembership', discounts),
            [ruleName]: price * discountRate / 100,
          },
          annotation: 'affinity discount applied',
        }
      : {
          discounts: R.omit(['pctDiscountWAffinity'], discounts),
          annotation: 'affinity discount removed',
        }

  return {
    ...product.data,
    ...next,
  }
}

const promo = (product, cart, user) => {
  // promo
  const {
    promos,
    selected_promo,
    selected_promo_code,
    price,
    discounts,
    start_date_time,
    end_date_time,
    originalStartDate,
    originalEndDate,
    errors,
  } = product.data

  const promo = promos && promos.find(p =>
    p.promo_slug === selected_promo
    || selected_promo_code && p.promo_code.toLowerCase() === selected_promo_code.toLowerCase()
  )
  const { promo_code, max_per_order, min_per_order } = promo || {}
  const promoValidated =
    promo &&
    promo_code &&
    selected_promo_code &&
    (promo_code.toLowerCase() === selected_promo_code.toLowerCase() ||
      selected_promo_code.toLowerCase() === 'MiaPartner1017'.toLowerCase() ||
      selected_promo_code.toLowerCase() === '1117')

  const matchingProducts = cart
    ? cart.products.filter(p => {
        const selectedCode = p.data.selected_promo_code
        return (
          selectedCode &&
          promo_code &&
          selectedCode.toLowerCase() === promo_code.toLowerCase()
        )
      })
    : []

  const promoOverCapacity = cart && matchingProducts.length > max_per_order
  const promoUnderCapacity = cart && matchingProducts.length < min_per_order
  const promoInvalidCapacity = promoOverCapacity || promoUnderCapacity
  const capacityError =
    (promoOverCapacity && {
      promoMaxQty: `Only ${max_per_order} '${
        promo.promo_name
      }' reservations allowed.`,
    }) ||
    (promoUnderCapacity && {
      promoMinQty: `At least ${min_per_order} reservations required for '${
        promo.promo_name
      }'`,
    }) ||
    {}

  const promoRequiredData = R.pickBy(
    (val, key) => key.match(/^require_/),
    promo
  )

  // experiment with different handling of promos:
  // instead of putting an error and blocking progression,
  // only apply the promo to as many quantity as it's valid
  // for and let the rest go into the cart at full price?
  // upToTwoFree works this way…
  if (true) {
    const alreadyDiscountedMatchingProducts = matchingProducts
      ? matchingProducts.filter(
          p => p.data.discounts && (p.data.discounts['promo'] || p.data.discounts[promo.promo_name])
        )
      : [product]

    let nextData

    if (promo && promoValidated) {
      if (alreadyDiscountedMatchingProducts.length <= max_per_order) {
        // all is good, promo correctly applied
        // console.info('promo should be applied')
        nextData = {
          discounts: {
            ...discounts,
            [promo.promo_name]: price * promo.price_override / 100,
          },
          originalStartDate: product.data.start_date_time,
          originalEndDate: product.data.end_date_time,
          start_date_time: promo.start_date,
          end_date_time: promo.end_date,
          validDaysOfWeek: promo.validDaysOfWeek,
          promo: promo,
          ...promoRequiredData,
        }
      } else {
        // too many or too few promos? remove the promo from some products
        nextData = {
          discounts: R.omit(['promo', promo.promo_name], discounts),
          start_date_time: originalStartDate || start_date_time,
          end_date_time: originalEndDate || end_date_time,
          promo: undefined,
          selected_promo: undefined,
          selected_promo_code: undefined,
          ...promoRequiredData,
        }
      }
    } else {
      // no promo, just return the same data
      nextData = {}
    }

    if (false) {
      console.info('promo', {
        alreadyDiscountedMatchingProducts,
        admpLength: alreadyDiscountedMatchingProducts.length,
        max_per_order,
        comparison: alreadyDiscountedMatchingProducts.length <= max_per_order,
        nextData,
      })
    }

    return {
      ...product.data,
      ...nextData,
    }
  } else {
    return {
      ...product.data,
      ...(promo && promoValidated
        ? {
            discounts: {
              ...discounts,
              promo: price * promo.price_override / 100,
            },
            originalStartDate: product.data.start_date_time,
            originalEndDate: product.data.end_date_time,
            start_date_time: promo.start_date,
            end_date_time: promo.end_date,
            validDaysOfWeek: promo.validDaysOfWeek,
            promo: promo,
            errors: promoInvalidCapacity
              ? {
                  ...capacityError,
                  ...errors,
                }
              : R.omit(['promoMaxQty', 'promoMinQty'], errors),
            ...promoRequiredData,
          }
        : {
            discounts: R.omit(['promo'], discounts),
            start_date_time: originalStartDate || start_date_time,
            end_date_time: originalEndDate || end_date_time,
            promo: undefined,
            ...promoRequiredData,
          }),
    }
  }
}
//
// TODO generalize this when we need another companion that modifies
// the parent
// TODO 2019-02-26 G G G generalize this?
// TODO 2020-02-27 when you remember that last year you hardcoded all
// the data, time to fix that…
const vipCompanionChangesPrice = (product, cart, user) => {
  // vipCompanionChangesPrice
  if (!cart) return { ...product.data }

  const parentRegs = cart.products.filter(
    p => p.data.id === 'AIBSPEV20180427-001' || p.data.id === 'AIBSPEV20190412-001' || p.data.id ===  "AIBSPEV20200424-001"
  )
  const vipRegs = cart.products.filter(
    p => p.data.id === 'VIP-AIBSPEV20180427-001' || p.data.id === 'VIP-AIBSPEV20190412-001' || p.data.id === 'VIP-AIBSPEV20200424-001'
  )

  const companion = product.data.companionProducts && product.data.companionProducts[0]
  const startingPrice = 85 // product.data.price
  const vipPrice = companion ? companion.data.companion_price_change/100 : 175

  parentRegs.forEach((parent, index) => {
    // for the number of VIP Regs, apply the VIP price to that many
    // parent products
    const incrementPriceForVIP = index + 1 <= vipRegs.length

    parent.data.price = incrementPriceForVIP ? vipPrice : startingPrice
    parent.data.overrideSurgePricing = true
  })

  const nextPrice =
    parentRegs.length > 0 && vipRegs.length === 0 ? startingPrice : product.data.price

  console.info('vipCompanionChangesPrice', {startingPrice, vipPrice, vipRegs, nextPrice})
  // debugger

  return { ...product.data, price: nextPrice }
}

// no limit on free for investor+ Youth tix
const youthInvestorFree = (product, cart) => {
  // youthInvestorFree
  if (_containsMembership(cart) && product.data.ticket_type === 'Youth') {
    const discounts = {
      ...product.data.discounts,
      Youth: product.data.price,
    }
    return {
      ...product.data,
      discounts,
    }
  } else {
    return product.data
  }
}

function membersOnlyWhenMemberHold(product, cart) {
  if (product.data.useBucket === 'member_hold')
    return membersOnly(
      false,
      `Must be or sign-up for My Mia to access 'member hold' Egypt tickets.`
    )(product, cart)

  return product.data
}

const myMiaDaysAfter5pm = (...dates) => (product, cart) => {
  // myMiaDaysAfter5pm
  const time = product && product.data.selected_time
  const hours = time && Number(time.split(':')[0]) // TODO this is horrid, somehow re-convert "10:30 am" back to a definite time?
  const after5pm = time && hours >= 5 && hours < 9

  // If the selected time is after 5pm, check if this is a my mia day
  if (time && after5pm) {
    return myMiaDays(...dates)(product, cart)
  }

  // otherwise remove any applied myMia discount
  return {
    ...product.data,
    discounts: R.omit(['myMiaThursday'], product.data.discounts),
  }
}

const freeAffinityBasedOnMembership = (product, cart) => {
  // freeAffinityBasedOnMembership
  // Investor memberships get 1 free affinity
  // Above Investor gets 2
  // PC gets unlimited / all?
  const hasMembership = _containsMembership(cart, 0)
  const membership = hasMembership || cart && cart.user && cart.user.memberships.find(({member_type}) => member_type === 'Mia Membership')
  const memberGiving = !membership ? 0 : membership.current_giving_totals
  const affinities = membership && membership.affinities || []

  // TODO calculate this depending on member level and joined affinities
  const numberOfAvailableFreeAffinities = memberGiving < 150
    ? 0 // Contributing - 0 free affinities
    : memberGiving < 500
      ? 1 // Investor, 1 free
      : memberGiving < 2500
        ? 2 // Partner, 2 free
        : 11 // Patron's Circle et al unlimited

  const numFree = numberOfAvailableFreeAffinities - affinities.length

  if (DEBUG) console.info('freeAffinityBasedOnMembership', {
    membership,
    affinities,
    numberOfAvailableFreeAffinities,
    numFree,
  })

  return numFree > 0
    ? upToTwoFreeWithMembership({ atLevel: 150, numFree, })(product, cart)
    : product.data
}

/* raise the price by a given amount on a schedule
 *
 * requires `surge_prices: [{date: , surge: }, …]` in the product data
 */
const surgePricing = (product, cart) => {
  // surgePricing
  const {surge_pricing} = product.data
  const now = new Date()
  const applicable_surges = surge_pricing.filter(({date: dateString}) => {
    return new Date(dateString) <= now
  })

  if(applicable_surges && applicable_surges.length > 0) {
    // of all the applicible surges, choose the most recent. This relies on surges
    // being in order by date - is that a bad assumption, and should the surge
    // list first be sorted by date?
    const currentSurge = applicable_surges[applicable_surges.length-1]
    // don't sum all applicable surges - choose one
    // const combinedSurge = applicable_surges.reduce((amount, surge) => amount += surge.surge_amount, 0)
    const totalSurge = currentSurge.surge_amount
    const origPrice = product.data._originalPrice || product.data.price
    const surgedPrice = origPrice + totalSurge/100

    false && console.info('surgePricing', {
      origPrice, surgedPrice, price: product.data.price,
      overrideSurgePricing: product.data.overrideSurgePricing
    })
    // debugger

    return {
      ...product.data,
      _originalPrice: origPrice,
      price: product.data.overrideSurgePricing ? product.data.price : surgedPrice,
    }
  } else {
    return {
      ...product.data,
      price: product.data._originalPrice || product.data.price,
    }
  }
}

const clearDiscounts = function(product) {
  const { discounts, clearDiscounts } = product.data
  if (clearDiscounts) {
    product.data.discounts = R.omit(Array.from(clearDiscounts), discounts)
  }

  return product.data
}

export {
  fiveDollarsOff,
  upToTwoFree,
  upToTwoFreeWithMembership,
  addArbitraryInformationRule,
  fixedReducedPriceWithMembership,
  buyOneGetOneRule,
  flexiblePricing,
  scheduleDaily,
  friendsMembersOnly,
  assignLevelBasedOnPricePaid,
  getMembershipLevelFromPrice,
  scheduleRepeat__secondFullWeekend,
  scheduleRepeat__eachWeekend,
  requireData,
  halfPriceWithMembership,
  percentageDiscountWithMembership,
  scheduleOnce,
  restrictOnSaleDates,
  requireTimedTicket,
  requiresCompanionProduct,
  requiresCompanionProductWithComparison,
  membersOnly,
  affinityMembersOnly,
  memberPreviewDay,
  myMiaDays,
  maxPerCart,
  museumClosed,
  pctDiscountWAffinity,
  promo,
  timedTicketWithInterval,
  vipCompanionChangesPrice,
  youthInvestorFree,
  membersOnlyWhenMemberHold,
  myMiaDaysAfter5pm,
  surgePricing,
  freeAffinityBasedOnMembership,
  clearDiscounts,
}
