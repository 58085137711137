import axios from 'axios'

// const baseUrl = 'http://52.91.16.48/v1/'
// const baseUrl = '/v1/'
// const api = (method, path, data) => {
//   return axios({
//     method,
//     url: (baseUrl + path).replace('//', '/'),
//     data,
//   })
// }

export const login = (email, password) => {
  return axios.post(`/v1/user/login`, {
    email,
    password,
  })
}

export const reset = values => {
  const userData = {
    ...values,
  }
  return axios.put(`/v1/user/reset`, userData)
}

export const create = values => {
  const userData = {
    ...values,
  }

  // return api('put', `/user/create`, userData)
  return axios.put('/v1/user/create', userData)
}

export const confirm = (email, token) => {
  const _email = encodeURIComponent(email.replace(/ /g, '+'))
  const auth_token = encodeURIComponent(token)

  return axios.get(
    `/v1/user/confirm?email_address=${_email}&auth_token=${auth_token}`
  )
}

export const exist = email => {
  return axios.get(`/v1/user/exist?email_address=${email}`)
}

export const createAcct = values => {
  return values.reset
    ? axios.put('/v1/user/reset/confirm', values)
    : axios.post('/v1/user/create', values)
}

export const updateInfo = values => {
  const userData = {
    ...values,
  }

  // return api('put', `/user/create`, userData)
  return axios.put('/v1/user/save', userData)
}

export const verify_bank = (amount1, amount2, token, email) => {
  const payments = [parseFloat(amount1), parseFloat(amount2)]

  return axios.post(`/v1/stripe/verify`, {
    token,
    email_address: email,
    payments,
  })
}
