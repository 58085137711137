/* eslint-disable */
// TODO actually write this
import React from 'react'
import R from 'ramda'

import { getCapacity } from '../../util/capacity'

export default class Capacity extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
    return <p>Capacity logix here and pass down props or do a render component or something</p>
  }
}
