import React from 'react'
import axios from 'axios'

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = { json: false }
  }

  componentWillMount() {
    this.loadExternalContent()
  }

  loadExternalContent() {
    // Method for getting data from the provided end point url

    var id = 3867

    var endPoint = 'https://new.artsmia.org/wp-json/wp-api-menus/v2/menus/' + id

    return axios
      .get(endPoint)
      .then(response => {
        this.setState({ loaded: true, json: response.data.items, response })
      })
      .catch(error => {
        Promise.reject(error)
      })
  }
  render() {
    const { json } = this.state

    const footerNavLinks =
      json &&
      json.map(footerNavItem =>
        <li key={footerNavItem.id}>
          <a className="link-fade-in-brdr uppercase" href={footerNavItem.url.replace('staging.', 'new.')}>
            <small>{footerNavItem.title}</small>
          </a>
        </li>
      )

    const isAppleDevice = navigator.userAgent.match('Mac OS')
    const mapLink = isAppleDevice
      ? `http://maps.apple.com/?daddr=Minneapolis Institute of Arts`
      : `https://goo.gl/maps/UvpKrANMr5UH3xXY6`

    return (
      <div className="footer">
        <footer>
          <div className="footer-content border-top-3">
            <div className="row thirds">
              <div className="col col-3">
                <ul className="list-style-none list-padding-none">
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={mapLink}
                      className="link-fade-in-brdr"
                    >
                      2400 Third Avenue South
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={mapLink}
                      className="link-fade-in-brdr"
                    >
                      Minneapolis, Minnesota 55404
                    </a>
                  </li>
                  <li>
                    <a href="tel:1-888-642-2787" className="link-fade-in-brdr">
                      888 642 2787 (Toll Free)
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col col-3">
              </div>
              <div className="col col-3">
                <ul className="list-inline">
                  {footerNavLinks}
                </ul>
                <ul className="list-inline">
                  <li>
                    <a
                      className="link-fade-out icon-miafacebook"
                      href="http://www.facebook.com/artsmia"
                      aria-hidden="true"
                      alt="facebook"
                    >
                      <span className="sr-only">facebook</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="link-fade-out icon-miaflickr"
                      href="http://www.flickr.com/photos/minneapolisinstituteofarts/"
                      aria-hidden="true"
                      alt="flickr"
                    >
                      <span className="sr-only">flickr</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="link-fade-out icon-miainstagram"
                      href="http://instagram.com/artsmia"
                      aria-hidden="true"
                      alt="instagram"
                    >
                      <span className="sr-only">instagram</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="link-fade-out icon-miatwitter"
                      href="http://twitter.com/artsmia"
                      aria-hidden="true"
                      alt="twitter"
                    >
                      <span className="sr-only">twitter</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="link-fade-out icon-miayoutube"
                      href="http://www.youtube.com/user/artsmia"
                      aria-hidden="true"
                      alt="youtube"
                    >
                      <span className="sr-only">youtube</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="link-fade-out icon-miatumblr"
                      href="http://artsmia.tumblr.com/"
                      aria-hidden="true"
                      alt="tumblr"
                    >
                      <span className="sr-only">tumblr</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="link-fade-out icon-miavimeo"
                      href="https://vimeo.com/artsmia/"
                      aria-hidden="true"
                      alt="vimeo"
                    >
                      <span className="sr-only">vimeo</span>
                    </a>
                  </li>
                  <li>
                    <a
                      className="link-fade-out icon-miacontact"
                      href="https://new.artsmia.org/contact-us/e-mail-newsletters/"
                      aria-hidden="true"
                      alt="email Mia"
                    >
                      <span className="sr-only">email Mia</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}

export default Footer
