import React from 'react'
import { connect } from 'react-redux'
import StripeTerminal from './stripe-terminal'

/* Save cart and account info to localStorage.
 *
 * Because of redux this fn-al component only runs when either of the mapped
 * state items changes, so on login/logout and when items in the cart are
 * manipulated.
 */
const CartSaver = props => {
  const {cart, account} = props
  const {isVE} = account || {}

  if(cart) {
    try {
      // TODO extract this to cart.toJson?
      const jsonCart = JSON.stringify(cart.map(p => {
        // get rid of data.parent and history (which contains parent)
        const p1 = p.clone().withData({
          parent: null,
          companion: null,
          companionProducts: null
        })
        p1.history = []
        p1.derivatives = []
        return p1
      }))

      window.localStorage.setItem('cart', jsonCart)
    } catch(e) {
      debugger
    }
  }

  if(!isVE) {
    window.localStorage.setItem('account', JSON.stringify(account))
    console.info('cartSaver', cart)
  }

  return !isVE
    ? null
    : <StripeTerminal
        justUpdateTerminalDisplay={true}
        cart={cart}
        account={account}
      />
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  cart: state.products.cart,
  account: state.account,
})

export default connect(mapStateToProps)(CartSaver)
