/* @format
 */

import React from 'react'

export default () => 
  <style>{`
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
          -webkit-transition-delay: 99999s;
          -webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
    }
  `}</style>
