import products from '../data/productsJson.js'

export const adjustDataForPrint = (orderData, location) => {
  const lineItemsWithPrintData = orderData.line_items.map(item => {
    const product = products.find(prod => prod.data.id === item.item.item_id)

    // Egypt adult tickets should read 'General Admission'
    if(
      product &&
      product.data.id === 'EX2019-001' &&
      item.line_item_attributes.ticket_type === 'Adult'
    ) item.line_item_attributes.ticket_type = 'General Admission'
    
    if(product && product.data.id === 'EX2019-001-ANYTIME') {
      // Don't print just the `start_date_time` on an anytime ticket,
      // it's confusing.
      item.item.product_attributes.start_date_time = false
    }

    // Only print print-able products, and don't let refunded items print
    const shouldPrint = shouldPrintProduct(product) && item.status !== 'refunded'

    return {
      ...item,
      print: shouldPrint,
    }
  })

  return {
    ...orderData,
    print: {
      ticket: true,
      receipt: orderData.point_of_sale !== 'phone',
    },
    line_items: lineItemsWithPrintData,
  }
}

// ticket should print for item by default, not if `print_ticket` is false
// and not if it's a membership or donation
export const shouldPrintProduct = product => {
  if (!product) return false

  const print_ticket = product ? product.data.print_ticket : true

  return product && typeof print_ticket === 'boolean'
    ? print_ticket
    : ['AFFINITY', 'MEMBERSHIP', 'DONATE'].indexOf(product.id) < 0
}

/* TODO →
 *
 * Default - no printing in phone room
 * VE needs a print/reprint ticket button
 * when a ticket is printed in the phone room, generate a 'ticket ID' that can be barcoded
 *
 * print cash drawer info when VE user closes out
 *   starting amount with a place for them to write in final cash?
 */
