import React from 'react'
import { Link } from 'react-router-dom'

/*
 * This is a bit clever - if we are on a secondary hive page, the header Mia
 * logo links to the hive homepage at `/`
 *
 * When already on the homepage, it links back to `new.artsmia.org` instead.
 */
export default class HeaderTitle extends React.Component {
  render() {
    const img = <img className="header-logo" src="/mia-wordmark.svg" alt="Mia Home" />
    const link = window.location.pathname === '/'
      ? <a href="https://artsmia.org/">{img}</a>
      : <Link to={`/`}>{img}</Link>

    return <div>{link}</div>
  }
}
