import React from 'react'
import axios from 'axios'

export default class PrimaryNavData extends React.Component {
  constructor(props) {
    super(props)
    this.state = { json: false }
  }

  componentWillMount() {
    this.loadExternalContent()
  }

  loadExternalContent() {
    // Method for getting data from the provided end point url

    // This is a bit confusing - here we are on the tickets site and have two choices for primary nav:
    // 1. #3872, "Primary Nav", Tickets / Calendar / Donate
    //    this is what's primary on new.artsmia.org.
    //    But we're already on tickets, so does linking it in the nav make sense?
    //    https://staging.artsmia.org/wp-admin/nav-menus.php?action=edit&menu=3872
    // 2. #3873, "Quick Nav", Calendar / Donate / Keep in Touch
    //    For now use this because it doesn't double up on 'tickets'
    //    https://staging.artsmia.org/wp-admin/nav-menus.php?action=edit&menu=3873
    var id = 3873

    var endPoint = 'https://new.artsmia.org/wp-json/wp-api-menus/v2/menus/' + id

    return axios
      .get(endPoint)
      .then(response => {
        const json = response.data.items.map(item => {
          if(item.title === 'Donate') item.url = '/products/donate'
          return item
        })
        this.setState({ loaded: true, json, response })
      })
      .catch(error => {
        Promise.reject(error)
      })
  }

  render() {
    const { json } = this.state

    const navInfo =
      json &&
      json.map(navInfoItem =>
        <li key={navInfoItem.id}>
          <a href={navInfoItem.url.replace('staging.', 'new.').replace('live-artsmia.pantheonsite.io', 'new.artsmia.org')}>
            {navInfoItem.title}
          </a>
        </li>
      )
    return (
      <div className="display-inline-block">
        {navInfo}
      </div>
    )
  }
}
