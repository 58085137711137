import { push } from 'react-router-redux'
// import ProductModel from '../core/models/product'
import CartModel from '../core/models/cart'
import products from '../data/productsJson.js'

export const ADD_PRODUCT_TO_CART_REQUESTED =
  'products/ADD_PRODUCT_TO_CART_REQUESTED'
export const ADD_PRODUCT_TO_CART = 'products/ADD_PRODUCT_TO_CART'
export const REMOVE_PRODUCT_FROM_CART = 'products/REMOVE_PRODUCT_FROM_CART'
export const MODIFY_PRODUCT_DATA = 'products/MODIFY_DATA'
export const TOGGLE_CONFETTI = 'products/TOGGLE_CONFETTI'
export const EMPTY_CART = 'products/EMPTY_CART'
export const PRIME_DATA_LAYER = 'products/PRIME_DATA_LAYER'
export const COMPLETE_CHECKOUT_ORDER = 'products/COMPLETE_CHECKOUT_ORDER'
export const NOTIFY_SF_ERROR = 'products/NOTIFY_SF_ERROR'
export const CLEAR_SF_ERROR = 'products/CLEAR_SF_ERROR'
export const ADD_RESERVATION_INFO = 'products/ADD_RESERVATION_INFO'

const localCart = window.localStorage.getItem('cart')
const localAccount = window.localStorage.getItem('account')
let rehydratedCart = []
if(localCart) {
  const localUser = localAccount ? JSON.parse(localAccount).user : undefined
  // TODO extract to models/cart#fromJson?
  const cart = new CartModel([], localUser)
  rehydratedCart = JSON.parse(localCart)
    .map(({data}, index) => {
      const p = products.find(p => p.id === data.id).clone().withData({
        ...data,
        // serializing to json converts date into a string, JSON.parse
        // doesn't change it back into a date. Do so manually.
        // Watch out for similar problems?
        ...(data.selected_date ? {selected_date: new Date(data.selected_date)} : {}),
      })

      // IDEA 1 - limit exhibitions to a maximum of 2 in the cart when rehydrating.
      // Not that this is a good idea, but if the problem is that the discount wavers
      // for exhibitions when there are 3 or more, this could be an easy fix, even if stupid
      if(p.data.product_type === 'exhibition') {
        // TODO allow separate product types (Member, Adult, Youth?) to coexist in the cart without truncating?
        const numberSameProduct = cart.products.filter(({data}, index) => {
          console.info('rehyd determining number same', [[p.id, p.data.ticket_type], [data.id, data.ticket_type]], {index})
          // if(index > 0) debugger
          return p.id === data.id // && p.data.ticket_type === data.ticket_type
        }).length

        console.info('rehyd', {numberSameProduct})

        if(numberSameProduct < 2) {
          cart.products.push(p)
        }
      } else {
        cart.products.push(p)
      }

      // computedTotal calls applyRules on all cart products
      // and because we are adding those products one at a time,
      // it should work just like if we added them manually?
      cart.computedTotal()

      return p
    })

  const productsWDiscount1 = cart.products.map(p => p.data.discounts)
  const total = cart.computedTotal()
  const productsWDiscount2 = cart.products.map(p => p.data.discounts)
  const total2 = cart.computedTotal()

  rehydratedCart = cart.products
  console.info('cart rehydrated', {
    total,
    total2,
    productsWDiscount1,
    productsWDiscount2,
    cart,
    rehydratedCart
  })
}

const initialState = {
  products: [],
  cart: rehydratedCart,
  confetti: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART_REQUESTED:
      return {
        ...state,
        isAddingProductToCart: true,
      }
    case ADD_PRODUCT_TO_CART:
      return {
        ...state,
        cart: [...state.cart, action.product],
        isAddingProductToCart: false,
      }
    case REMOVE_PRODUCT_FROM_CART:
      const removeProductIndex = state.cart.indexOf(action.product)
      // if the last product has been removed, clear reservation info
      const nextReservationInfo = state.cart.length === 1
        ? undefined
        : state.reservationInfo
      return {
        ...state,
        cart: [
          ...state.cart.slice(0, removeProductIndex),
          ...state.cart.slice(removeProductIndex + 1),
        ],
        reservationInfo: nextReservationInfo,
      }
    case MODIFY_PRODUCT_DATA:
      const { products, data } = action
      // return state
      return {
        ...state,
        cart: state.cart.map(
          product =>
            products.indexOf(product) > -1
              ? product.clone().withData(data)
              : product
        ),
      }
    case TOGGLE_CONFETTI:
      return {
        ...state,
        confetti: !state.confetti,
      }
    case EMPTY_CART:
      return {
        ...state,
        cart: [],
      }
    case PRIME_DATA_LAYER:
      return {
        ...state,
        pushToDataLayer: true,
      }
    case CLEAR_SF_ERROR:
      return {
        ...state,
        error: undefined,
      }
    case COMPLETE_CHECKOUT_ORDER:
    case ADD_RESERVATION_INFO:
      return {
        ...state,
        reservationInfo: action.info,
      }
    case CLEAR_RESERVATION_INFO:
      // TODO also clear any products with a queue_id matching the previous
      // queue ID from the cart?>!
      return {
        ...state,
        reservationInfo: undefined,
      }
    default:
      return state
  }
}

export const addToCart = product => {
  return dispatch => {
    dispatch({ type: ADD_PRODUCT_TO_CART_REQUESTED })
    dispatch({ type: ADD_PRODUCT_TO_CART, product })
  }
}

export const removeFromCart = product => {
  return dispatch => {
    dispatch({ type: REMOVE_PRODUCT_FROM_CART, product })
  }
}

export const modifyProductData = (products, data) => {
  return dispatch => dispatch({ type: MODIFY_PRODUCT_DATA, products, data })
}

export const toggleConfetti = () => {
  return dispatch => dispatch({ type: TOGGLE_CONFETTI })
}

export const beginCheckout = () => {
  return dispatch => {
    dispatch(push('/checkout'))
    // TODO POST this cart to API as a sign of this transaction nearing completion
    // once cart is persisted, update URL to `/checkout/<cartId>`??
    // or set cart ID in localStorage?
  }
}

export const emptyCart = () => {
  return dispatch => {
    dispatch({ type: EMPTY_CART })
    dispatch({type: CLEAR_RESERVATION_INFO})
  }
}

export function completeCheckout(transactionId, apiResponse, apiRequest) {
  return dispatch => {
    dispatch({ type: PRIME_DATA_LAYER })
    dispatch(push(`/order/${transactionId}`))
    if (apiResponse.type === 'sf_error') {
      dispatch({ type: NOTIFY_SF_ERROR, message: apiResponse.error })
    } else {
      dispatch({ type: CLEAR_SF_ERROR })
    }
    dispatch({ type: EMPTY_CART })
    dispatch({type: CLEAR_RESERVATION_INFO})
    // show success message?
    // dispatch({ type: COMPLETE_CHECKOUT_ORDER })
  }
}

export const TRIGGER_CART = 'products/triggerCart'
export const triggerCart = () => dispatch => dispatch(push('/cart'))

export const TRIGGER_COMPANION_PAGE = 'products/triggerCompanionPage'
export const triggerCompanionPage = (page) =>
  dispatch => dispatch(push(page))

export const addReservationInfo = ({info}) => dispatch => {
  dispatch({type: ADD_RESERVATION_INFO, info})
}
export const CLEAR_RESERVATION_INFO = 'products/clearReservationInfo'
export const clearReservationInfo = (id) => dispatch => {
  // TODO completely emptying the cart is too agressive - only clear items in the cart matching the `id`?
  dispatch({ type: EMPTY_CART })
  dispatch({type: CLEAR_RESERVATION_INFO, id})
}
