class Cart {
  constructor(products, user) {
    this.products = products
    this.user = user

    this.products.map(prod => prod.applyRules(this, this.user))
  }

  computedTotal() {
    return this.products.reduce((sum, product) => {
      product.applyRules(this, this.user)
      const price = parseFloat(product.priceWithTax)

      return (sum += isNaN(price) ? 0 : price)
    }, 0)
  }

  validate() {
    this.computedTotal()

    return (
      this.products.length > 0 &&
      this.products.every(
        product => !!(product.data.valid && product.valid(this))
      )
    )
  }

  get errors() {
    return this.products.map(p => p.data.errors || p.data.errorMessage)
  }

  // Separate products that should be in separate order/cart/purchases.
  // At this point this is only for sustaining memberships.
  toMultipleCarts() {
    const sustainingMembership = this.products.filter(
      item => item.id === 'SUSTAINING_MEMBERSHIP'
    )
    const otherProducts = this.products.filter(
      item => item.id !== 'SUSTAINING_MEMBERSHIP'
    )
    const membershipStub = sustainingMembership[0]
      ? sustainingMembership[0].clone().withData({
          stubProduct: true,
        })
      : []

    const carts = [
      sustainingMembership.length > 0
        ? new Cart(sustainingMembership, this.user)
        : false,
      otherProducts.length > 0
        ? new Cart(otherProducts.concat(membershipStub), this.user)
        : false,
    ].filter(cart => cart)

    console.info('cart toMultipleCarts', {
      sustainingMembership,
      otherProducts,
      carts,
    })

    return carts
  }

  // TODO - does this belong in frontend as it's own module??
  // (yes probably)
  toLineItems(
    purchaser,
    stripeData,
    transactionLocation,
    paymentType,
    account
  ) {
    const lineItems = this.products.filter(p => !p.data.stubProduct).map(p => {
      return p.toLineItem(
        purchaser && purchaser.operator_detail,
        transactionLocation
      )
    })

    if (lineItems.every(li => li.item.product_id === 'SUSTAINING_MEMBERSHIP')) {
      paymentType = lineItems[0].line_item_attributes.paymentMethod
    }

    const totalAmount = this.computedTotal() * 1000
    const fees = 0 // with stripe interchange plus, fees are not a simple calculation - handle in API
    const netAmount = totalAmount - fees

    // TODO pass stripe token response through and fill in card type / last 4 digits
    const card = stripeData && (stripeData.card || stripeData.payment_method_details.card_present)

    const cardData =
      paymentType === 'credit_card' || paymentType === 'cc_sustainer' || paymentType === 'payment_intent'
        ? {
            fees: fees / 1000,
            card_type: card.brand,
            last4Digits: card.last4,
          }
        : {}

    const firstPayment = {
      payment_type: paymentType,
      payment_status: 'UNDEFINED',
      payment_amount: totalAmount / 1000,
      net_amount: netAmount / 1000,
      ...cardData,
    }

    const operator_detail = purchaser.operator_detail
      ? {
          ...purchaser.operator_detail,
          salespoint: transactionLocation,
          location: account && account.operator && account.operator.location,
          operator_name:
            account && account.operator && account.operator.operator_name,
        }
      : {}

    // const membershipInCart = this.products.find(p => p.data.id === 'MEMBERSHIP')
    // const primaryContact =
    //   membershipInCart && membershipInCart.data.primary_contact

    // const purchaserMergedWithMembershipPrimary = membershipInCart
    //   ? {
    //       ...purchaser,
    //       first_name: primaryContact.first_name,
    //       last_name: primaryContact.last_name,
    //       phone: primaryContact.phone,
    //       email_address: primaryContact.email_address,
    //       addresses: [
    //         ...(purchaser.addresses || []),
    //         {
    //           city: primaryContact.city,
    //           country: null,
    //           postal_code: primaryContact.postal_code,
    //           state: primaryContact.address_state,
    //           street_address: [
    //             primaryContact.street_address_1,
    //             primaryContact.street_address_2,
    //           ].join(' '),
    //           type: 'Home',
    //         },
    //       ],
    //     }
    //   : purchaser
    //
    const total_tax = lineItems.reduce(
      (accumTax, item) => accumTax + item.tax,
      0
    )
    const tax_liability = totalAmount / 1000 - total_tax
    return {
      total_tax,
      tax_liability,
      subtotal: tax_liability,
      total_paid: totalAmount / 1000,
      total_net_payments: netAmount / 1000,
      total_line_item: totalAmount / 1000,
      total_fees: fees / 1000,
      total_due: totalAmount / 1000,
      total_discount: 0.0,
      timestamp: new Date().toISOString(),
      purchaser: {
        ...purchaser,
        payment_token: stripeData && stripeData.id,
      },
      operator_detail,
      payments: [firstPayment],
      line_items: lineItems,
      point_of_sale: transactionLocation,
    }
  }
}

export default Cart
