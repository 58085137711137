import React from 'react'
import { Link } from 'react-router-dom'

import ReasonableWPImage from './reasonable-wp-image'
import DateDisplay from './date-display'

const ReactMarkdown = require('react-markdown')

export default props => {
  const { data } = props.product
  const { image, slug } = data
  const summary = data.summary ? (
    <ReactMarkdown source={data.summary} />
  ) : (
    <span />
  )

  const isMembershipy =
    "mia_membership donation donate LOBBY_DONATION friends_membership"
      .split(' ')
      .indexOf(data.product_type) > -1
    && !data.id.match("AIB")
  const isExhibition = 'exhibition'.split(' ').indexOf(data.product_type) > -1
    && data.id !== "EX2019-001-ANYTIME"

  const showTopHeader = props.showTopHeader && (isMembershipy || isExhibition)

  return (
    <div className={`col ${props.compact ? 'col-2' : 'col-3'} cell-container`}>
      <Link
        to={{
          pathname: `/products/${slug}`,
          state: {
            fromGroup: props.stepBack,
          },
        }}
      >
        <div className="small-article" style={{ cursor: 'pointer' }}>
          {showTopHeader && (
            <h2 className="border-top-3">{data.short_title || data.title}</h2>
          )}
          {image && <ReasonableWPImage src={image} />}

          {(showTopHeader && isMembershipy) || (
            <div>
              {showTopHeader || <h3>{data.title}</h3>}{' '}
              {isMembershipy || <DateDisplay product={props.product} />}
            </div>
          )}
          {props.compact ? <span /> : <div>{summary}</div>}
        </div>
      </Link>
    </div>
  )
}
