import React from 'react'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import R from 'ramda'
import moment from 'moment'
import { IconButton, FontIcon } from 'material-ui'

import {
  addToCart,
  removeFromCart,
  modifyProductData,
  beginCheckout,
  emptyCart,
} from '../../modules/products'
import CartSummary from '../../CartSummary'
import CartModel from '../../core/models/cart'
import Contact from '../../ContactSummary'
import {ReservationInfo} from '../../components/capacity'
import Deprecated from '../../components/DeprecatedRedirect'

import products from '../../data/productsJson.js'
import { HeroBox } from '../../components/template/Catalogue'

const Cart = props => {
  const { cart, confetti, ...childProps } = props

  return (
    <Deprecated><div>
      <CartSummary />
      <Contact />
      {cart.length > 0 ? (
        <CartListingContainer {...childProps} products={cart} />
      ) : (
        <Link to="/">
          You don't have anything in your cart. Go find something!
        </Link>
      )}
      {props.hideControls || <aside>
        <Upsell cart={cart} />
      </aside>}
    </div></Deprecated>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  cart: state.products.cart,
  confetti: state.products.confetti,
  contact: state.account.contact || state.account.user,
  operator: state.account.operator,
  isVE: state.account && state.account.isVE,
  reservationInfo: state.products.reservationInfo,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addToCart,
      removeFromCart,
      modifyProductData,
      beginCheckout,
      emptyCart,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Cart)

const CartListing = props => {
  const { contact } = props
  const cart = contact
    ? new CartModel(props.products, contact)
    : new CartModel(props.products)
  const validCart = cart.validate()

  const lineItems = cart.products.map(p => p.toLineItem())
  const total1 = cart.computedTotal()
  const total2 = cart.computedTotal()
  const total3 = cart.computedTotal()
  console.info('cart/index CartListing', { cart, lineItems, total1, total2, total3 })

  const filterData = (filter, product) =>
    Object.keys(product.data)
      .filter(filter)
      .map(selectedData => product.data[selectedData])
      .map(d => typeof d === 'object' ? JSON.stringify(d) : d)
  const getSelectedData = product =>
    filterData(key => /^selected|provided/.test(key), product)

  const groupedProducts = R.groupBy(prod => {
    const { data } = prod
    const attributes = R.flatten([
      data.id,
      prod.displayName,
      prod.price,
      data.dataNeeded,
      getSelectedData(prod),
      data.valid,
      data.errorMessage,
      data.ticket_type,
      JSON.stringify(data.providedInformation)
    ])
    return attributes
  }, cart.products)

  const cellStyles = {
    padding: '0 20px',
    textAlign: 'left',
  }

  return (
    <table style={{ width: '100%' }}>
      <tbody>
        {' '}
        <tr>
          <th style={cellStyles}>Item Name</th>
          <th style={cellStyles}>Item Details</th>
          <th style={cellStyles}>Quantity</th>
          <th style={cellStyles}>Price</th>
        </tr>
        {R.toPairs(groupedProducts).map(([attributes, products], i) => {
          const quantity = products.length
          const displayName = products[0].displayName

          const basePrice = R.sum(
            products.map(p => p.data.price).filter(price => !isNaN(price))
          )
          const price = R.sum(
            products.map(p => p.priceWithTax).filter(price => !isNaN(price))
          )

          // prettier-ignore
          const priceExplained = basePrice === price
            ? <span>${price}</span>
            : <span>
                <del>${basePrice}</del> ${price}
              </span>

          const productsValid = products.every(p => p.valid(cart))

          const errorStyle = {
            backgroundColor: productsValid ? 'none' : 'rgba(255, 0, 0, 0.1)',
          }

          const lockQuantity =
            props.hideControls ||
            products[0].data.lock_quantity ||
            products[0].data.strictCapacity
          const totalLockQuantity = products[0].data.id === 'MATERIALS-FEE'

          console.info('cart/index ticket_type', products[0].data.ticket_type)

          const providedInformation = products[0].data.providedInformation
          const productAttrs = R.pick(
            [
              'location',
              products[0].data.type === 'timed' ? '' : 'start_date_time' // don't show start date for 'timed' products like exhibitions
            ],
            products[0].data
          )
          const lineItemInfo = selectDetailsFromData({
            ...filterDataObj(
              key =>
                (/^selected|discounts/i.test(key) &&
                key !== 'clearDiscounts' &&
                key !== 'ignoreDiscounts') || key.match(/beneficiary/i),
              products[0].data
            ),
            ...providedInformation,
            ...productAttrs,
          }, {item: products[0].data})

          // Change Egypt Adult tickets to 'General Admission'
          const ticketType = products[0].data.ticket_type
          const ticketTypeValue = products[0].data.id === 'EX2019-001' && ticketType === 'Adult' ? 'General Admission' : ticketType
          const ticketTypeString = ticketType ? `(${ticketTypeValue})` : ''

          return (
            <tr key={`${displayName}-${i}`}>
              <td style={cellStyles}>
                {displayName} {ticketTypeString}
              </td>
              <td style={cellStyles}>{lineItemInfo}</td>
              <td style={cellStyles}>
                {props.hideControls || (
                  <IconButton
                    onClick={() => props.removeFromCart(R.last(products))}
                    disabled={totalLockQuantity}
                  >
                    <FontIcon
                      className="material-icons"
                      style={{ fontSize: '40px' }}
                    >
                      remove_circle
                    </FontIcon>
                  </IconButton>
                )}
                {quantity}{' '}
                {lockQuantity || (
                  <IconButton
                    onClick={() => props.addToCart(R.last(products).clone())}
                  >
                    <FontIcon
                      className="material-icons"
                      style={{ fontSize: '40px' }}
                    >
                      add_circle
                    </FontIcon>
                  </IconButton>
                )}
              </td>
              <td style={cellStyles}>{priceExplained}</td>
              {!productsValid && [
                <td key={`${displayName}-errorMessage`} style={errorStyle}>
                  {products[0].data.errorMessage ||
                    R.values(products[0].data.errors).join('; ')}
                </td>,
                <td key={`${displayName}-fixer`}>
                  {false && <AddRequiredData {...props} products={products} />}
                </td>,
              ]}
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="4">
            <h3>Total: ${cart.computedTotal()}</h3>
          </td>
        </tr>
        {props.hideControls || (
          <tr>
            <td>
              <p>Cart is {validCart ? 'valid' : 'invalid'}</p>
              <ReservationInfo onExpire={props.emptyCart} />
            </td>
            {(validCart || props.isVE) && (
              <td>
                <button onClick={props.beginCheckout}>Checkout</button>
              </td>
            )}
            <td>
              <button onClick={props.emptyCart}>Empty Cart</button>
            </td>
          </tr>
        )}
      </tfoot>
    </table>
  )
}

const AddRequiredData = props => {
  const { products } = props
  const firstDataNeeded = products[0].data.dataNeeded

  switch (firstDataNeeded && firstDataNeeded[0]) {
    case 'selected_date':
      const { startDate, endDate } = products[0].data
      const validDateRangeAttrs = startDate
        ? {
            min: startDate.toISOString().split('T')[0],
            max: endDate.toISOString().split('T')[0],
          }
        : {}

      return (
        <input
          type="date"
          onChange={event => {
            props.modifyProductData(products, {
              selected_date: new Date(
                // `new Date('2017-01-01') returns
                // `Sat Dec 31 2016 18:00:00 GMT-0600 (CST)`
                // because chrome assumes 00:00 UTC and converts that to local time.
                // Using slashes instead of dashes gives us a UTC time which works for now
                // FIXME work in proper timezone support
                new Date(event.target.value.replace(/-/g, '/')).setHours(12)
              ),
            })
          }}
          {...validDateRangeAttrs}
        />
      )
    case 'selected_time':
      const { availableTimes } = products[0].data

      return (
        <AttributeChooser
          choices={availableTimes}
          onChange={event =>
            props.modifyProductData(products, {
              selected_time: event.target.value,
            })
          }
        />
      )
    case 'selected_amount':
      const onChange = event => {
        props.modifyProductData(products, {
          selected_amount: event.target.value,
        })
      }
      const pricePoints = products[0].data.suggestedPricePoints

      return (
        <AttributeChooser
          choice={pricePoints}
          onChange={onChange}
          decorator="$"
          allowTextInput={true}
        />
      )
    default:
      return <span />
  }
}

const CartListingContainer = connect(mapStateToProps, mapDispatchToProps)(
  CartListing
)

export const toWorldTime = amPmTime => {
  // TODO DEDUPE with core/models/product.js L198
  const [time, meridian] = amPmTime.split(/\s?(am|pm)/i)
  const [hours, minutes] = time.split(':')

  if (!meridian) return amPmTime

  const adjusted24HourTime =
    meridian.toLowerCase() === 'am' || hours === '12'
      ? time
      : `${parseInt(hours) + 12}:${minutes}`

  return adjusted24HourTime
}

export const AttributeChooser = ({
  choices,
  disabledChoices,
  onChange,
  decorator,
  allowTextInput,
  choice,
  extraInfo,
}) => {
  const select = choices && (
    <select onChange={onChange}>
      <option value="" />
      {choices &&
        choices.map &&
        // this shouldn't actually be `time` - it's any kind of attribute. attr? data? choice?
        choices.map(time => {
          const worldTime = toWorldTime(time)
          const disabled =
            disabledChoices && (
              disabledChoices.indexOf(worldTime) > -1 ||
              disabledChoices.indexOf(worldTime.replace(':', '')) > -1
            )

          return (
            <option key={time} value={time} disabled={disabled}>
              {decorator}
              {''}
              {time}
              {extraInfo ? extraInfo(time, choice) : ''}
            </option>
          )
        })}
    </select>
  )

  return !allowTextInput ? (
    select ? (
      select
    ) : (
      <span />
    )
  ) : (
    <div>
      {select}
      {allowTextInput && (
        <input onChange={onChange} />
      )}
    </div>
  )
}

function humanizeData(data, key) {
  const coercedDate = new Date(data)
  if (data.toDateString) {
    return data.toDateString()
  } else if (
    coercedDate.toString() !== 'Invalid Date' &&
    coercedDate > new Date() && // Don't let it coerce a number such as 1, 50 to a date
    data !== '45' && data !== '60' // temporary fix for tours, which have a `tour_quantity: '{15,30,45,60}'` field that javascript thinks is a date…
  ) {
    const dateM = moment(data)
    return key === 'start_date_time'
      ? dateM.format("dddd, MMMM Do YYYY, h:mma")
      : dateM.format("dddd, MMMM Do YYYY")
  } else if (key === 'selected_time' && !data.match(/am|pm/i)) {
    const [hours, minutes] = data.split(':')
    const meridian = hours > 11 ? 'pm' : 'am'

    return `${
      meridian === 'am' || hours === '12' ? hours : parseInt(hours) - 12
    }:${minutes} ${meridian}`
  } else if (key === 'providedInformation') {
    return data.student
      ? `${data.student.firstName} ${data.student.lastName}`
      : 'providedInformation placeholder'
  } else if (data.constructor === Object) {
    const fullData = Object.entries(data)
      .filter(([key]) => 'first_name last_name email_address phone'.split(' ').indexOf(key) > -1)
      .filter(([k, v]) => !!v)
      .map(([k, v]) => `${k}: ${v ? v.toString() : ''}`)
      .join('\n')

    return <span title={fullData}>{Object.values(data)[0]}</span>
  } else if (key === 'start_date_time' && data === '2018-08-14') {
    // a bug with promo codes reset sold line_items product attributes date to the date
    // the promo code started, 8/14. Band-aid! fix it until the promo code get worked out.
    const dateM = moment('2018-10-27T20:00:00')
    return dateM.format("dddd, MMMM Do YYYY, h:mma")
  } else if (key === 'selected_amount' || key === 'benefit_value') {
    return `$${data}`
  }
  return data.toString()
}

// change a obtuse discount name (`fixedReducedPriceWithMembership`)
// into a reportable name
const discountNameTransformer = codeName => {
  // map internal rule names to external discount names
  const nameChanges = {
    fixedReducedPriceWithMembership: 'My Mia Member',
    upToTwoFreeWithMembership: 'Investor+',
    pctDiscountWAffinity: 'Affinity Member',
    percentageDiscountWithMembership: 'Member',
    myMiaDays: 'My Mia Day',
    myMiaThursday: 'My Mia Day',
  }

  return nameChanges[codeName] || codeName
}

export function selectDetailsFromData(selectedData = {}, fullLine) {
  const discounts = selectedData.discounts
    ? selectedData.discounts.length
      ? selectedData.discounts
      : Object.keys(selectedData.discounts).length !== 0 &&
        R.values(
          R.mapObjIndexed(
            (amount, discountName) =>
              discountNameTransformer(discountName) // + ' ($' + amount + ')'
          )(selectedData.discounts)
        ).join(' ')
    : ''


  const product_type = fullLine.item.product_type
  const showEventTime = fullLine
    ? product_type === 'lecture' || product_type === 'special_event' || product_type === 'tour'
    : true

  return R.values(
    R.mapObjIndexed(
      (data, key, index) => {
        const friendlyKey = key
          .replace('selected_', '')
          .replace(/_/g, ' ')
          .replace('providedInformation', 'Student')
          .replace('start date time', 'Event Time')
          .replace('amount', 'Amount')
          .replace('benefit value', 'Benefit Value')
          .replace('location', 'Location')
          .replace('schoolName', 'School Name')
          .replace('companyName', 'Company Name')
          .replace('primary beneficiary', 'Primary Beneficiary')
          .replace('secondary beneficiary', 'Secondary Beneficiary')

        // Egypt 'Adult' tickets changed after going on sale to be 'General Admission'
        // this changes the value only cosmetically. See also a few other spots in the
        // frontend code: This file at lines 162-4, SingleProductSelect#L533, and
        // `src/util/print#L7-11
        if(data === 'Adult' && key === 'ticket_type' && fullLine.item.product_id === 'EX2019-001') {
          data = 'General Admission'
        }

        if(key === 'selected_date' && data) {
          const m = moment(data.split ? data.split('T')[0] : data)
          data = m
        }

        return (
          data &&
          data !== {} && (
            <span key={key + '-' + index}>
              {friendlyKey}: {humanizeData(data, key)}
              <br />
            </span>
          )
        )
      },
      { ...R.omit(
          ['end_date_time', 'paymentInfo', !showEventTime ? 'start_date_time' : '', 'companyId' ],
          selectedData
        ),
        discounts
      }
    )
  )
}

const filterDataObj = (filter, data) => {
  const { discounts, clearDiscounts } = data
  if (clearDiscounts) {
    data.discounts = R.omit(Array.from(clearDiscounts), discounts)
  }

  return Object.keys(data)
    .filter(filter)
    .reduce((filteredData, selectedData) => {
      filteredData[selectedData] = data[selectedData]
      return filteredData
    }, {})
}

function Upsell({cart, ...props}) {
  const donationProduct = products.find(p => p.id === 'DONATE')
  const membershipProduct = products.find(p => p.id === 'MEMBERSHIP')
  const aibDonationProduct = products.find(p => p.id === 'AIBPATDON20210428-001')
  const cartHasDonationOrMembership = cart.find(cartProd => ['DONATE', 'MEMBERSHIP', 'AIBPATDON20210428-001'].indexOf(cartProd.id) > -1)
  const cartHasAIBProduct = cart.find(cartProd => cartProd.id.match('AIB'))

  const upsellProduct = cartHasAIBProduct ? aibDonationProduct : donationProduct

  return cartHasDonationOrMembership ? null : <>
    <hr />
    <HeroBox heroProduct={upsellProduct} />
  </>
}
