// TODO handle API calls here?
// import axios from 'axios'
import { confirm as confirmAccount } from '../containers/account/api'
import { push } from 'react-router-redux'

export const GET_AUTH_TOKEN = 'user/GET_AUTH_TOKEN'
export const GET_USER = 'user/GET_USER'
export const CONFIRM_USER_SIGNUP = 'user/CONFIRM_USER_SIGNUP'
export const CONFIRM_USER_SIGNUP_SUCCESS = 'user/CONFIRM_USER_SIGNUP_SUCCESS'
export const CONFIRM_USER_SIGNUP_ERROR = 'user/CONFIRM_USER_SIGNUP_ERROR'
export const LOG_IN = 'user/LOG_IN'
export const OPEN_DRAWER = 'user/OPEN_DRAWER'
export const RESUME_OPEN_DRAWER = 'user/RESUME_OPEN_DRAWER'

const localAccount = window.localStorage.getItem('account')

const isDev = process.env.NODE_ENV === 'development' || window.location.host === 'dev-ticket.artsmia.org'

const defaultAccount = {
  identified: false,
  isDev
}

const initialState = localAccount ? {
  ...JSON.parse(localAccount),
  loggedInFromBrowserStoredSession: true,
  isDev,
} : defaultAccount

export default (state = initialState, action) => {
  switch (action.type) {
    case CONFIRM_USER_SIGNUP:
      console.info('CONFIRM_USER_SIGNUP', action)
      return {
        ...state,
        identified: true,
        confirming: true,
        email: action.email,
        token: action.token,
      }
    case CONFIRM_USER_SIGNUP_SUCCESS:
      console.info('CONFIRM_USER_SIGNUP_SUCCESS', action)
      return { ...state, confirming: false, confirmed: true }
    case CONFIRM_USER_SIGNUP_ERROR:
      console.info('CONFIRM_USER_SIGNUP_ERROR', action)
      return { ...state, confirming: false, confirmed: false, error: true }
    case LOG_IN:
      const { user, operator } = action
      // if the browser has a stored localStorage user
      const hasStoredBrowserSession = state.user && !!(state.user.loggedInFromBrowserStoredSession
        || !!state.user.hiveUserId)
      // and this log in is for a VE user
      const loginViaSFDC = !!operator
      // ignore the initially-logged-in browser user
      const nextUser = loginViaSFDC && hasStoredBrowserSession
        ? { ...user, loggedInFromBrowserStoredSession: false }
        : { ...state.user, ...user }

      console.info('LOG_IN', {hasStoredBrowserSession, loginViaSFDC, nextUser})

      return {
        ...state,
        identified: true,
        confirmed: true,
        user: nextUser,
        operator: operator,
        isVE: !!operator,
        isMember: nextUser.memberships && nextUser.memberships.length > 0,
      }
    case LOG_OUT:
      window.localStorage.removeItem('account')
      return {
        identified: false,
      }
    case OPEN_DRAWER:
      const { selectedLocation, initialCashDrawerCount, name } = action

      return {
        ...state,
        operator: {
          ...state.operator,
          drawerOpen: true,
          location: selectedLocation,
          operator_name: name,
          initialCashDrawerCount,
        },
      }
    case RESUME_OPEN_DRAWER:
      return {
        ...state,
        operator: {
          ...state.operator,
          drawerOpen: true,
          location: action.selectedLocation,
          operator_name: action.selectedName,
        },
      }
    case CLOSE_DRAWER:
      return {
        ...state,
        operator: {
          ...state.operator,
          drawerOpen: false,
        },
      }
    default:
      return state
  }
}

export const getAuthToken = () => {
  return dispatch => {
    dispatch({ type: GET_AUTH_TOKEN })
  }
}

export const getUser = () => {
  return dispatch => {
    dispatch({ type: GET_USER })
  }
}

export const confirmUserSignup = (email, token) => {
  return dispatch => {
    dispatch({ type: CONFIRM_USER_SIGNUP, email, token })
    confirmAccount(email, token).then(response => {
      const { success } = response.data

      if (success) {
        dispatch({ type: CONFIRM_USER_SIGNUP_SUCCESS, status: success })
      } else {
        dispatch({ type: CONFIRM_USER_SIGNUP_ERROR, status: 'test' })
      }
    })
  }
}

export const logIn = (user, operator) => dispatch => {
  console.info('dispatchign logIn', { user, operator })
  dispatch({ type: LOG_IN, user, operator })
}

const LOG_OUT = 'user/LOG_OUT'
export const logOut = () => dispatch => {
  console.info('dispatchign logOut')
  dispatch({ type: LOG_OUT })
}

export const openDrawer = ({
  selectedLocation,
  initialCashDrawerCount,
  name,
}) => dispatch => {
  dispatch({ type: OPEN_DRAWER, selectedLocation, initialCashDrawerCount, name })
  dispatch(push('/'))
}

export const resumeOpenDrawer = (selectedLocation, selectedName) => dispatch => {
  dispatch({ type: RESUME_OPEN_DRAWER, selectedLocation, selectedName })
  dispatch(push('/'))
}

export const CLOSE_DRAWER = 'user/CLOSE_DRAWER'
export const closeDrawer = location => dispatch => {
  dispatch({ type: CLOSE_DRAWER, location })
}
