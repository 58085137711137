/* global StripeTerminal */
import React from 'react'
import CartModel from '../core/models/cart'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { connectTerminal, connectReader, disconnectReader } from '../modules/hardware'

async function fetchConnectionToken() {
  // Your backend should call /v1/terminal/connection_tokens and return the JSON response from Stripe
  const response = await fetch('/stripe/terminal_connection_token', { method: "POST" })
  const data = await response.json()
  // TODO error handling when server doesn't return?

  return data.secret
}

class StripeTerminalWrapper extends React.Component {
  constructor(props) {
    super(props)
    const lastConnectedReaderId = window.localStorage.getItem('lastConnectedReaderId')

    this.state = {
      discoveredReaders: [],
      lastConnectedReaderId,
    }
  }

  readerDisconnected = (data) => {
    console.log("Disconnected from reader", data)
    this.setState({error: data.error})
    this.props.disconnectReader()
  }

  render() {
    if(this.props.justUpdateTerminalDisplay) return null

    const {connectedReader} = this.props
    const {error, discoveredReaders} = this.state

    const readerConnected = connectedReader && connectedReader.status === 'online'
    const reader = connectedReader
    const otherReaders = (discoveredReaders || []).filter(r => !reader || (r.status === 'online' && r.id !== reader.id))
    const onlineMessage = this.props.onlineMessage

    return <details>
      <summary>
        {!error && readerConnected ? <>
            Card reader connected: <strong>{reader.label}</strong>
            {' '}{onlineMessage}
          </> : <span style={{border: '1px solid red'}}>
            {error ? error.message : 'No terminal connected'}
          </span>
        }
      </summary>

      {otherReaders.length === 0 ? 'no other readers found' : <>
        <p>Other Readers available:</p>

        <ul>{otherReaders.map(reader => {
          return <li
            key={reader.id}
            title={`${reader.ip_address} - ${reader.status}`}
            onClick={this.connectReader.bind(this, reader)}
          >{reader.label}</li>
        })}</ul>
      </>}

      <hr />
      <>
        <button onClick={this.disconnectReader}>Disconnect reader</button>
        <button onClick={(_) => this.discoverReaders()}>Connect reader</button>
      </>
    </details>
  }

  async componentDidMount() {
    this.loadStripeTerminalSDK()
    this.formulateAndSetReaderDisplay()

    const {terminal, connectedReader, handleReaderConnected} = this.props

    if(terminal && connectedReader && handleReaderConnected)
      handleReaderConnected && handleReaderConnected({
        reader: connectedReader,
        terminal: terminal,
      })
  }

  componentDidUpdate() {
    this.formulateAndSetReaderDisplay()
  }

  async loadStripeTerminalSDK() {
    const head = document.getElementsByTagName('head')[0]
    const script = document.createElement('script')
    script.src = 'https://js.stripe.com/terminal/v1/'
    const hasScript = document.getElementsByTagName('head')[0].querySelector(`script[src='${script.src}']`)

    if(!hasScript) {
      head.appendChild(script)
      script.onload = this.sdkLoaded.bind(this)
      return console.info('loading stripe terminal')
    }

    const {terminal, connectedReader} = this.props
    if(!terminal || !connectedReader) this.sdkLoaded.bind(this)
  }

  async sdkLoaded() {
    console.info({StripeTerminal}, StripeTerminal.create)
    const terminal = StripeTerminal.create({
      onFetchConnectionToken: fetchConnectionToken,
      onUnexpectedReaderDisconnect: this.readerDisconnected.bind(this),
    })

    this.props.connectTerminal(terminal)
    this.discoverReaders()
  }

  async discoverReaders() {
    const {terminal, handleReadersDiscovered} = this.props
    if(!terminal) return

    // const configuration = {method: 'simulated'}
    // const discoverResult = await terminal.discoverReaders(configuration)
    const discoverResult = await terminal.discoverReaders()
    const {discoveredReaders} = discoverResult

    handleReadersDiscovered && handleReadersDiscovered(discoverResult)
    this.setState({discoveredReaders})

    if (discoverResult.error) {
      console.log('Failed to discover: ', discoverResult.error)
    } else if (discoverResult.discoveredReaders.length === 0) {
      console.log('No available readers.')
    } else {
      // You should show the list of discoveredReaders to the
      // cashier here and let them select which to connect to (see below).

      this.connectReader(discoveredReaders)
    }
  }

  async connectReader(readerCandidates) {
    const {lastConnectedReaderId} = this.state
    const {terminal, isDev, handleReaderConnected, connectedReader} = this.props
    if(!terminal) return

    let selectedReader

    // If a list of readers is passed in, select one based on the logged
    // in VE location. Use `lastConnectedReaderId` when present
    if(readerCandidates.length) {
      const onlineReaders = readerCandidates.filter(({status}) => status === 'online')
      const lastConnectedReader = onlineReaders.find(reader =>
        reader.id === lastConnectedReaderId
      )
      const readerLocation = this.props.readerLocation || 'null'
      const locationMatchedReader = onlineReaders
        .find(reader =>
          reader.label.toLowerCase().replace(' ', '')
            === readerLocation.toLowerCase()
        )

      selectedReader = lastConnectedReader // first check for a recently connected reader
        || locationMatchedReader // then, one matching the logged in drawer location
        || (isDev && onlineReaders[0]) // and in dev who cares, just pick one
    } else {
      // If a specific reader is given, use that
      selectedReader = readerCandidates
    }

    if(!selectedReader) return console.info('no reader to connect', {
      readerCandidates,
      lastConnectedReaderId,
      selectedReader,
    })

    // TODO don't disconnect unless the newly seleced reader is different than 
    // one that's currently connected.
    let disconnectResult
    const readerConnected = connectedReader
      && terminal.connectionStatus === 'connected'

    debugger

    if(readerConnected && lastConnectedReaderId !== selectedReader.id) {
      disconnectResult = await terminal.disconnectReader()
      this.props.disconnectReader()
    }

    let connectResult

    const shouldConnect = !terminal.reader && !readerConnected

    if(shouldConnect) {
      connectResult = await terminal.connectReader(selectedReader)

      if (connectResult.error) {
        console.log('Failed to connect:', connectResult.error, {
          selectedReader
        });
        return this.setState({error: connectResult.error})
      } else {
        console.log('Connected to reader:', connectResult.reader.label);
      }
    }

    const newReader = (connectResult && connectResult.reader) || (terminal && terminal.reader)

    console.info('connectReader', {
      readerCandidates,
      selectedReader,
      disconnectResult,
      connectResult,
      readerConnected,
      newReader
    })

    handleReaderConnected && handleReaderConnected({
      reader: newReader,
      terminal: terminal,
    })

    debugger

    const newlyConnectedReaderId = newReader && newReader.id
    const prevConnectedReaderId = window.localStorage.getItem('lastConnectedReaderId')
    const reduxReaderId = this.props.connectedReader && this.props.connectedReader.id

    if(newReader && (prevConnectedReaderId !== newlyConnectedReaderId || reduxReaderId !== newlyConnectedReaderId)) {
      window.localStorage.setItem('lastConnectedReaderId', newlyConnectedReaderId)
      this.setState({lastConnectedReaderId: newlyConnectedReaderId})
      this.props.connectReader(newReader)
    }
  }

  formulateAndSetReaderDisplay = (debounceCount=0) => {
    const {terminal, cartProducts: _cart, account, connectedReader} = this.props

    const readerConnected = connectedReader && connectedReader.status === 'online'
    const terminalConnection = terminal && terminal.connectionStatus !== 'not_connected'

    if(!terminal || !terminalConnection || !readerConnected || !_cart) {
      // if a reader isn't connected, debounce and call this method again to see if a connection gets established
      if(!readerConnected && debounceCount <= 2)
        setTimeout(() => this.formulateAndSetReaderDisplay(debounceCount + 1), 1000)

      false && console.info('formulateAndSetReaderDisplay reader or cart are null', {
        readerConnected,
        terminal,
        _cart,
        debounceCount,
      })

      return
    }

    const cart = new CartModel(_cart, account.user)
    cart.validate()
    const amount = cart.computedTotal()*100

    // When the cart is empty, make sure the reader display is cleared
    // and any attempt at collecting payment is cancelled
    if(cart.products.length === 0) {
      if(terminal) {
        const {collectPaymentMethodAttempt, clearReaderDisplay} = terminal
        clearReaderDisplay()

        if(collectPaymentMethodAttempt) {
          const {isPending} = collectPaymentMethodAttempt
          if(isPending)
            terminal.cancelCollectPaymentMethod()
        }
      }
      return
    }

    const terminalDisplayData = {
      type: 'cart',
      cart: {
        line_items: cart.products.map(p => {
          const _p = p.toLineItem()
          return {
            description: _p.item.product_name,
            amount: _p.amount*100,
            quantity: _p.quantity,
          }
        }),
        tax: 0,
        total: amount,
        currency: 'usd',
      },
    }

    console.info('formulateAndSetReaderDisplay', {_cart, cart, terminalDisplayData})

    terminal.setReaderDisplay(terminalDisplayData)
  }

  disconnectReader = () => {
    const {terminal} = this.props
    terminal.clearReaderDisplay()
    try {
      terminal.cancelCollectPaymentMethod()
    } catch(e) {
    }
    this.props.disconnectReader()
  }
}

// export default StripeTerminalWrapper

const mapStateToProps = (state, ownProps) => ({
  cartProducts: state.products.cart,
  customer: state.account.contact || state.account.user,
  account: state.account,
  operator: state.account && state.account.operator,
  isVE: state.account && state.account.isVE,
  isDev: state.account && state.account.isDev,
  connectedReader: state.hardware && state.hardware.connectedReader,
  terminal: state.hardware && state.hardware.connectedTerminal,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      connectTerminal,
      connectReader,
      disconnectReader,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(StripeTerminalWrapper)
