import React from 'react'
import R from 'ramda'
import { withFormik, Field } from 'formik'
import yup from 'yup'
import axios from 'axios'
import DateTime from 'react-datetime/DateTime'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const isObject = v => R.type(v) === 'Object' && v.constructor === Object
/* So we can decorate values in the schema with labels, etc
 * a `key: value` combo can take one of three forms
 *
 * (1) simply a string key with a yup value
     phone: yup.string().required().min(10)
 *
 * (2) a key with an object describing its singular value, containing a `yup` key
 * with validation info for that key
 *
       {
         phone: {
           extra: "(Please include an area code)",
           yup: yup.string().required().min(10),
         }, …
       }
 *
 * which allows customization of the input field (`extra`) and then
 * transforms to `{phone: yup.string().required().min(10),}`
 * for yup/formik to do validation
 *
 * (3) an object with no yup key, which will be recursively processed for cases 1
 * and 2, then wrapped in a `yup.object().shape()`
 * (TODO when passing an object with a mix of child fields and customizations
 * this needs to do a bit more work - currently for this case only accepts a `label`
 * to customize the name of the fieldset - see the `R.omit` label hack)
 */
const extractSchemas = R.map(val => {
  const transforms = {
    Object: () => val.yup, // pass thru existing defined yup objects
    String: () => yup[val.yup] && yup[val.yup].call(), // coerce a string (`string`, `number`, …)
    Array: () => val.yup.reduce((__yup, arg) => __yup[arg](), yup), // reduce a chain of yup values `[string, required]`
    // how can arguments be passed?
  }
  const _yup = val.yup && transforms[R.type(val.yup)]()

  const extractedSchema = isObject(val)
    ? _yup ||
      yup.object().shape(extractSchemas(R.omit(['label', 'extra'], val)))
    : val

  return extractedSchema
})

class RequiredInfoSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      itemsData: [],
    }
  }

  render() {
    const infoSchema = this.props.product.data.information
    const extracted = extractSchemas(infoSchema)
    const validationSchema = yup.object().shape(extracted)

    const header = this.props.header

    const { autoUpdateInformation } = this.props.product.data

    const _infoForm = ({
      values,
      touched,
      errors,
      isValid,
      dirty,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      handleReset,
      ...props
    }) => {
      //. TODO rename this fn, as we're operating on a vanilla object at this point
      const yupSchemaToForm = (schema, nestContext) => {
        return R.mapObjIndexed((field, name) => {
          const {
            extra,
            component,
            choices,
            type,
            label: l,
            htmlOptions,
            ...children
          } = field
          const label = typeof field.label !== 'function' && field.label

          // uppercase the first letter
          // and break any 'came[lC]ase' junctions into 'Came[l C]ase'
          const fieldLabel =
            label ||
            name
              .replace(/^[a-z]/, m => m.toUpperCase())
              .replace(/([^A-Z])([A-Z0-9])/g, (m, m1, m2) => `${m1} ${m2}`)

          if (field.constructor === Object && !field.yup) {
            const nestedForm = yupSchemaToForm(children, name)
            return (
              <fieldset key={fieldLabel}>
                <legend>{fieldLabel}</legend>
                {extra && <p>{extra}</p>}
                {R.values(nestedForm)}
              </fieldset>
            )
          }

          const fieldName = nestContext ? `${nestContext}.${name}` : name

          const hasError = nestContext
            ? errors[nestContext] && errors[nestContext][name]
            : errors[name]
          // const isTouched = nestContext
          //   ? touched[nestContext] && touched[nestContext][name]
          //   : touched[name]
          const showError =
            hasError &&
            (typeof hasError.match !== undefined && hasError.match(/required/)
              ? ' * required'
              : hasError.replace(fieldName, ''))

          const validate =
            name === 'zip'
              ? async value => {
                  const zipCodeQuery = `https://nominatim.openstreetmap.org/search?postalcode=${value}&country=USA&addressdetails=1&format=json`
                  if (value.length > 4 && value !== values.address.zip) {
                    const response = await axios.get(zipCodeQuery)
                    const { city, state } = response.data[0].address

                    props.setFieldValue('address.city', city)
                    props.setFieldValue('address.state', state)
                  }
                }
              : value => undefined

          const customField = name === 'birthDate' && (
            <DateTime
              timeFormat={false}
              isValidDate={date => date < new Date('2017-01-01')}
              viewMode={'years'}
              onChange={date => {
                // date could either be a ISO string from a browser date handler
                // , or a moment object
                const isoDate = date.toISOString
                  ? date.toISOString().replace(/T.*$/, '')
                  : date

                props.setFieldValue('student.birthDate', isoDate)
              }}
              value={values && values.student && values.student.birthDate}
              closeOnSelect={true}
              disableOnClickOutside={true}
              inputProps={{ type: 'date' }}
              viewDate={new Date(2011, 0, 15, 2, 2, 2, 2)}
            />
          )

          return (
            <div key={fieldLabel}>
              <label htmlFor={fieldName}>
                {label || fieldLabel}
                {extra && (
                  <span style={{ color: '#aaa' }}>
                    <br />
                    {extra}
                  </span>
                )}
                <br />
                {!choices ? (
                  customField || (
                    <Field
                      component={component || 'input'}
                      name={fieldName}
                      type={field._type}
                      validate={validate}
                      {...htmlOptions}
                    />
                  )
                ) : (
                  <ChooserField
                    choices={choices}
                    component={component}
                    fieldName={fieldName}
                    validate={validate}
                    type={type || component}
                    values={values}
                  />
                )}
              </label>

              {hasError && <span style={{ color: 'red' }}>{showError}</span>}
            </div>
          )
        }, schema)
      }
      const infoForm = yupSchemaToForm(infoSchema)
      const disableSubmit = !isValid || isSubmitting

      if (autoUpdateInformation && dirty) {
        autoUpdateInformation
          ? this.props.updateData(values)
          : this.debouncedUpdateData(values)
      }

      return (
        <form
          onSubmit={handleSubmit}
          autoComplete={this.props.isVE ? 'off' : 'on'}
        >
          {R.values(infoForm)}

          {autoUpdateInformation || (
            <FormControls disableSubmit={disableSubmit} />
          )}
        </form>
      )
    }

    const InfoForm = withFormik({
      handleSubmit: (values, { props, setErrors, setSubmitting }) => {
        this.props.updateData(values)
      },
      // For fields with additional info, extract a yup param from the object
      validationSchema: validationSchema,
      // idea: piggy-back on `validate` to update parent component state
      // not sure this will work but who knows?
      // validate: (values, props) => {
      //   console.info('validating', { values, updateData: this.props.updateData, })
      //   return {}
      // },
      validateOnChange: true,
      isInitialValid: false,
      mapPropsToValues: props => {
        const { identifiedUser } = props

        const homeAddr =
          identifiedUser &&
          identifiedUser.addresses &&
          identifiedUser.addresses.find(add => add.type === 'Home')
        const address = homeAddr
          ? homeAddr
          : identifiedUser &&
            identifiedUser.addresses &&
            identifiedUser.addresses[0]
        const prefilledAddress = address
          ? {
              street: address.street_address,
              zip: address.postal_code,
              state: address.state,
              city: address.city,
              ...props.address,
            }
          : {}

        const parent = identifiedUser
        const prefilledParent = parent
          ? {
              name: `${parent.first_name} ${parent.last_name}`,
              email: parent.email_address,
              phone: parent.phone,
            }
          : {}

        return R.pick(Object.keys(infoSchema), {
          ...R.omit(['identifiedUser'], props),
          address: { ...props.address, ...prefilledAddress },
          parentGuardian: { ...props.parentGuardian, ...prefilledParent },
        })
      },
    })(_infoForm)

    const identifiedUser =
      this.props.account &&
      this.props.account.identified &&
      this.props.account.user

    const formInfoProps = {
      ...this.props.product.data.providedInformation,
      ...(identifiedUser
        ? {
            identifiedUser,
          }
        : {}),
    }
    return (
      <div>
        <h5 id="infoForm">{header || 'Required Student Information'}</h5>
        <InfoForm {...formInfoProps} />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const isMember = state.account && state.account.isMember

  return {
    account: state.account,
    isVE: state.account && state.account.isVE,
    isMember,
    ...ownProps,
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(
  RequiredInfoSelector
)

const ChooserField = props => {
  let { choices, fieldName, type, values } = props

  if (props.type === 'radio') {
    return (
      <div>
        {choices.map(value => (
          <span key={value} style={{ display: 'block' }}>
            <Field
              name={fieldName}
              value={value}
              id={fieldName + '-' + value}
              type={type}
              checked={value === values[fieldName]}
            />
            <label htmlFor={fieldName + '-' + value}>{value}</label>
          </span>
        ))}
      </div>
    )
  }

  if (props.type === 'select') {
    return (
      <Field name={fieldName} component="select">
        <option value="" />
        {choices.map(value => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </Field>
    )
  }
}

const FormControls = props => {
  const { disableSubmit } = props
  return (
    <div>
      <button
        type="submit"
        disabled={disableSubmit}
        style={{ opacity: disableSubmit ? '0.3' : 1 }}
      >
        Save Information
      </button>
    </div>
  )
}
