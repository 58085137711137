import React from 'react'
// import { push } from 'react-router-redux'
// import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const CartSummary = props => {
  const itemCount = props.cart.length
  return (
    <div>
      <p>
        {itemCount} item{itemCount !== 1 ? 's are' : ' is'} in the cart
      </p>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    cart: state.products.cart,
  }
}

export default connect(mapStateToProps)(CartSummary)
