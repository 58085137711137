import React from 'react'

export default class ReasonableWPImage extends React.Component {
  constructor(props) {
    super(props)
    const { src: writtenSrc } = props
    const src = writtenSrc && writtenSrc.replace('http:', 'https:')
    const smallImage = src && src.replace(/(\.\w+)$/, '-525x350$1')
    // const bigImage = src && src.replace(/(\.\w+)$/, '-1024x403$1')

    this.state = {
      src: smallImage,
      loadingError: false,
      loaded: false,
    }
  }

  handleImageLoad = event => this.setState({ loaded: true })
  handleError = event => this.setState({ src: this.props.src })

  render() {
    return (
      <img
        src={this.state.src}
        className="cell-image"
        alt=""
        onLoad={this.handleImageLoad}
        onError={this.handleError}
      />
    )
  }
}
