import React from 'react'
import { connect } from 'react-redux'

function HiveDeprecatedRedirect(props) {
  const { isVE } = props
  const isHiveSpecificDomain = window.location.href.match(/hive.artsmia.org|dev-ticket.artsmia.org|localhost/)
  const shouldRedirectToNewTicketing = isVE ? false : !isHiveSpecificDomain

  console.info('DeprecatedRedirect !!!', {
    isVE,
    isHiveSpecificDomain,
    shouldRedirectToNewTicketing,
  })

  if(shouldRedirectToNewTicketing) {
    console.info('redirecting 3')
    setTimeout(() => window.location.href = 'https://tickets.artsmia.org', 3000)
  } else {
    return props.children
  }

  const bannerStyles = {
    background: 'yellow',
    padding: '1em',
  }

  return <>
    <p style={bannerStyles}>
      Mia has launched a new <a href="https://tickets.artsmia.org">ticketing system</a>!
      You will be redirected shortly.
    </p>
    {props.children}
  </>
}

const mapStateToProps = state => {
  return {
    isVE: state.account && state.account.isVE,
  }
}
export default connect(mapStateToProps)(HiveDeprecatedRedirect)
