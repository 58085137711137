/** @format
 */
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import moment from 'moment'

import {
  // addReservationInfo,
  clearReservationInfo,
} from '../../modules/products'

class ReservationInfo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      delta: this.computeDelta(),
    }
  }

  render() {
    const { delta } = this.state || {}
    const minutePlural = parseInt(delta / 60) === 1 ? 'minute' : 'minutes'
    const secondPlural = parseInt(delta) % 60 === 1 ? 'second' : 'seconds'
    const showMinutes = delta > 60

    const prettyDelta = moment
      .utc(delta * 1000)
      .format(
        showMinutes
          ? `m [${minutePlural}], s [${secondPlural}]`
          : `s [${secondPlural}]`
      )

    return delta ? <p>Reservation expires in: {prettyDelta}</p> : <span />
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
  }

  tick() {
    const delta = this.computeDelta()
    if (!delta) return
    const { onExpire } = this.props

    this.setState({ date: new Date(), delta })

    if (delta < 0) {
      onExpire && onExpire()
      this.props.clearReservationInfo(this.props.reservationInfo.queue_id)
    }
  }
  // TODO
  // render xx seconds in minutes and seconds (with moment?)
  // - simplified towards minutes when above 3, and with both minutes and seconds below a threshold?
  // color code seconds green/yellow/red as time dwindles?
  // when it's close to the end, warn the user what is going to happen `onExpire`
  //
  // extract this component as well as a HoC for <Capacity> in general
  //   to `src/components/capacity`?
  //   extracting Capacity to a component would allow checks both at the product
  //     selector and in the cart and even a triple check when clicking 'checkout'
  //
  // implement quantity for reservation q?
  // implement "renewal of registration" - when moving from product to cart, restart the ticker at top of allotted time?
  // both of ^^ touch on updating an existing reservation
  // store reservationId in a cookie and load it by default?
  //   [x] first persist it in redux store
  //   [ ] then cookie? - if cookie do we need to also store the cart?
  //   when it's loaded, check if it's expired first
  //   when it expires, clear the cookie?
  //
  // clean up console logs
  //
  // render props for <ReservationInfo>?
  // …to decorate and/or express custom logic when something happens, i.e. remaining === 0?

  computeDelta() {
    const { reservationInfo: info } = this.props
    if (!info) return
    const expireDate = new Date(info.expire * 1000)
    return (expireDate - new Date()) / 1000
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  reservationInfo: state.products.reservationInfo,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearReservationInfo,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(ReservationInfo)
