import R from 'ramda'
import axios from 'axios'
import moment from 'moment'

export async function getCapacity(productId, date, time, reservationInfo) {
  if (!productId) return 0
  let checkReservedTickets

  // do strict capacity checking with reservation for these product IDs
  // how to define this in product data, which isn't passed to this fn?
  // Bryan Stevenson with ticket reservations that time out
  if(productId === 'TLK20180622-001' || productId === 'TLK20180622-002') {
    date = '20180622'
    checkReservedTickets = true
  }

  const isEgypt = productId === 'EX2019-001'
  const isCOVIDGA = productId === 'GEN-FY21-001'
  if(isEgypt || isCOVIDGA) { // TODO sort these out.
    // now the API doesn't separate dates with `-` so get rid of them
    date = date && date.replace(/-/g, '')
  }

  const params = R.filter(R.identity)([productId, date]).join('/')

  let capacityInfo

  if(checkReservedTickets) {
    const waitCapacity = await axios.get(`/v1/orders/wait_capacity/${params}`)
    capacityInfo = waitCapacity.data

    reservationInfo = reservationInfo && !reservationInfo.error && {...reservationInfo, waitCapacity: waitCapacity.data}
    // this could also work that if we don't already have a reservation, get one
    // from the API here instead of waiting until it's added to the cart
    // if(!reservationInfo) reservationInfo = await getReservation(productId, date)
    // TODO
    // handle `{error: "No remaining capacity"}` with an error message to the user?
  } else {
    const endpoint = isEgypt || isCOVIDGA ? '/v1/capacity' : '/v1/orders/capacity'

    const response = await axios.get(`${endpoint}/${params}`)
    capacityInfo = response.data
  }

  let capacityRemaining

  if (capacityInfo && !R.isNil(capacityInfo.current_capacity)) {
    // Simple object with capacity across timeslots
    const { current_capacity } = capacityInfo

    capacityRemaining = parseInt(current_capacity)
  } else {
    // Complex capacity
    //
    // If a date and time are passed, return that one
    // otherwise return the default for that day
    // This isn't good enough but works for now
    const timeWithoutMeridian = time && time.split(/\s?[am|pm]/i)[0]
    const timedCapacityInfo = time && capacityInfo[timeWithoutMeridian.replace(':', '')]

    capacityRemaining = timedCapacityInfo

    // TODO for Egypt this is no longer a simple int of capacity, but a set of
    // 'buckets' with integers for their individual capacities.
    // Deal with that here or pass it along?
  }

  if(window.DEBUG) {
    console.info('getCapacity', {time}, {capacityRemaining, capacityInfo, reservationInfo})
  }
  return { capacityRemaining, capacityInfo, reservationInfo }
}

const groupedById = R.groupBy(lineItem => {
  const id = lineItem.item.item_id

  const {
    selected_date: date,
    selected_time: time,
  } = lineItem.line_item_attributes
  const instance = date && time && date.toISOString().split('T')[0] + '-' + time

  // TODO add my mia tours to this list
  return (id === 'PCHTOUR' || id === 'AIBWKSP20180427-001') ? id + '-' + instance : id
})

export async function decrementProductCapacity(cartData) {
  const groupedItems = groupedById(cartData.line_items)

  R.mapObjIndexed(async (items, id) => {
    if(id === 'EX2019-001' || id === 'EX2019-TOUR-001' || id === 'EX2019-TOUR-002' || id.match(/EX|GEN/)) {
      return // dont update capacity from here for Egypt, the API does it
    }

    const { capacityRemaining } = await getCapacity(id)

    const nextCapacityPayload = {
      id: id,
      capacity: capacityRemaining - items.length,
    }

    await axios.put(
      `/v1/orders/capacity`,
      nextCapacityPayload
    )
  }, groupedItems)
}

export async function getReservation(productId, date, quantity) {
  // TODO implement quantity in reservations when API gets more sophisticated
  const params = R.filter(R.identity)([productId, date]).join('/')
  // const response = await axios.get(`/v1/orders/queue/${params}`)
  // return response.data

  // IDEA 
  // this could independently request a single reservation per `quantity` 
  // of these products in the cart, and store them on the product. That would handle
  // single or multiple reservations?
  //
  const quantityReservations = R.times(
    () => axios.get(`/v1/orders/queue/${params}`),
    quantity
  )
  const responses = await Promise.all(quantityReservations)
  const reservations = responses.map(r => r.data)

  // This only returns one of the reservations, but that's OK?
  // they both expire at the same time so as long as we track on
  // we can count on the other expiring at around the same time
  // …except when the purchase is made the API will only clear out
  // one reservation?
  return {
    ...responses[0].data,
    reservations,
  }
}

// Oversell, Rush and Member Hold tickets are available to VE only, on the
// day of attendance, in person, and only at lobby workstations. Member
// Holds are made available last, only to members.
// (TODO double check, member vs rush?)
// TODO return first or all available buckets?
export function getAvailableBuckets(date, operator, bucketInfo, user) {
  const isSameDay = moment(date).isSame(new Date(), 'day')
  // VE operators who aren't on a "phone drawer" can sell rush tickets on the day-of
  // VE team leads, managers, and webdev users can sell rush tickets ahead of time if need be
  const operatorAllowedToSellDayOfBuckets = (
    isSameDay
    && operator
    && !operator.location.match(/phone/i)
  ) || (operator && operator.location.match(/webdev|manager|teamlead/i))
  let bucketPrecedence = ['general_admission']
  const userIsMember = user && user.memberships && user.memberships.length > 0
  if(operatorAllowedToSellDayOfBuckets)
    bucketPrecedence = bucketPrecedence.concat(['rush', 'member_hold', 'oversell'])
  const alternateBucket = bucketPrecedence.find(bucketName => bucketInfo[bucketName] > 0)

  if(window.DEBUG) {
    console.info('getAvailableBuckets', {
      isSameDay,
      bucketPrecedence,
      alternateBucket,
      operatorAllowedToSellDayOfBuckets,
      userIsMember,
    })
    debugger
  }

  return alternateBucket
}

export function setCapacity(productId, capacity) {
  const nextCapacityPayload = {
    id: productId,
    capacity: capacity
  }

  return axios.put(
    `/v1/orders/capacity`,
    nextCapacityPayload
  )
}

// TODO - this needs to balance capacity across buckets sometimes…
//
// When there are 2 GA tickets left but a user wants 3, it should sell 2 from GA and 1 from oversell.
// 1 oversell remaining, but a member wants 3? dock 1 oversell and 2 member hold?
