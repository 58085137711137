import React from 'react'
import HeaderTitle from './Header/HeaderTitle'
import NavIcon from './Header/NavIcon'
import Nav from './Header/Nav'

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isToggleOn: true }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn,
    }))
  }

  render() {
    return (
      <div>
        {/*<details>{JSON.stringify(json)}</details> */}

        <header>
          <a href="#content" className="screen-reader-text">
            Click to skip to site content
          </a>
          <div className="row two-thirds">
            <div className="col col-3 header-grid">
              <HeaderTitle />
            </div>
            <div className="col col-9 header-grid position-relative">
              <Nav
                isToggleOn={this.state.isToggleOn}
                onClick={this.handleClick}
              />
              <NavIcon
                isToggleOn={this.state.isToggleOn}
                onClick={this.handleClick}
              />
            </div>
          </div>
        </header>
      </div>
    )
  }
}
export default Header
