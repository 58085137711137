import React from 'react'
import axios from 'axios'

export default class SecondaryNavData extends React.Component {
  constructor(props) {
    super(props)
    this.state = { json: false }
  }

  componentWillMount() {
    this.loadExternalContent()
  }

  loadExternalContent() {
    // Method for getting data from the provided end point url

    var id = 3870
    // WP Menu "Main Nav"
    // https://staging.artsmia.org/wp-admin/nav-menus.php?action=edit&menu=3870

    var endPoint = 'https://new.artsmia.org/wp-json/wp-api-menus/v2/menus/' + id

    return axios
      .get(endPoint)
      .then(response => {
        this.setState({ loaded: true, json: response.data.items, response })
      })
      .catch(error => {
        Promise.reject(error)
      })
  }

  render() {
    const { json } = this.state

    const secondaryNavLinks =
      json &&
      json.map(navItem =>
        <li key={navItem.id}>
          <a href={navItem.url.replace('staging.', 'new.').replace('live-artsmia.pantheonsite.io', 'new.artsmia.org')}>
            {navItem.title}
          </a>
        </li>
      )
    return (
      <div>
        {secondaryNavLinks}
      </div>
    )
  }
}
