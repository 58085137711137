import React from 'react'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'

export default ({
  handleDiscount,
  product,
  slidingScale,
  updateData,
  isVE,
  priceLabel,
}) => {
  const { entitlements } = product.data

  if (!entitlements || entitlements.length === 0) return <span />

  return (
    <div>
      <SelectField
        floatingLabelText={product.data.slidingScaleLabel || 'Sliding Scale'}
        floatingLabelFixed={true}
        value={slidingScale}
        // onChange={handleDiscount}
        onChange={(event, index, value) => {
          handleDiscount(event, index, value, true) // true resets the price - sliding scale isn't a discount
          updateData('selected_sliding_scale')(event)
        }}
        autoWidth={true}
        errorText={!slidingScale && 'Must select a price level'}
        errorStyle={{color: 'orange'}}
      >
        {product.data.id.match('YOUTH') && (
          <MenuItem value={'-1'} primaryText={'Over $60,000'} />
        )}
        {entitlements
          .filter(ent => (ent.user_type === 'VE' ? isVE : true))
          .map((entitlement, index) => {
            // HACK! the material UI select highlights items by their value.
            // This appends a distinct letter to the end of each number
            // that gets removed by `parseInt` when applying discount percentage
            const char = String.fromCharCode(97 + index)

            return (
              <MenuItem
                value={entitlement.price_override + char}
                primaryText={`${entitlement.entitlement_name}`}
                key={entitlement.entitlement_name + index}
              />
            )
          })}
      </SelectField>
      <p>
        {product.data.id.match('YOUTH') && slidingScale === '75f' ? (
          <div>
            <p style={{ marginTop: '1em' }}>
              Price per Class: Free to those who qualify
            </p>
            <p>
              Scholarships are available for those who qualify. For more
              information visit{' '}
              <a href="https://artsmia.org/scholarship">
                https://artsmia.org/scholarship
              </a>{' '}
              before registering your student.
            </p>
          </div>
        ) : (
          <div>
            {priceLabel || 'Price per Class:'} ${product.data.visualPrice ||
              product.data.price}
            <br />
          </div>
        )}
      </p>
    </div>
  )
}
