import React from 'react'
import PrimaryNavData from './PrimaryNavData'
import SecondaryNavData from './SecondaryNavData'

class Nav extends React.Component {
  render() {
    return (
      <div>
        <nav className="nav">
          <ul className="primary-links uppercase">
            <PrimaryNavData />
            <li className="search">
              <form className="search-header">
                <input
                  type="search"
                  placeholder="Search"
                  className="search"
                  aria-label="Search site"
                  role="search"
                  id="search"
                />
              </form>
            </li>
          </ul>
          <div
            id="nav-items"
            onClick={this.props.onClick}
            className={this.props.isToggleOn ? 'close' : 'open-nav'}
          >
            <ul className="secondary-links uppercase">
              <SecondaryNavData />
            </ul>
          </div>
        </nav>
      </div>
    )
  }
}

export default Nav
