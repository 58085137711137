import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Moment from 'react-moment'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import DateTime from 'react-datetime/DateTime'
import TextField from 'material-ui/TextField'
import SelectField from 'material-ui/SelectField'
import MenuItem from 'material-ui/MenuItem'
import Checkbox from 'material-ui/Checkbox'
import SlidingScale from './sliding-scale'
import R from 'ramda'
import { ReservationInfo } from '../capacity'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import {
  addToCart,
  modifyProductData,
  triggerCart,
  triggerCompanionPage,
  addReservationInfo,
} from '../../modules/products'
import { AttributeChooser, toWorldTime } from '../../containers/cart'
import {
  getCapacity,
  getReservation,
  getAvailableBuckets,
} from '../../util/capacity'
import CartModel from '../../core/models/cart'
import ReasonableWPImage from '../reasonable-wp-image'
import InformationSelector from '../InformationSelector'
import DateDisplay from '../date-display'
import Tours from '../tours/Tours.js'

import { requireData } from '../../core/models/rules'

const ReactMarkdown = require('react-markdown')

const isSameDay = (d1, d2) => moment(d1).isSame(d2, 'day')

// Don't re-render the date picker unless dates have changed.
//
// It's expensive because it checks the validity of each date (~30) 
// by initializing a new Product and running all the rules, which gets
// expensive.
class DateTimePreventReRenders extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const {value, start_date_time: sdtProps, end_date_time} = this.props
    const {start_date_time: sdtState} = this.state || {}
    const start_date_time = sdtState || sdtProps

    const changes = !(isSameDay(value, nextProps.value)
    && isSameDay(start_date_time, nextProps.start_date_time)
    && isSameDay(end_date_time, nextProps.end_date_time))

    return changes
  }

  render() {
    const {isValidDate, onChange, value, defaultValue} = this.props

    return <DateTime
      closeOnSelect={true}
      timeFormat={false}
      isValidDate={isValidDate}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
    />
  }
}

class SingleProductSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = this.setInitialState(props)
  }

  setInitialState = (props) => {
    const {product} = props

    const futureOnSaleDate = product.data.checkSaleDates && new Date(product.data.onSaleDate) > Date.now()
    const saleDatePassed = product.data.checkSaleDates && new Date(product.data.offSaleDate || product.data.end_date_time) < Date.now()
    const previewOnly = product.data.previewOnly
      || futureOnSaleDate
      || saleDatePassed

    // Copy the adult and youth ticket quantity from the GA products in the cart
    // TODO generalize this for side-by-side companion products where the
    // parent subsequently triggers the Child product page
    let defaultItemQuantities = { adult: 1 }
    const hasGATickets = props.products.cart.filter(
      p => p.data.id === 'GEN-FY21-001'
      && p.data.selected_date === product.data.selected_date
    )
    if(product.data.id === 'EX2020-002' && hasGATickets && hasGATickets.length > 0) {
      defaultItemQuantities = hasGATickets.reduce((reduction, step) => {
        const type = step.data.ticket_type.toLowerCase()
        reduction[type] = reduction[type] || 0
        reduction[type] += 1
        return reduction
      }, {})
    }
    // END matching default item qualtity to parent of companion product

    // TODO why is this different from what's set on this.state?
    // const showPromoSelector = (!previewOnly || this.props.isVE || this.props.isDev) && product.data.promos && product.data.promos.length > 0
    return {
      open: !!props.startOpen,
      soldOut: false,
      initialProductHistoryLength: product.history.length,
      itemQuantities: defaultItemQuantities,
      perItemRequiredData: [],
      // This item is not up for sale yet. VE should be able to pre-sell if they
      // absolutely need to, web users are shown a "Not yet available" message
      // instead of quantity selection and "Add to Cart"
      futureOnSaleDate, 
      saleDatePassed,
      previewOnly: previewOnly,
      showPromoSelector: !previewOnly && product.data.promos && product.data.promos.length > 0,
    }
  }

  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = ({ addToCartClicked }) => {
    const { onClose, triggerCart, triggerCompanionPage, product, isVE } = this.props
    const nextPage = product.data.triggerCompanionPage

    if(nextPage && triggerCompanionPage && addToCartClicked)
      return triggerCompanionPage(nextPage)

    if (
      !isVE ||
      product.data.unlisted ||
      product.data.directToCart ||
      product.data.strictCapacity
    ) {
      if (addToCartClicked) {
        return triggerCart && triggerCart()
      }
    }

    this.setState({ open: false })

    // Reset product data when the dialog is closed - otherwise it's remembered
    // forever and gets in the way of VE operators
    try {
      this.props.product.data = this.props.product.history[
        this.state.initialProductHistoryLength - 1
      ]
    } catch (e) {
      console.error('problem resetting product data')
    }

    onClose && onClose()
  }

  async componentDidMount() {
    this.getRemainingCapacity()
    this.shouldDisableAddToCartButton()
  }

  getRemainingCapacity = async instanceId => {
    const product = this.props.product
    const {
      id: productId,
      trackCapacity,
      trackInstanceCapacity,
      defaultCapacity,
      checkCapacity,
      strictCapacity,
      simpleInstanceTracking,
    } = product.data

    if (trackInstanceCapacity && (!checkCapacity || instanceId === undefined))
      return

    const id = productId
    const date = instanceId && instanceId.replace(/(.*)(-.*?)$/, '$1')
    const time = instanceId && instanceId.replace(/(.*)-(.*?)$/, '$2')

    if (trackCapacity) {
      const capacityChecker = simpleInstanceTracking
        ? getCapacity.bind(this, `${id}-${checkCapacity}`)
        : getCapacity.bind(
            this,
            id,
            date,
            time,
            this.props.products.reservationInfo
          )

      try {
        const data = await capacityChecker()
        const {
          capacityRemaining: response,
          capacityInfo,
          reservationInfo: freshReservation,
        } = data
        let useBucket = 'general_admission'

        const operator = this.props.account.operator
        // If we are using the tours login, enable selling tickets at the 10am timeslot
        if (operator && operator.location.match(/tours/i)) {
          useBucket = 'tours'
        }

        const reservationInfo =
          this.props.products.reservationInfo || freshReservation
        this.setState({ reservationInfo })

        const dCapacity = defaultCapacity || 15
        // const capacityRemaining =
        //   capacityObjType === 'undefined' ? dCapacity : response
        const capacityObjType = typeof response
        let capacityRemaining = Math.max(
          0,
          capacityObjType === 'undefined'
            ? dCapacity
            : capacityObjType === 'object'
              ? response['capacity'] || response[useBucket]
              : response
        )

        if (window.DEBUG)
          console.info('SPS getRemainingCapacity', {
            capacityRemaining,
            dCapacity,
            response,
            capacityInfo,
            useBucket,
          })

        if (!R.isNil(capacityRemaining)) {
          const soldOut =
            product.data.soldOut ||
            (reservationInfo && strictCapacity
              ? false
              : capacityRemaining < 1)

          this.setState({ soldOut, capacityRemaining })
        }

        if (capacityInfo) {
          const soldOutTimes = R.pickBy((value, key) => {
            if(product.data.id === 'PCHTOUR') useBucket = 'current_capacity'
            let isSoldOut = !R.isNil(value[useBucket]) && value[useBucket] < 1

            // 2020-07-23 hotfix for 4:30pm tickets
            if (key === '1630' && product.data.id === 'GEN-FY21-001') {
              if (true || window.DEBUG)
                console.info(
                  'resetting `isSoldOut` for 4:30 slots',
                  {
                    beforeChange: isSoldOut,
                    isVE: this.props.isVE,
                    afterChange: isSoldOut || !this.props.isVE,
                  }
                )
              isSoldOut = isSoldOut || !this.props.isVE
            }

            // const availableBuckets = getAvailableBuckets(date, operator, value)
            const alternateBucket = getAvailableBuckets(
              date,
              operator,
              value,
              this.props.account.user
            )

            // console.info('isSoldOut', {
            //   value, key, useBucket, alternateBucket,
            //   isSoldOut, operator, date, time,
            // })

            if (alternateBucket && useBucket !== alternateBucket) {
              // console.info('overriding default bucket', {useBucket, alternateBucket})
              // debugger
              isSoldOut =
                !R.isNil(value[alternateBucket]) && value[alternateBucket] < 1
              useBucket = alternateBucket
              this.setState({capacityRemaining: value[alternateBucket]})
            }

            return isSoldOut
          }, capacityInfo)

          this.setState({
            soldOutTimes: R.keys(soldOutTimes),
            capacityInfo,
            useBucket,
          })
        }

        return capacityRemaining
      } catch (error) {
        console.error('getCapacity', { error })
        this.setState({
          soldOut: true,
          errorMessage:
            'Error: Could not access remaining capacity information',
        })
      }
    }
  }

  async componentDidUpdate(nextProps, nextState) {
    const {
      capacityRemaining,
      error,
      quantityDesiredExceedsCapacity,
    } = this.state

    if (quantityDesiredExceedsCapacity && !error)
      this.setState({ error: `Only ${capacityRemaining} spots remaining` })

    const product = this.props.product
    const { checkCapacity } = product.data

    if (this.state.checkCapacity !== checkCapacity) {
      if (product && checkCapacity) {
        const capacityRemaining = await this.getRemainingCapacity(checkCapacity)

        this.setState({ checkCapacity, capacityRemaining })
      }
    }

    this.shouldDisableAddToCartButton()
  }

  validDate = function(current, selected) {
    const data = this.props.product.data
    const {start_date_time, end_date_time} = data
    const startDate = moment(this.state.startDate || this.state.start_date_time || start_date_time)
    const endDate = moment(end_date_time)

    const pAtDate = this.props.product
      .clone()
      .withData({ selected_date: current._d })
    pAtDate.valid()
    const dateError = pAtDate.data.errors['selectedDate'] || pAtDate.data.errors['museumClosed']

    const { validDaysOfWeek } = pAtDate.data
    const whitelistDayOfWeek = validDaysOfWeek
      ? validDaysOfWeek.indexOf(current.day()) > -1
      : true

    const isPCHTour = data.id === 'PCHTOUR'
    const adjustedStartDate =
      this.props.isVE && isPCHTour
      ? moment(startDate).subtract(39, 'days')
      : startDate

    // for dated companion products, check that the companion is still available
    // at the parent's selected_date
    const companionProduct = this.state.companionProduct
    let validCompanion = true
    if(companionProduct) {
      const companionEndDate = companionProduct.data.end_date_time

      validCompanion = companionEndDate ? moment(current).isBefore(companionEndDate) : true
    }

    const conditions = [
      current.day() !== 1, // never on monday
      current.day() !== 2, // tuesday and wednesday closed…
      current.day() !== 3, // …during reduced covid hours
      current.isBetween(adjustedStartDate, endDate, 'day', '[]'), // between the (adjusted) product start date and end date
      current.isSameOrAfter(
        this.props.isVE && isPCHTour ? adjustedStartDate : new Date(),
        'day',
        '[]'
      ), // for PCH tours, allow VE to sell in an adjusted window to be able to retroactively process cash taken at the house
      !dateError,
      whitelistDayOfWeek, // this probably superseeds the first 'not monday' condition?
      validCompanion
    ]

    return conditions.every(c => c)
  }

  render() {
    const { product } = this.props
    const { soldOut, itemQuantities } = this.state
    const data = product.data
    const {
      start_date_time,
      end_date_time,
      image,
      title,
    } = product.data

    const startDate = moment(this.state.startDate || this.state.start_date_time || start_date_time)
    const defaultEventDate = startDate.isSameOrAfter(new Date())
      ? startDate
      : new Date()

    // if (data.title == 'Donate') return <Donation />
    if (data.title === 'Donate') return <span />

    var validDate = this.validDate

    function ChildTicket(props) {
      var entitlements = props.entitlements
      var max_per_order = props.max

      var entitlementsByType = R.uniqBy(R.prop('user_type'), entitlements || [])
      var entitlementList = entitlementsByType.map(
        entitlement => {
          const { user_type } = entitlement
          if (
            user_type === 'child' ||
            user_type === 'youth' ||
            user_type === 'student'
          ) {
            return (
              <TextField
                key={entitlement.entitlement_id}
                type="number"
                min="0"
                max={max_per_order}
                floatingLabelText={entitlement.entitlement_name}
                floatingLabelFixed={true}
                onChange={(event, quantity) => {
                  props.setTypeAndQuantity({ type: user_type, quantity })
                }}
                value={props.itemQuantities && props.itemQuantities[user_type]}
              />
            )
          }
          
          const isLoggedIn = props.account && props.account.identified
          if (entitlement.user_type === 'member' && !props.isMember) {
            return (
              <div key="member_entitlement">
                <p>
                  This item has a My Mia discount available.{' '}
                  {isLoggedIn || <span><Link to="/account">Login</Link> to receive your discount.</span>}
                </p>
              </div>
            )
          }
          if (entitlement.user_type === 'affinity') {
            const userAffiniityQualifies = R.contains(
              entitlement.affinity_type,
              props.memberAffinities || []
            )

            return (
              <div key={`affinity_entitlement-${entitlement.affinity_type}`}>
                <p>
                  {!userAffiniityQualifies ? (
                    <span>
                      This item has a an Affinity discount available.{' '}
                      {isLoggedIn || <span><Link to="/account">Login</Link> to receive your discount.</span>}
                    </span>
                  ) : (
                    <span />
                  )}
                </p>
              </div>
            )
          }
        }
      )

      return <div>{entitlementList}</div>
    }
    const { errors, errorMessage } = product.data
    const errorMessages = (errors
      ? R.values(
          R.mapObjIndexed((error, key) => {
            // IDEA: these shouldn't be hardcoded into the product rules,
            // but sprinkled in when an error is shown to a user in the interface
            const errorClarifications = {
              selectedDate: 'Select a date to visit above.',
              selectedTime: 'Choose a time to visit.',
            }
            const clarifier = errorClarifications[key]
            return clarifier ? error + ' ' + clarifier : error
          }, errors)
        )
      : []
    ).concat([errorMessage, this.state.errorMessage].filter(message => message))

    const remainingCapacityMessage = (
      <RemainingCapacityMessage
        capacityRemaining={this.state.capacityRemaining}
        product={this.state.product || this.props.product}
        isVE={this.props.isVE}
        reservationInfo={this.state.reservationInfo}
        soldOut={soldOut}
        event_logistics={data.event_logistics}
      />
    )

    // TODO is this needed?
    // Probably, to run the rules on the product before grabbing the price
    // But look into it
    this.buildCart()

    const summary = data.summary ? (
      <ReactMarkdown source={data.summary} />
    ) : (
      <span />
    )

    const details = data.details ? (
      <ReactMarkdown source={data.details} />
    ) : (
      <span />
    )

    const { capacityInfo } = this.state

    const perCartLimits = [
      this.state.capacityRemaining
        ? Math.max(this.state.capacityRemaining, 1)
        : undefined,
      data.promo && data.promo.max_per_order,
      data.max_per_order,
    ]
    const maxPerFudgeFactor = this.props.isVE ? 2 : 1
    const maxPerCart =
      Math.min(...R.filter(R.identity, perCartLimits)) + maxPerFudgeFactor
    // TODO I'm upping this a few above the enforced maximum so that the error message
    // shows to a user when they attempt to add more, which is kind of nonsense
    // but also kind of makes sense?
    // Clicking the button and seeing nothing happen (the quantity number doesn't
    // increase) is frustrating. It's more satisfying for me to see the error appear…
    //
    // limiting it to +1 gives VE some leeway while preventing anyone from overdoing it?

    // Show enables showing special UI to the tours folks.
    // Currently gated for dev only until we know it's ready for launch
    const showOrganizedTourUI =
      this.props.isVE &&
      this.props.account.operator.location.match(/tours/i) &&
      (data.product_type === 'exhibition')

    if(showOrganizedTourUI && !this.state.ticketTimes) {
      // convert the product to 'timed' so tours
      // can choose a time and process their tour requests
      // …
      // this creates a fake array of times and saves it in state,
      // so that when the time drop-down is shown we can show it based on
      // `showOrganizedTourUI` and pass in the times created here.
      // This needs to happen because I can't think of how to simply
      // add the `timedTicketWithInterval` to the product and re-render
      // causing it to think it has times again.
      //
      // And this might actually be better than doing that?
      //
      // TODO de-dupe the code that creates an array of times below with
      // core/models/rules.js#timedTicketWithInterval

      let interval = 15
      let availableHours =
        '10 am_11 am_12 pm_1 pm_2 pm_3 pm_4 pm_5 pm_6 pm_7 pm_8 pm'
      // for now we only have 10 an 15 minute intervals…
      let availableTimes = interval === 10 ? '00 10 20 30 40 50' : '00 15 30 45'

      const times = R.flatten(
        availableHours.split('_').map(hour => {
          return availableTimes.split(' ').map(minute => {
            return hour.replace(/(\D+)/, `:${minute}$1`)
          })
        })
      )

      this.setState({
        ticketTimes: times,
        start_date_time: new Date('2019-05-23'),
      })
    }


    /* restrict items for sale when they have sold out or shouldn't be
     * up for sale yet (`previewOnly`)
     * unless the operating user is VE or in a dev environment
     */
    const restrictSale = (this.state.soldOut || this.state.previewOnly || data.preventSale)
      && !(this.props.isVE || this.props.isDev)
    // then based on that, decide whether to show different UI elements
    const showDatePicker = !restrictSale && data.type !== 'onetime'
    const showQuantityAndAddToCart = !(restrictSale || 
      ((data.type === 'timed' && (!data.selected_date || !data.selected_time)) ||
      (data.type === 'ongoing' && !data.selected_date)))

    const addToCartLabel = this.state.companionProduct
      ? 'Continue'
      : 'Add to Cart'
    const addToCartButton = product.data.preventSale || <FlatButton
      label={addToCartLabel}
      primary={true}
      disabled={this.state.shouldDisableAddToCartButton}
      onClick={this.addToCartClicked.bind(this)}
    />
    const showEarlyAddToCartButton = data.id === 'EX2020-002'
  
    return (
      <div className="col-3 col cell-container" onClick={this.handleOpen}>
        <div className="small-article" style={{ cursor: 'pointer' }}>
          {image && <ReasonableWPImage src={image} />}
          <h3>{data.title}</h3>
          <DateDisplay product={product} />
          <div>{summary}</div>
        </div>
        {this.state.open && (
          <Dialog
            modal={false}
            open={this.state.open}
            onRequestClose={this.handleClose.bind(this)}
            autoScrollBodyContent={true}
            contentClassName="customWidth"
          >
            <div style={{ color: '#232323' }} className="row two-thirds">
              <div
                style={{ color: '#232323' }}
                className="col-9 col cell-container"
              >
                <h2>{data.title}</h2>
                <time className="uppercase">
                  <DateDisplay product={product} />
                </time>
                <p>{data.location}</p>

                <PricingMatrix
                  product={product}
                  expanded={!this.props.isVE}
                  handleToggle={() => this.setState({expandPricing: !this.state.expandPricing})}
                  style={{marginBottom: '0.7em'}}
                />

                {remainingCapacityMessage}

                <div>{summary}</div>

                {this.state.showPromoSelector && <PromoCodeSelector
                  product={product}
                  updateCode={event => {
                    product.withData({
                      selected_promo_code: event.target.value,
                    })
                    product.applyRules()
                    this.setState({
                      productPrice: product.price,
                      startDate: product.data.start_date_time,
                    }) // trick component to update
                  }}
                />}
              </div>

              {showEarlyAddToCartButton ? addToCartButton : null}
            </div>
            <div style={{ color: '#232323' }} className="row two-thirds">
              {showDatePicker && (
                <div className="col-9 col cell-container">
                  <hr />
                  <h3>Select a date to visit.</h3>
                  <DateTimePreventReRenders
                    closeOnSelect={true}
                    timeFormat={false}
                    isValidDate={validDate.bind(this)}
                    onChange={this.setProductData.bind(this)}
                    value={product.data.selected_date || defaultEventDate}
                    defaultValue={defaultEventDate}
                    product={product}
                    start_date_time={this.state.start_date_time || product.data.start_date_time}
                    end_date_time={product.data.end_date_time}
                    open={true}
                  />
                  <QuickSentenceDatePicker
                    daysAhead={2}
                    selected={product.data.selected_date}
                    validDate={validDate.bind(this)}
                    setProductData={this.setProductData.bind(this)}
                    startDate={product.data.start_date_time}
                  />
                </div>
              )}
            </div>
            <div style={{ color: '#232323' }} className="row two-thirds">
              {((data.type === 'timed' || (data.type === 'ongoing' && showOrganizedTourUI))  ||
                new Set(data.dataNeeded).has('selected_time')) &&
                data.selected_date && (
                  <div className="col-9 col cell-container">
                    <h3>Choose your time slot</h3>
                    <AttributeChooser
                      choices={
                        product.data.availableTimes || product.data.require_time || this.state.ticketTimes
                      }
                      disabledChoices={this.state.soldOutTimes}
                      onChange={event => {
                        this.setProductData.bind(this)(
                          event.target.value,
                          'selected_time'
                        )
                      }}
                      choice={product.data.selected_time}
                      extraInfo={(time, choice) => {
                        // Selecting where the capacity comes from is a bit ugly.
                        // There are three possibilities (but really just two)
                        // 0. simple capacity tracking -
                        // this extra info for time selection never shows
                        // 1. capacity tracked by `<id>-<time>` -
                        // only show the capacity for the currently chosen time
                        // 2. capacity tracked by `{ <times: >, capacity: }` -
                        // look up the selected time, if not present use the default.
                        const defaultCapacity =
                          capacityInfo &&
                          (capacityInfo.capacity ||
                            (time === choice && capacityInfo.current_capacity))
                        const worldTime = toWorldTime(time).replace(':', '')
                        const timeCapacityInfo =
                          capacityInfo &&
                          (capacityInfo[worldTime] ||
                            capacityInfo[toWorldTime(time)])
                        // ^ ask for world time both with and without `:` delimiter
                        // new capacity system used `hhmm` and old system uses `hh:mm`

                        // 2018-10-17 hotfix for 10am tickets
                        const bandAidTenAM =
                          worldTime === '1000' && timeCapacityInfo
                        if (false && bandAidTenAM) { // don't disable 1000am timeslot anymore, 2020-06-25
                          // overwrite `general_admission` for these times?
                          try {
                            timeCapacityInfo['general_admission'] = 0
                          } catch (e) {
                            console.error('error resetting 10am time - sunday?')
                            debugger
                          }
                        }

                        const remaining = timeCapacityInfo
                          ? [
                              timeCapacityInfo.current_capacity,
                              timeCapacityInfo['general_admission'],
                              product.data.id === 'EX2018-001'
                                ? 0
                                : timeCapacityInfo.capacity,
                            ].find(num => typeof num !== 'undefined')
                          : defaultCapacity || 0

                        const bucketCapacityString =
                          timeCapacityInfo &&
                          JSON.stringify(timeCapacityInfo)
                            .replace(/,/g, '; ')
                            .replace(/:/g, ': ')
                            .replace(/{|"|}/g, '')

                        // VE sees the full capacity, plus what's in
                        // different buckets
                        //
                        // other users see when the capacity is dwindling
                        // or sold out
                        const VECapacityMessage = bucketCapacityString
                          ? remaining + ' [' + bucketCapacityString + ']'
                          : remaining
                        const capacityMessage = this.props.isVE
                          ? VECapacityMessage
                          : remaining < 11 && remaining > 0
                            ? 'Few tickets remaining'
                            : remaining <= 0 && 'Sold Out'

                        return capacityMessage ? ' - ' + capacityMessage : ''
                      }}
                    />
                    <InfoMessages messages={product.data.messages} />
                    {errorMessages.length > 0 &&
                      errorMessages
                        .filter(error =>
                          error.match(/date|time|closed|advance reservation/i)
                        )
                        .map((error, index) => (
                          <p
                            key={error + index}
                            style={{ clear: 'both', color: 'red' }}
                          >
                            {error}
                          </p>
                        ))}
                    <VisualBreak />
                  </div>
                )}

              {showOrganizedTourUI && (
                <div>
                  <hr />
                  <h2>Organized Tour</h2>
                  {data.selected_date && data.selected_time ? (
                    <Tours
                      closeDialog={this.handleClose.bind(this, {})}
                      selected_date={data.selected_date}
                      selected_time={data.selected_time}
                      product_data={data}
                    />
                  ) : (
                    <p>Choose a date and time above to process a tour</p>
                  )}
                  <hr />
                </div>
              )}
            </div>
            <div style={{ color: '#232323' }} className="row two-thirds">
              {this.state.previewOnly && <>
                <hr />
                <p>
                  {this.state.futureOnSaleDate && <>
                    Coming Soon! {' '}
                    Available starting {' '}
                    <Moment format={'dddd, MMMM D'}>{data.onSaleDate}</Moment>.
                  </>}
                  {this.state.saleDatePassed && <>
                    As of {' '}
                    <Moment format={'dddd, MMMM D, YYYY'}>{data.offSaleDate || data.end_date_time}</Moment> {' '}
                    this is no longer available. {' '}
                    Please call 612-870-3000 for more information.
                  </>}
                </p>
                {(this.props.isVE || this.props.isDev) && <hr />}
              </>}
            </div>
            <div style={{ color: '#232323' }} className="row two-thirds">
              {showQuantityAndAddToCart && <>
                  <div
                    style={{ color: '#232323' }}
                    className="col-9 col cell-container"
                  >
                    {data.oneAtATime || <div><h3>How many would you like?</h3>
                    <TextField
                      type="number"
                      min={(data.promo && data.promo.min_per_order) || 0}
                      max={maxPerCart}
                      floatingLabelText={`${
                        data.id === 'GEN-FY21-001-ADM' ? 'General Admission' : 'Adult'
                      } Tickets`}
                      onChange={(event, quantity) =>
                        this.setTypeAndQuantity.call(this, {
                          type: 'adult',
                          quantity,
                        })
                      }
                      value={itemQuantities && itemQuantities['adult']}
                    />
                    <ChildTicket
                      entitlements={data.entitlements}
                      max={data.max_per_order}
                      setTypeAndQuantity={this.setTypeAndQuantity.bind(this)}
                      itemQuantities={itemQuantities}
                      {...this.props}
                    /></div>}

                    {data.showSlidingScale && (
                      <SlidingScale
                        handleDiscount={this.handleDiscount.bind(this)}
                        product={this.props.product}
                        slidingScale={this.state.priceOverride}
                        updateData={this.setLineItemData.bind(this)}
                        isVE={this.props.isVE}
                        priceLabel={'Price per:'}
                      />
                    )}

                    {data.information && (
                      <InformationSelector
                        updateData={this.setInformation.bind(this)}
                        product={this.props.product}
                        itemQuantities={itemQuantities}
                        header="Required Information"
                      />
                    )}
                    {true && (
                      <RequiredDataSelector
                        updateData={this.setLineItemData.bind(this)}
                        product={this.props.product}
                        itemQuantities={itemQuantities}
                      />
                    )}

                    {this.props.isVE && (
                      <PriceOverrideSelector
                        handleDiscount={this.handleDiscount.bind(this)}
                        product={this.props.product}
                        priceOverride={this.state.priceOverride}
                        update={this.setProductData.bind(this)}
                      />
                    )}

                    <VisualBreak />
                    <div className="buttons">
                      {product.data.unlisted || (
                        <FlatButton
                          label="cancel"
                          primary={false}
                          onClick={this.handleClose.bind(this)}
                        />
                      )}
                      {addToCartButton}
                      {itemQuantities &&
                        errorMessages.length > 0 &&
                        errorMessages.map((error, index) => (
                          <p
                            key={error + index}
                            style={{ clear: 'both', color: 'red' }}
                          >
                            {error}
                          </p>
                        ))}
                      <ReservationInfo
                        onExpire={this.handleClose.bind(this, {})}
                      />
                    </div>
                  </div>
                </>}

              {/* TODO componentize error messaging  - this is in three different places now */}
              {this.state.soldOut &&
                itemQuantities &&
                errorMessages.length > 0 &&
                errorMessages.map((error, index) => (
                  <p
                    key={error + index}
                    style={{ clear: 'both', color: 'red' }}
                  >
                    {error}
                  </p>
                ))}

              {product.data.companionProducts && (
                <div>
                  <hr />
                  {product.data.companionProducts &&
                    product.data.companionProducts.map(compProduct => {
                      return (
                        <CompanionProductChooser
                          key={compProduct.data.id}
                          product={compProduct}
                        chosenProduct={this.state.companionProduct}
                        parentProduct={product}
                          updateProduct={companionProduct =>
                            this.setState({ companionProduct })
                          }
                        />
                      )
                    })}
                </div>
              )}
              {false && data.myMiaDays && (
                <div>
                  <hr />
                  <div>
                    <h5>My Mia Days</h5>
                    <p>
                      My Mia members see "{title}" free on My Mia Days: October
                      28 - November 3.
                    </p>
                    <ul>
                      {false &&
                        data.myMiaDays.map(date => {
                          const product =
                            this.state.product || this.props.product
                          const year = new Date(
                            product.data.start_date_time
                          ).getFullYear()
                          const dateString = date + `/${year}`
                          // prettier-ignore
                          const dateStyle =
                      product.data.selected_date &&
                      product.data.selected_date.toISOString().split('T')[0] ===
                        new Date(dateString).toISOString().split('T')[0]
                        ? { textDecoration: 'underline' }
                        : {}

                          return (
                            <li
                              key={dateString}
                              onClick={this.setProductData.bind(
                                this,
                                new Date(dateString).setHours(12),
                                'selected_date'
                              )}
                              style={{cursor: 'pointer', ...dateStyle}}
                            >
                              {dateString}
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                </div>
              )}
              {data.hasMyMiaTour &&
                product.data.selected_date && (
                  <div>
                    <h5>Public Tours</h5>
                    <p>
                      Tours are complimentary, but reservations are required.
                      Listening devices provided. Tours occur Tuesday-Friday at
                      12:00pm, as well as Thursday and Friday at 6:00pm. Tours
                      begin November 12, 2018 and end March 24, 2019. Tours
                      unavailable December 17, 2018 – January 14, 2019.
                    </p>

                    <MyMiaTourChooser
                      product={product}
                      chosenTour={this.state.tour}
                      updateTour={tour => this.setState({ tour })}
                      capacityInfo={this.state.capacityInfo}
                    />
                  </div>
                )}
              <hr />

              {image && (
                <div className="col-3 col cell-container">
                  <img className="cell-image" src={image} alt="" />
                </div>
              )}
              <div>{details}</div>

              <p title={`${this.state.capacityRemaining} / ${product.data.initialCapacity}`}>
                <Link to={`/products/${data.slug}`}>permalink</Link>
              </p>
            </div>
          </Dialog>
        )}
      </div>
    )
  }

  // shouldEnableAddToCart
  shouldDisableAddToCartButton() {
    const { product } = this.props
    const {
      itemQuantities,
      soldOut,
      capacityRemaining,
      quantityDesiredExceedsCapacity,
      reservationInfo,
      companionProduct,
    } = this.state

    const quantitySelected = R.sum(R.values(itemQuantities))
    // TODO - for tours, we can purchase multiple times in one go, in which case
    // quantitySelected isn't directly connected to the quantity remaining.
    //
    // i.e. I'm purchasing a 12pm and 1pm tour. If both tours have 1 slot left,
    // this is a valid purchase, but as this stands 2 is subtracted from each instance's
    // capacity and the button turns off…
    const nextQ = quantitySelected > capacityRemaining && !reservationInfo

    if (capacityRemaining && nextQ !== quantityDesiredExceedsCapacity)
      this.setState({
        quantityDesiredExceedsCapacity: nextQ,
        errorMessage:
          nextQ && `Sorry, what you've selected is not currently available.`,
      })

    const cart = this.buildCart()
    const validCart = cart.validate()
    const cartErrors = R.mergeAll(R.values(cart.errors))
    // also check if we require information, and make sure it's provided
    const needsProvidedInformation = (product.data.information && !product.data.providedInformation)

    const chosenDateValid = product.data.type === 'onetime' || this.validDate(moment(product.data.selected_date))

    // VE can always add to cart?
    // TODO - this is probably too lenient. Allow VE to circumvent some of this, but not all?
    const shouldDisableAddToCartButton = this.props.isVE
      ? needsProvidedInformation
      : soldOut ||
        nextQ ||
        // disable if product is not valid
        !(product && product.valid(cart)) ||
        // and until some quantity is selected
        !(
          itemQuantities &&
          R.values(itemQuantities).find(quantity => quantity > 0)
        ) ||
        needsProvidedInformation ||
        (companionProduct && !chosenDateValid)

    const shouldUpdateState =
      this.state.shouldDisableAddToCartButton !==
        shouldDisableAddToCartButton ||
      this.state.validCart !== validCart ||
      !R.equals(this.state.cartErrors, cartErrors)

    if (shouldUpdateState)
      this.setState({
        shouldDisableAddToCartButton,
        validCart,
        cartErrors: cartErrors,
      })

    return shouldDisableAddToCartButton
  }

  // build a cart. We want to look at all the products already in the cart
  // as well as this theoretical product we're about to add, EXCEPT
  // when the 'add to cart' button has just been pressed - because then this
  // theoretical product will have been added to the cart and thus the cart
  // would have an inaccurate quantity (n+1 from the n that have now been added
  // to the cart and the 1 that's tacked on).
  buildCart() {
    const { product, products } = this.props
    const cartHasSameAsCurrentProduct = !!products.cart.find(
      p => p.data.id === product.data.id
    )
    // const cartProdExacSame = !!products.cart.find(p => p === product)
    const { itemQuantities } = this.state
    const quantitySelected = R.sum(R.values(itemQuantities))

    const cart = new CartModel(
      this.state.addingToCart || cartHasSameAsCurrentProduct
        ? products.cart
        : // : products.cart.concat(new Array(quantitySelected).fill(product)),
          products.cart.concat(R.times(() => product, quantitySelected)),
      this.props.isMember && this.props.account.user
    )
    cart.validate()

    return cart
  }

  setProductData(data, fieldName) {
    const { product } = this.props

    if (data && data._isAMomentObject) {
      const nextProduct = product.withData({
        selected_date: data.hour(12)._d,
      })
      nextProduct.applyRules()
      this.setState({ product: nextProduct })
    } else if (fieldName === 'selected_date') {
      const nextProduct = product.withData({
        selected_date: new Date(data),
      })
      nextProduct.applyRules()
      this.setState({ product: nextProduct })
    } else if (fieldName === 'selected_time') {
      const nextProduct = product.withData({
        selected_time: data,
      })
      nextProduct.applyRules()
      this.setState({ product: nextProduct })
    } else {
      this.setState(data || {})
    }
  }

  /* TODO
   * validate choice againt capacity for an event based on attributes?
   * tour - only so many per date time (PCH, My Mia)
   * special event - 10 VIP / 50 regular or something like that…
   *
   * How to make this work? Current PCH validation is janky
   */
  setLineItemData(fieldName, quantity) {
    function fn(event) {
      const { product } = this.props
      const { perItemRequiredData } = this.state

      // Material UI's `Select > MenuItem`s are just divs??!?>?!
      // so `value` needs to check down to `innerText`
      const value = event._isAMomentObject
        ? event._d
        : event.target && (event.target.value || event.target.innerText)

      const thisItemData = {
        [fieldName]: value,
      }
      const nextProduct = product.withData(thisItemData)

      const index = quantity
      const nextPerItemData = perItemRequiredData[index]
        ? { ...perItemRequiredData[index], ...thisItemData }
        : thisItemData

      const cart = this.props.product.cart
      const user = this.props.account.user
      nextProduct.applyRules(cart, user)

      const nextData = [
        ...perItemRequiredData.slice(0, index),
        nextPerItemData,
        ...perItemRequiredData.slice(index + 1),
      ]

      this.setState({ product: nextProduct, perItemRequiredData: nextData })
    }

    return fn.bind(this)
  }

  setInformation(nextInfo) {
    const { product } = this.props
    const nextProduct = product.withData({
      providedInformation: { ...product.data.providedInformation, ...nextInfo },
    })
    this.setState({ product: nextProduct })
  }

  async addToCartClicked() {
    const { product } = this.props
    const { itemQuantities, tour, companionProduct } = this.state
    let { perItemRequiredData } = this.state
    let error

    this.setState({
      addingToCart: true,
    })

    const itemsAddedToCartP = R.toPairs(itemQuantities).map(
      async ([type, quantity], qtyIndex) => {
        if(parseInt(quantity) <= 0) return
        let reservationInfo = this.props.products.reservationInfo
        if (product.data.strictCapacity) {
          // make the reservation call here and save the returned reservation ID
          // in a cookie or in localStorage?
          if (!reservationInfo) {
            try {
              reservationInfo = await getReservation(
                product.data.id,
                '20180622',
                quantity
              )
            } catch (_error) {
              console.error('getReservation', { _error })
              error = "Error: Couldn't contact capacity server."
              return
            }

            if (reservationInfo.error) {
              // TODO if we can't get a reservation, ie this product is sold out,
              // we can't add this product to the cart.
              // Show an error message?
              error = `${
                product.data.title
              } could not be added to your cart - it has sold out. Tickets might become available in the next 10 minutes.`
              return
            }
            this.props.addReservationInfo({ info: reservationInfo })
          } else {
            // we have a reservation - set an error that the reservation must be
            // cleared before adding any more to the cart
            // this isn't perfect but it's what will work for now.
            // The problem with adding a second reservation later is juggling reservations
            // with multiple times - if one expires in 10 minutes and the other in
            // 3 minutes how to cleanly communicate that?
            error = `There's already a reservation for this product in your cart. Clear the cart to change your desired quantity.`
            return
          }

          // save reservationInfo to the product data
          // …so it can be passed through to the API `/order` call
          //
          // This breaks if a user adds 1 to their cart, then goes back and adds another
          // Each of those line items re-use the first `queue_:id` TODO
          perItemRequiredData = reservationInfo.reservations.map(r => ({
            reservationInfo: r,
          }))
          try {
            const existingPIDorEmptyArray =
              perItemRequiredData && perItemRequiredData.length > 0
                ? perItemRequiredData
                : reservationInfo.reservations.map(() => ({}))
            perItemRequiredData = R.zip(
              existingPIDorEmptyArray,
              reservationInfo.reservations
            )
              .map(R.mergeAll)
              .map(r => ({ reservationInfo: r }))
          } catch (e) {
            perItemRequiredData = reservationInfo.reservations.map(r => ({
              reservationInfo: r,
            }))
          }
        }

        // This is limited to Exhibitions with connected tours.
        // It doesn't apply to `companionProducts`! For that, see further
        // down
        const hasValidTour = tour && tour.valid()
        const tourChosenForInvalidDay = tour && tour.data.errors.museumClosed
        if (tour && hasValidTour && !tourChosenForInvalidDay)
          doNTimes(quantity, () => this.props.addToCart(tour))

        // TODO this isn't great, but re-write any exhibition tickets to match the
        // selected tour time.
        // Figuring this out is tricker than I have time for, but I think it's something to
        // do with the tour.valid() being called independently of any products in the cart,
        // so the validation rule can't verify that the times match?

        if (hasValidTour) {
          product.withData({
            selected_time: tour.data.selected_time,
          })
        }
        // END Exhibitions with connected tours

        // If this product has a certain compaion product, flow the user
        // on to that product's page when `addToCart` is clicked.
        // For now, this is only needed on WHWLYS. Expand the functionality?
        if (companionProduct && companionProduct?.data?.id === 'DONATE') {
          product.withData({
            triggerCompanionPage: `/products/${companionProduct.data.slug}`,
          })
        }

        const productsWithBuckets = [
          'EX2019-001', 'GEN-FY21-001'
        ]
        let useBucket = productsWithBuckets.indexOf(product.data.id) > -1
        if (useBucket) {
          const chosenTime = toWorldTime(product.data.selected_time)
          const capacityInfo = this.state.capacityInfo
          const capacityAtSelectedTime =
            capacityInfo[chosenTime] ||
            capacityInfo[chosenTime.replace(':', '')]

          useBucket = getAvailableBuckets(
            product.data.selected_date.toISOString().split('T')[0],
            this.props.account.operator,
            capacityAtSelectedTime,
            this.props.account.user
          )
        }

        const variant = (product.getDerivative(type) || product).withData({
          originalPrice: product.data.price,
          ticket_type: product.data.ticket_type || 'Adult',
          useBucket,
        })

        const shouldApplyPerItemData =
          perItemRequiredData &&
          perItemRequiredData.length > 0 &&
          product.data.id !== 'AIBPREV20180425-001' &&
          product.data.id !== 'AIBPATDON20180425-001' &&
          product.data.id !== 'AIBPREV20190410-001' &&
          product.data.id !== 'AIBPREV20190410-002' &&
          product.data.id !== 'AIBPATDON20190410-001'

        if (
          shouldApplyPerItemData &&
          perItemRequiredData[0].selected_organization_name
        ) {
          // Fill in any unfilled perItem selected_org data with the first value?
          const newPerItemRequiredData = perItemRequiredData
            .concat([{}, {}, {}, {}, {}, {}])
            .slice(0, parseInt(quantity)) // fill out the array to have as many empty entries as `quantity`
            .map(data => {
              data.selected_organization_name =
                data.selected_organization_name ||
                perItemRequiredData[0].selected_organization_name
              return data
            })
          // and fill those in with the first value if later values are missing

          perItemRequiredData = newPerItemRequiredData
        }

        shouldApplyPerItemData
          ? perItemRequiredData.map(itemData =>
              this.props.addToCart(
                variant.clone().withData({
                  ...itemData,
                  ticket_type: product.data.ticket_type || 'Adult',
                })
              )
            )
          : doNTimes(quantity, () => this.props.addToCart(variant.clone()))

        if (companionProduct) {
          if(!companionProduct.data.id === 'DONATE') {
            companionProduct.applyRules()
            if(companionProduct.data.dataNeeded?.indexOf('selected_date') > -1) {
              companionProduct.withData({selected_date: product.data.selected_date})
            }
          }

          if(companionProduct.data.id === 'EX2020-002' || companionProduct.data.id === 'DONATE') {
            // Rely on `product.data.triggerCompanionPage` to tell
            // `addToCartClicked` that it should push to the companion
            // product page for configuration next
          } else {
            doNTimes(quantity, () => this.props.addToCart(companionProduct))
          }
        }

        return { variant, reservationInfo }
      }
    )

    await Promise.all(itemsAddedToCartP)

    if (error) {
      this.setState({ errorMessage: error })
      return
    }
    this.handleClose({ addToCartClicked: true })
  }

  setTypeAndQuantity({ type, quantity }) {
    const itemQuantities = this.state.itemQuantities
    const nextItemQuantities = { ...itemQuantities, [type]: quantity }

    this.setState({
      itemQuantities: nextItemQuantities,
      perItemRequiredData: this.state.perItemRequiredData || [],
    })
  }

  // TODO de-dupe with `Affinity`
  handleDiscount(event, index, value, resetPrice = false) {
    const product =
      (this.props && this.props.product) || (this.state && this.state.affinity)

    const {
      price,
      originalPrice,
      _originalPrice,
      nonDiscountedAmount,
    } = product.data

    const currentPrice = _originalPrice || price

    const discount =
      (currentPrice - (nonDiscountedAmount || 0)) * parseFloat(value) / 100

    const nextPrice =
      value === '-1'
        ? _originalPrice || originalPrice || price
        : currentPrice - discount

    const overrideType = event.target.innerText

    const discountToAdd = {
      [overrideType]: price - nextPrice,
    }

    const nextDiscounts = resetPrice
      ? product.data.discounts
      : {
          ...R.omit(
            [this.state.lastSelectedOverrideType],
            product.data.discounts
          ),
          ...discountToAdd,
        }

    const nextTicketType = overrideType.match(/Member/)
      ? 'Member'
      : overrideType.match(/Child/) ? 'Child' : 'Adult'

    const nextProductPrice = resetPrice
      ? {
          _originalPrice: _originalPrice || price,
          price: nextPrice,
          originalPrice: nextPrice,
          selected_price_override_type: overrideType,
        }
      : {}

    const nextData = {
      priceOverride: value,
      lastSelectedOverrideType: overrideType,
      product: product.withData({
        ...nextProductPrice,
        visualPrice: nextPrice,
        discounts: nextDiscounts,
        ticket_type: nextTicketType,
      }),
    }

    this.setState(nextData)
  }
}

const mapStateToProps = state => {
  const isMember = state.account && state.account.isMember
  const memberAffinities =
    isMember &&
    R.flatten(
      R.map(m => m.affinities, R.path(['user', 'memberships'], state.account))
    )

  return {
    products: state.products,
    account: state.account,
    isVE: state.account && state.account.isVE,
    isDev: state.account && state.account.isDev,
    isMember,
    memberAffinities,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addToCart,
      modifyProductData,
      triggerCart,
      triggerCompanionPage,
      addReservationInfo,
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(SingleProductSelect)

const doNTimes = (n, f) => {
  while (n-- > 0) f()
}

class RequiredDataSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      itemsData: [],
    }
  }

  render() {
    const { product, itemQuantities } = this.props
    const { dataNeeded } = product.data
    // Because date and time apply across all tickets
    // also ignore selected_sliding_scale - that's selected elsewhere
    const dataNeededExcludingDateAndTime =
      dataNeeded &&
      dataNeeded.filter(
        data => data !== 'selected_date' && data !== 'selected_time' && data !== 'selected_sliding_scale'
      )

    if (
      !itemQuantities ||
      !dataNeeded ||
      dataNeededExcludingDateAndTime.length === 0
    )
      return <span />

    const { perItemRequiredData } = this.state
    // prettier-ignore
    // because I can't get new Array(<length>) to work?
    const itemData = (perItemRequiredData || [])
      .concat([{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}])
      .slice(0, Math.max(parseInt(itemQuantities.adult), 1))

    const itemDataInputs = itemData.map((data, qtyIndex) =>
      dataNeededExcludingDateAndTime.map(requiredData => {
        if (
          requiredData === 'selected_time' ||
          requiredData === 'selected_time'
        )
          return <span />

        const selectedD = itemData[requiredData]
        const humanizedFieldName =
          requiredData && !requiredData.find
            ? requiredData.replace('selected_', '').replace('_', ' ') + ` ${qtyIndex + 1}`
            : false

        // TODO something wrong is happening here - when an organization name is chosen
        // requiredData becomes an array of all available org names, which breaks
        // this form?
        // For now quit rendering if an array is found under `requiredData`
        // Long term figure out where the data is getting set weirdly and fix it
        if(!humanizedFieldName) return null

        const _onChange = this.props.updateData(requiredData, qtyIndex)

        var input = <input type="text" onChange={_onChange} value={selectedD} />

        // TODO generalize this - pass a shape of required data, along with allowed options??
        if (requiredData === 'selected_meal') {
          input = (
            <AttributeChooser
              choices={product.data.require_meal}
              onChange={_onChange}
              product={product}
            />
          )
        }

        if (
          requiredData === 'selected_organization_name' &&
          product.data.require_organization_name &&
          product.data.require_organization_name.length
        ) {
          input = (
            <AttributeChooser
              choices={product.data.require_organization_name}
              onChange={_onChange}
              product={product}
            />
          )
        }

        // TODO generalize this - pass a shape of required data, along with allowed options??
        // …am I getting this de-duplicate message yet?
        if (requiredData === 'selected_invited_by') {
          input = (
            <AttributeChooser
              choices={product.data.require_invited_by}
              onChange={_onChange}
            />
          )
        }

        return (
          <p key={requiredData}>
            <label>
              {humanizedFieldName} {input}
            </label>
          </p>
        )
      })
    )

    return (
      itemDataInputs.length > 0 && (
        <div>
          <br />
          <h3>Required Information</h3>

          {itemDataInputs}
        </div>
      )
    )
  }
}

export const PriceOverrideSelector = ({
  handleDiscount,
  product,
  priceOverride,
  update,
}) => {
  const { entitlements, promos } = product.data

  const priceOverrides = promos ? R.concat(entitlements, promos) : entitlements

  if (!priceOverrides || priceOverrides.length === 0 || product.data.showSlidingScale) return <span />

  function _handleClearDiscountsClick(event) {
    this.data.clearDiscounts = new Set([
      'fixedReducedPriceWithMembership',
      'upToTwoFreeWithMembership',
      'percentageDiscountWithMembership',
      priceOverride,
      ...Object.keys(this.data.discounts),
    ])
    update({ priceOverride: undefined })
  }
  function _handleRestoreDiscountsClick(event) {
    this.data.clearDiscounts = undefined
    this.data.discounts = {}
    update({ priceOverride: undefined })
  }

  // debugger
  // TODO this should actually replace the default discount with the price overridden discount?
  if (
    !priceOverride &&
    product.data.discounts &&
    Object.keys(product.data.discounts).length > 0
  ) {
    return (
      <div>
        <p title={`current discount: ${priceOverride}`}>
          Price override is unavailable for already discounted tickets.
        </p>
        <button onClick={_handleClearDiscountsClick.bind(product)}>
          Clear Discounts
        </button>
      </div>
    )
  }

  return (
    <div>
      <SelectField
        floatingLabelText="Price Override"
        value={priceOverride}
        onChange={handleDiscount}
        autoWidth={true}
      >
        <MenuItem value={'-1'} primaryText={'Full Price'} />
        {priceOverrides.filter(po => po).filter(po => !po.hideFromVE).map((override, index) => {
          // HACK! the material UI select highlights items by their value.
          // This appends a distinct letter to the end of each number
          // that gets removed by `parseInt` when applying discount percentage
          const char = String.fromCharCode(97 + index)
          const { price_override, entitlement_name, promo_name } = override
          const name = entitlement_name || promo_name

          return (
            <MenuItem
              value={price_override + char}
              primaryText={`${name}`}
              key={name + index}
            />
          )
        })}
      </SelectField>
      <p>${product.price}</p>

      {product.data.clearDiscounts && (
        <span>
          Automatic discount has been cleared.{' '}
          <button onClick={_handleRestoreDiscountsClick.bind(product)}>
            Restore it?
          </button>
        </span>
      )}
    </div>
  )
}

const RemainingCapacityMessage = ({
  capacityRemaining,
  product,
  reservationInfo,
  isVE,
  soldOut,
  event_logistics,
}) => {
  const soldOutMessage = remaining => {
    const hasReservation =
      reservationInfo && reservationInfo.queue_ie && !reservationInfo.error
    const reservationMsg = hasReservation ? '. You have 1 ticket reserved.' : ''
    if (soldOut || remaining < 1) return `Sold Out${reservationMsg}`
    if (remaining < 15) return `Few tickets remaining${reservationMsg}`
  }

  const initialCapacity = product.data.initialCapacity

  const remainingCapacityMessageVE = (
    <p>
      {capacityRemaining !== undefined
        ? `Capacity remaining: ${capacityRemaining} / ${initialCapacity}`
        : ''}
      <br />
      {soldOutMessage(capacityRemaining)}
    </p>
  )

  if(isVE) {
    return <p>
      {isNaN(capacityRemaining) ? '' : remainingCapacityMessageVE}
      {event_logistics && <a href={event_logistics}>{event_logistics}</a>}
    </p>
  }

  return <p>{soldOutMessage(capacityRemaining)}</p>
}

const MyMiaTourChooser = ({
  product,
  updateTour,
  chosenTour,
  capacityInfo,
}) => {
  const { selected_date, myMiaDays, myMiaTourProduct } = product.data

  // not My Mia days
  const isMyMiaDay =
    myMiaDays &&
    myMiaDays.find(d =>
      moment(d + '/2018').isSame(moment(selected_date), 'day')
    )

  const tour = myMiaTourProduct.withData({ selected_date })
  tour.applyRules()
  const { availableTimes } = tour.data

  if (isMyMiaDay) {
    chosenTour && updateTour(null)
    return <p>No tours are available on your selected date</p>
  }

  const addTourWithTime = event => {
    updateTour(tour.withData({ selected_time: event.target.value }))
  }

  chosenTour && chosenTour.valid()

  const { museumClosed } = chosenTour ? chosenTour.data.errors : {}
  let tourErrorMessages = [museumClosed].filter(err => !!err)

  const chosenTime =
    chosenTour && chosenTour.data && chosenTour.data.selected_time
  const timeCapacityRemaining =
    chosenTime &&
    capacityInfo &&
    capacityInfo[toWorldTime(chosenTime).replace(':', '')]
  const tourCapacityRemaining =
    timeCapacityRemaining && timeCapacityRemaining.public_tour

  const soldOutTourTimes = capacityInfo
    ? R.keys(
        R.pickBy((value, key) => {
          const remaining = value['public_tour']
          const isSoldOut = R.isNil(remaining) || remaining < 1

          return isSoldOut
        }, capacityInfo)
      )
    : []

  const tourSoldOut =
    (chosenTour && R.isNil(tourCapacityRemaining)) || tourCapacityRemaining < 1
  if (tourSoldOut) {
    tourErrorMessages.push('This tour has sold out')
    const prevTour = chosenTour
    const nextTour = chosenTour.withData({ valid: false })
    if (prevTour.data !== nextTour.data) updateTour()
  } else {
    // chosenTour && updateTour(chosenTour.withData({valid: true}))
  }

  const tourErrorMessageString = tourErrorMessages.join('; ')

  false &&
    console.info('MyMiaTourChooser', {
      capacityInfo,
      selected_date,
      chosenTour,
      chosenTime,
      tourCapacityRemaining,
      tourSoldOut,
      tourErrorMessages,
      soldOutTourTimes,
    })

  return (
    <div>
      <label>
        Choose a tour {'  '}
        <AttributeChooser
          choices={availableTimes}
          onChange={addTourWithTime}
          extraInfo={(time, choice) => {
            const worldTime = toWorldTime(time).replace(':', '')
            if (soldOutTourTimes.indexOf(worldTime) > -1) return ' - Sold Out'
          }}
          disabledChoices={soldOutTourTimes}
        />
      </label>

      {chosenTour && chosenTour.valid() && !tourSoldOut ? (
        <p>
          We will see you then! Tours meet at the 24th Street entrance on the
          second floor
        </p>
      ) : (
        <p style={{ color: 'red' }}>{tourErrorMessageString}</p>
      )}
    </div>
  )
}

class CompanionProductChooser extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      capacity: 0,
    }
  }

  companionStillAvailable() {
    const { product: prodGiven, parentProduct } = this.props

    const parentSelectedDate = parentProduct.data.selected_date
    const companionEndDate = prodGiven.data.end_date_time

    return companionEndDate ? moment(parentSelectedDate).isBefore(companionEndDate) : true
  }

  async componentDidMount() {
    const { product: prodGiven, chosenProduct, updateProduct } = this.props
    const product = prodGiven.data.friendsLectureTourProduct || prodGiven
		const companionStillAvailable = this.companionStillAvailable()
    false && console.info('Companion cDM', { product, companionStillAvailable })

    const preloadCompanionExhibition = true && window.location.search && !!window.location.search.match('exhibition=true')
    if(preloadCompanionExhibition && !chosenProduct) {
      updateProduct(product)
    } else {
      updateProduct(false)
    }

    if (!product.data.initialCapacity || !product.data.initialCapacity || product.data.product_type === 'LOBBY_DONATION') {
      return this.setState({ capacity: false })
    }

    const { capacityRemaining: productCapacityRemaining } = await getCapacity(
      product.data.id
    )

    this.setState({ capacity: productCapacityRemaining })
  }

  render() {
    const { product, updateProduct, chosenProduct } = this.props
    const { capacity } = this.state
		const companionStillAvailable = this.companionStillAvailable()

    const tour = product.data.friendsLectureTourProduct || product

    const addTourToProduct = (event, isChecked) => {
      updateProduct(isChecked ? tour : null)
    }

    const isAvailable = companionStillAvailable &&
			(product.data.type === 'ongoing' || product.data.product_type === 'LOBBY_DONATION' || capacity > 0)

		if(!companionStillAvailable) {
      const companionIsRequested = window.location.search.match(/exhibition/) || chosenProduct
      return companionIsRequested
        ? <p>An exhibition ticket for this date is not currently available.</p>
        : null
    }

    const companionIsDonate = product.data.name === 'Donate'
    const companionCTA = companionIsDonate
      ? 'Also make a donation to Mia'
      : `Add "${product.data.name}" tickets next`

    return (
      <div>
        <h3>{companionCTA}</h3>

        {isAvailable ? (
          <div>
            <Checkbox
              label={
                product.data.summary || product.data.body || product.data.__content ||
                'And reserve a spot for the pre-lecture tour'
              }
              checked={!!chosenProduct}
              onCheck={addTourToProduct}
            />

            {capacity && <p>{capacity} spots remaining</p>}

            {companionIsDonate || <PricingMatrix
              product={product}
              style={{margin: '1em 0 0.7em'}}
              fullSummary={`Tickets: $20, My Mia Member $16, Investor+ Free, Youth 17 and Under Free`}
            />}
          </div>
        ) : (
          <p>Tour is sold out</p>
        )}

        <br />
      </div>
    )
  }
}

const PromoCodeSelector = ({ product, updateCode }) => {
  const { promos, selected_promo } = product.data
  const promoApplied = product.data.promo
  const promo = promoApplied || promos && promos.find(p => p.promo_slug === selected_promo)

  // TODO generalize this - how to selectively require data,
  // only when a promo asks for it??
  if (product.data.require_organization_name) {
    product.rules.push(
      requireData(
        'selected_organization_name',
        product.data.require_organization_name
      )
    )
  }

  if (promoApplied)
    return (
      <div>
        <p>
          Promo successfully applied. Choose a date to attend how many tickets
          you'd like below.
          {promo.max_per_order && ` Maximum: ${promo.max_per_order}.`}
        </p>
      </div>
    )

  return true || promo ? (
    <div>
      <p>{promo && promo.message} If you have been given a promo code, enter it here:</p>
      <label>
        Promo Code{' '}
        <input
          type="text"
          name="promoCode"
          id="promoCode"
          size="30"
          onChange={updateCode}
        />
      </label>
    </div>
  ) : (
    <span />
  )
}


/* TODO
 *
 * choose which pricing levels get shown here…
 *   based on some entitlements being `public`?
 * reflect the applied entitlement, sort it first in the list?
 *   what if it's a promo vs discount?
 * show info on "limited income" discounts
 * better styling
 * enable on the catalog page
 * show for all products? (currently only for HOOP as a test)
 */
const PricingMatrix = (props) => {
  const {product, expanded, fullSummary} = props // handleToggle
  const priceWithDiscounts = product.price
  const priceString = priceWithDiscounts > 0 ? `$${priceWithDiscounts}` : 'Free'

  if(product.data.product_type !== 'exhibition') return <h3>
    {priceString}
  </h3>

  const allPricings = product.data.entitlements.map(ent => {
    const {price} = product.data
    const discountedPrice = price - price*ent.price_override/100
    return [ent.entitlement_name, discountedPrice]
  })
  const publicPricings = [['General Admission', product.data.base_price/100]]
    .concat(allPricings.slice(0, 3))
  const hasPriceDifference = publicPricings[0][1] !== publicPricings[1][1]

  const styles = {
    ...props.style,
    paddingLeft: 0, // TODO remove the disclosure triangle?
    backgroundImage: 'none',
    webkitAppearance: 'none',
  }

  return <>
    <details style={styles} open={!!(hasPriceDifference && expanded)}>
      <summary>{fullSummary || priceString}</summary>
      <table>{publicPricings.map(([name, price]) => <tr>
        <td style={{minWidth: '13em'}}>{name}</td>
        <td>{price === 0 ? 'Free' : `$${price}`}</td>
      </tr>)}</table>
    </details>
  </>
}

/* Allow quick selection from today to the Nth specified day ahead
 */
const QuickSentenceDatePicker = (props) => {
  const setDate = (date) => props.setProductData(date, 'selected_date')
  const daysAhead = props.daysAhead || 3
  const { validDate, selected } = props
  const firstDate = moment.max(
    moment(props.startDate),
    moment()
  )
  const candidateDates = new Array(daysAhead+2)
    .fill('')
    .map((_, index) => moment(firstDate).add(index, 'd'))
  const numInvalidDatesInRange = candidateDates.filter(d => !validDate(d)).length
  const padNumAhead = numInvalidDatesInRange

  let dates = candidateDates
    .slice(0, daysAhead+padNumAhead)

  const showFutureSelectedDate = selected && moment(selected).isAfter(dates[dates.length-1], 'day')

  if(showFutureSelectedDate)
    dates = dates.concat(moment(selected))
  
  return <>
    {dates.map((date, index) => {
      const dayName = date.format('dddd')
      let colloquialDayName = date.calendar(null, calendarFormats)
      const isSelected = selected && date.isSame(selected, 'day')
      const isValid = validDate(date)
      const isLastEntry = index === dates.length-1 

      const dayStyle = {
        ...(!isValid ? {color: '#cecece', cursor: 'not-allowed'} : {}),
        ...(isSelected ? {textDecoration: 'underline'} : {}),
      }

      if(!isValid && isLastEntry) return false

      return <>
        {!isValid
          ? <span style={dayStyle}>{dayName}</span>
          : <a style={dayStyle} href={'#'+colloquialDayName} onClick={() => setDate(date)}
               title={date.format(formatString)}
            >{colloquialDayName}</a>}
        {isLastEntry ? '' : ' '}
      </>
    })}
  </>
}
const formatString = 'dddd, MMMM Do YYYY'
const calendarFormats = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameElse: formatString
}

const InfoMessages = ({messages}) => {
  return messages ? <>
    {Object.values(messages)
      .map((info, index) => (
        <p
          key={info + index}
          style={{ clear: 'both', color: 'green' }}
        >
          {info}
        </p>
      ))}
  </> : null
}

const VisualBreak = (props) => <hr style={{ visibility: 'hidden', margin: props.height || '1em'}} />
