import { unregister } from './registerServiceWorker'

import React, {Suspense} from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import store, { history } from './store'
import App from './containers/app'

const target = document.querySelector('#root')
const wrapApp = App =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Suspense fallback={<p>…loading…</p>}>
        <App />
      </Suspense>
    </ConnectedRouter>
  </Provider>

render(wrapApp(App), target)

if (module.hot) {
  module.hot.accept('./containers/app', () => {
    const NextApp = require('./containers/app').default
    render(wrapApp(NextApp), target)
  })
}

// function PreloadLazyComponents() {
//   useEffect(() => {
//     import('./components/template/Catalogue')
//     import('./components/product-card')
//     import('./components/mia-styleguide/Header')
//     import('./components/mia-styleguide/Footer')
//   }, [])
// 
//   return <span />
// }

unregister()
