import React from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import Tours from '../tours/Tours'

export default class SingleProductSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }
  handleOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    return (
      <div>
        <div className="row sixths">
          <div
            className="col-2 grid-full"
            style={{ backgroundColor: 'transparent' }}
          >
            <FlatButton
              label="Tour Fee"
              backgroundColor="#232323"
              style={{ color: '#ffffff' }}
              onClick={this.handleOpen}
            />
          </div>
        </div>
        <Dialog
          title="Tour Fee"
          modal={false}
          open={this.state.open}
          onRequestClose={this.handleClose}
          autoScrollBodyContent={true}
          contentClassName="customWidth"
        >
          <Tours closeDialog={this.handleClose.bind(this)} />
        </Dialog>
      </div>
    )
  }
}
