import React from 'react'
import { connect } from 'react-redux'
import { Link, NavLink } from 'react-router-dom'
import R from 'ramda'

import {AffinityDialog} from './Memberships'
import TourFees from './TourFees'
import ProductCard from '../product-card'
import Deprecated from '../DeprecatedRedirect'

import products from '../../data/productsJson.js'

const slug = string => string.toLowerCase().replace(/ /g, '-')

const AIBRegex = /AIB.*2021|2021.*AIB/

/*
 * Show a group of products along with a title.
 * Render and children before the grid of grouped products
 *
 * "group hero" goes here if asked for in group configuration (not shown to VE)
 * | Group Title
 * -------------
 * | {children}
 * | prod 1 | prod 2 |
 * | prod 3 |   …    |
 * | {children go here if showChildrenLast}
 * -------------
 */
function GroupedProducts(props) {
  const { title, products, children, search, compactView, showGroupHero, ...rest } = props
  const { showChildrenLast, showChildrenInside, hideWhenEmpty, showTopHeader } = rest

  const filteredProducts = search
    ? products.filter(({ data }) =>
        [data.name]
          .join(' ')
          .toLowerCase()
          .match(search.toLowerCase())
      )
    : products

  let heroProduct = undefined
  if(showGroupHero) {
    heroProduct = filteredProducts.find(p => p.data.hero)
  }

  const hasChildren = !!children
  const suppress =
    (typeof props.conditions !== 'undefined' && !props.conditions) ||
    (search && filteredProducts.length === 0) ||
    (hideWhenEmpty && filteredProducts.length === 0 && !hasChildren)

  if (suppress) return props.message ? <p>{props.message}</p> : <span />

  const _slug = slug(title)
  const childPage = _slug ? `/catalog/${_slug}` : `/catalog/`

  const breakAfterNum = !compactView ? 1000 : window.innerWidth > 1000 ? 6 : 3

  const showingProducts =
    true && props.single
      ? filteredProducts
      : filteredProducts.slice(0, breakAfterNum)

  const linkToMore = !props.single ? (
    filteredProducts.length > breakAfterNum && (
      <div
        className="col-3 col cell-container"
        style={{
          paddingTop: '0.3em',
          clear: 'both',
        }}
      >
        <h3>
          And {filteredProducts.length - showingProducts.length} more {title}
        </h3>
        <ul style={{ listStyle: '' }}>
          {filteredProducts.slice(breakAfterNum).map(({ data }) => (
            <li key={data.id}>
              <Link to={`/products/${data.slug}`}>{data.name}</Link>
            </li>
          ))}
        </ul>
        <Link to={childPage}>Show all {title}</Link>
      </div>
    )
  ) : (
    <span />
  )

  const inlineStyles = {
    width: '31%',
    float: 'left',
    margin: '1%',
  }

  const groupStyles = {
    ...props.style,
    ...(props.inline ? inlineStyles : { clear: 'both' }),
  }

  return (
    <section id={_slug} style={groupStyles}>
      {!compactView && heroProduct && <HeroBox heroProduct={heroProduct} />}
      {title !== '' && (
        <h2 className="border-top-3">
          <Link to={childPage}>{title}</Link>
        </h2>
      )}

      {!children &&
        filteredProducts.length === 0 && (
          <p>
            There aren't any {title} on sale right now.
            <hr style={{ visibility: 'hidden' }} />
          </p>
        )}

      {!!children && !showChildrenLast && !showChildrenInside && children}
      <div
        className={props.inline || `row ${compactView ? 'sixths' : 'thirds'}`}
      >
        {!!children && !showChildrenLast && showChildrenInside && children}

        {showingProducts.map(product => (
          <ProductCard
            key={product.id}
            product={product}
            stepBack={props.single && _slug}
            compact={compactView}
            showTopHeader={showTopHeader}
          />
        ))}

        {!!children && showChildrenLast && children}

        {linkToMore}
      </div>
    </section>
  )
}

const sortByDate = R.sortBy(p => new Date(p.data.start_date_time))
const sortByDateAndPriority = R.compose(
  R.sortBy(p => {
    const sort = typeof(p.data.sortPriority) !== 'undefined'
      ? Boolean(p.data.sortPriority) ? -1 : 1
      : 0

    return sort
  }),
  sortByDate
)

class Catalogue extends React.Component {
  constructor(props) {
    super(props)
    this.state = { search: undefined }
  }

  render() {
    const { isVE, isDev } = this.props
    const currentAndUpcomingProducts = products
      .filter(p => !p.data.sold_out)
      .filter(p => {
        let { id, checkSaleDates, onSaleDate } = p.data
        if (id.match('TLK20180622')) {
          const { point_of_sale } = p.data
          // TODO this only differentiates VE from non-VE. If we ever need different
          // `onSaleDate`s for PHONE and LOBBY this needs more work
          const pos = point_of_sale.find(
            pos => pos.location === (isVE ? 'PHONE' : 'WEB')
          )
          onSaleDate = onSaleDate || (pos ? pos.on_sale_date : 0)
          checkSaleDates = !!pos
        }

        // Special Treatment for AIB events in dev before their
        // production onSaleDate - bump them up so they show normally for
        // testing.
        //
        // TODO should this apply to all products in dev?
        if(isDev && p.data.id.match(AIBRegex)) {
          p.data.onSaleDate = '2020-02-01T09:00:00'
          onSaleDate = new Date()
        }

        return checkSaleDates ? new Date(onSaleDate) <= new Date() : true
      })
      .filter(p => {
        const { checkSaleDates = false, offSaleDate } = p.data || {}
        // if offSaleDate is defined, only show products where that date
        // is in the future.
        //
        // Probably need to use `moment` to get the date comparisons right?
        const isOffSale = checkSaleDates && offSaleDate
          ? new Date(offSaleDate) < new Date()
          : false

        return !isOffSale
      })
      .filter(p => {
        return new Date(p.data.end_date_time) > new Date()
      })

    const futureProducts = products.filter(p => !p.data.sold_out).filter(p => {
      const { checkSaleDates, onSaleDate } = p.data
      return checkSaleDates ? new Date(onSaleDate) >= new Date() : false
    })

    const filterDevOnly = p =>
      p.data.devOnly
        ? process.env.NODE_ENV === 'development' ||
          window.location.host === 'dev-ticket.artsmia.org'
        : true

    const listedProducts = currentAndUpcomingProducts
      .filter(p => !p.data.unlisted)
      .filter(p => !p.data.group_id)
      .filter(filterDevOnly)
      .filter(p => {
        const { search } = this.state
        return !search || p.data.title.match(new RegExp(search, 'i'))
      })

    const unlistedProducts =
      isVE &&
      products
        .filter(p => listedProducts.indexOf(p) < 0)
        .filter(p => !p.data.group_id)
        .filter(
          p =>
            p.data.devOnly
              ? process.env.NODE_ENV === 'development' ||
                window.location.host === 'dev-ticket.artsmia.org'
              : true
        )
        //.filter(({ data }) => {
        //  // not a membership
        //  return (
        //    ['mia_membership', 'affinity_level'].indexOf(data.product_type) < 0
        //  )
        //})
        .filter(({ data }) => {
          const endDate = new Date(data.end_date_time)

          return endDate
            ? endDate > new Date(+new Date() - 12096e5) // two weeks ago
            : true
        })

    if(isVE) {
      const giftMembership = products.find(p => p.id === 'GIFT_MEMBERSHIP')
      unlistedProducts.unshift(giftMembership)
    }

    const productGroups = [
      {
        title: '', // unnammed top section
        products: products
          .filter(
            ({ data }) => {
              // show all matching products in dev, but obey onSaleDate in production when one is provided
              const isOnSale = isDev || (data.onSaleDate ? new Date(data.onSaleDate) < new Date() : true)

              // show products that are:
              // 1. an exhibition that is on sale (depending on environment)
              // 2. membership and 3. donation
              // 4. VE Only: Gift Membership and GA rush
              return (!data.unlisted || (isDev && data.devOnly)) &&
              ((data.product_type === 'exhibition' &&
                new Date(data.end_date_time) > new Date() && isOnSale) ||
                data.product_type === 'mia_membership' ||
                data.id === 'DONATE')
          })
          .reverse()
          .concat(
            this.props.isVE
              ? [
                products.find(p => p.id === 'GEN-FY21-002'),
                ...unlistedProducts.filter(p => p.data.id.match(/EX2020/)),
                products.find(p => p.id === 'GIFT_MEMBERSHIP'),
              ]
              : []
          ),
        children: this.props.isVE ? <AffinityDialog products={products} /> : <span />,
        showChildrenLast: true,
        showTopHeader: !this.props.isVE,
        showGroupHero: true,
      },
      {
        title: 'Visit Mia',
        products: listedProducts
          .filter(p => p.data.product_type === 'exhibition' && !p.data.unlisted)
          .concat(
            this.props.isVE
              ? currentAndUpcomingProducts.filter(p => p.data.id.match(/EX2020-002/))
              : []
          ),
        showTopHeader: false,
        hideWhenEmpty: true,
        disabled: true
      },
      {
        title: 'Support Mia',
        products: products
          .filter(filterDevOnly)
          .filter(({data}) => {
            return !data.unlisted && (data.product_type === 'mia_membership' || data.id === 'DONATE')
          }),
        showTopHeader: false,
        hideWhenEmpty: true,
        children: this.props.isVE ? <AffinityDialog products={products} /> : null,
        showChildrenLast: true,
        showGroupHero: true,
        disabled: true
      },
      // {
      //   title: 'Exhibitions',
      //   products: listedProducts
      //     .filter(p => p.data.product_type === 'exhibition')
      //     .concat(
      //       this.props.isVE
      //         ? unlistedProducts.filter(p => p.data.id.match(/EX2019/))
      //         : []
      //     ),
      //   inline: true,
      // },
      // {
      //   title: 'My Mia Memberships',
      //   products: products.filter(
      //     ({ data }) => data.product_type === 'mia_membership'
      //   ),
      //   inline: true,
      // },
      // {
      //   title: 'Donate',
      //   products: products.filter(({ data }) => data.id === 'DONATE'),
      //   inline: true,
      // },
      {
        title: 'Talks',
        products: sortByDate(
          listedProducts.filter(
            p => (p.data.product_type === 'lecture' || p.data.group_product_type === 'lecture') && !p.data.id.match(/AIB/)
          )
        ),
        hideWhenEmpty: true,
      },
      {
        title: 'Art In Bloom',
        products: sortByDateAndPriority(
          listedProducts.filter(
            p => p.data.id.match(AIBRegex)
          )
        ),
        hideWhenEmpty: true,
      },
      {
        title: 'Classes',
        products: sortByDate(
          listedProducts.filter(
            p =>
              (p.data.product_type === 'class' && !p.data.group_id) ||
              p.data.group_product_type === 'class'
          ).filter(p => !p.data.id.match(/AIB/i))
        ),
        hideWhenEmpty: true,
      },
      {
        title: 'Special Events',
        products: sortByDate(
          listedProducts.filter(
            p =>
              p.data.product_type === 'special_event' && !p.data.id.match(/AIB/i)
          )
        ),
        hideWhenEmpty: true,
      },
      {
        title: isVE ? 'Tours & Tour Fees' : 'Tours',
        products: sortByDateAndPriority(listedProducts)
          .filter(p => p.data.product_type === 'tour')
          .filter(p => isVE || p.data.title !== 'Lunchroom Reservation'),
        children: !!isVE && <TourFees />,
        showChildrenLast: true,
        hideWhenEmpty: true,
      },
      {
        title: 'Friends Members',
        products: [
          products.find(p => p.data.id === 'FRIENDS_MEMBERSHIP'),
          ...sortByDateAndPriority(listedProducts.filter(
            p =>
              (p.data.gau_code && p.data.gau_code.match(/friends/i)) ||
              p.data.title.match(/friends lecture/i) ||
              p.data.title.match(/Bus Donations/) ||
              p.data.title.match(/Art in Bloom/i)
          )),
        ],
        // children: <FriendsMembership products={products} />,
        // showChildrenInside: true,
        hideWhenEmpty: true,
      },
    ]
    .filter(group => !group.disabled)
    .concat(
      isVE
        ? [
            {
              title: 'Unlisted Products',
              products: R.reverse(sortByDate(unlistedProducts)),
              children: (
                <p>
                  NOTE: Check with a manager before selling from the Unlisted Products section.
                </p>
              ),
            },
            {
              conditions:
                futureProducts &&
                !window.location.origin.match('ticket.artsmia.org'),
              message: 'Future Products are only visible on dev-ticket',
              title: 'Future Products',
              products: R.reverse(sortByDate(futureProducts)),
              children: (
                <p>
                  NOTE: These will go on sale in the future. And you should only
                  be seeing them on the dev site. Don't sell these!
                </p>
              ),
            },
          ]
        : []
    )

    const { group: singleGroup } = this.props.match.params
    const showProdGroups = singleGroup
      ? productGroups.filter(g => slug(g.title) === singleGroup)
      : productGroups

    const showNav = false
    const nav = (
      <nav style={{ marginTop: '1em' }}>
        <NavLink to="/">All Products</NavLink>
        {productGroups
          .filter(({ inline }) => !inline)
          .map(({ title, products, children, hideWhenEmpty }, index) => {
            const hasActiveProducts =
              products?.filter(p => !p.data.unlisted).length > 0
              || children

            if (!hasActiveProducts)
              return hideWhenEmpty
                ? null
                : <span style={{ opacity: '0.7', paddingRight: '1em' }}>
                  {title}
                </span>

            return (
              <NavLink
                key={title}
                to={`/catalog/${slug(title)}`}
                style={{ paddingRight: '1em' }}
                activeStyle={{ textDecoration: 'underline' }}
              >
                {title}
              </NavLink>
            )
          })}
        <input
          type="search"
          value={this.state.search}
          onChange={this.updateSearch}
          title="Search"
          style={{ backgroundSize: '20px', position: 'relative', top: '-5px' }}
        />
      </nav>
    )

    return (
      <Deprecated><div>
        {this.props.account.isVE || (
          <div>
            <Link to="/" style={{ paddingRight: '1em' }}>
              <h2 style={{ marginBottom: 0 }}>
                Ticketed Events and Registration
              </h2>
            </Link>

            <p>
              General admission is free, with no advance reservation required.
              Tickets are required for the special exhibitions and events shown below.
              (Some free programs still require tickets/reservations.)
              You may purchase or reserve online, or by phone at 612.870.3000.
            </p>
          </div>
        )}

        {showNav && nav}

        <hr style={{ visibility: 'hidden' }} />

        {showProdGroups.map(props => (
          <GroupedProducts
            key={props.title}
            {...props}
            search={this.state.search}
            single={!!singleGroup}
            inline={props.inline}
            compactView={isVE}
          />
        ))}
      </div></Deprecated>
    )
  }

  updateSearch = event => {
    this.setState({ search: event.target.value })
  }
}

const mapStateToProps = state => {
  return {
    account: state.account,
    isVE: state.account && state.account.isVE,
    isDev: state.account && state.account.isDev,
  }
}
export default connect(mapStateToProps)(Catalogue)

export function HeroBox({heroProduct, ...props}) {
  const heroBoxStyle = {
    display: 'flex', flexDirection: 'row-reverse',
    marginBottom: '2em', marginTop: '-2em',
  }

  if(!heroProduct.data) return null

  const { slug, hero } = heroProduct?.data ?? {}
  const { heading, subhead, buttonText, img, alt, title } = hero || {}
  console.info('HeroBox', {heroProduct, heading})

  return heroProduct && heading && <Link to={`/products/${slug}`} style={heroBoxStyle}>
    <div style={{width: '35%', justifySelf: 'center', alignSelf: 'center', marginLeft: '2em'}}>
      <h2 style={{fontSize: '2.3em'}}>{heading}</h2>
      <p style={{fontSize: '1.7em'}}>{subhead}</p>
      <button>{buttonText}</button>
    </div>
    <img
      src={img}
      alt={alt}
      title={title}
      style={{width: '65%', minHeight: 287, maxHeight: 379, objectFit: 'cover'}}
    />
  </Link>
}
