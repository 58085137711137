import React, { Component, Fragment } from 'react'
// import { Link } from 'react-router-dom'

const museumClosed = false
const InfoLink = (props) => <a style={linkStyles} href="https://new.artsmia.org/covid-19/">
  Learn more here
</a>

export default class MuseumClosedBanner extends Component {
  constructor(props) {
    super()

    this.state = {
      showBanner: true
    }
  }

  render() {
  // let [showBanner, setShowBanner] = useState(true)
  // ^^ TODO why isn't useState working? I think it's probably nextjs version related?
  // …For now convert this to a class Component
  // https://reactjs.org/warnings/invalid-hook-call-warning.html
  let showBanner = this.state.showBanner

  function closeBanner() {
    this.setState({showBanner: false})
  }
  function openBannerIfClosed() {
    showBanner || this.setState({showBanner: !this.state.showBanner})
  }

  const textEarly2020 = `
    In consideration of the health and welfare of visitors,
    volunteers, and staff, Mia is temporarily closed to the public.
  `.replace(/\n\s+/g, ' ')
  const text = `The museum is temporarily closed, and planning to reopen January 28.`

  return museumClosed && <div id="announcement" style={bannerStyles} title={showBanner ? '' : text} onClick={openBannerIfClosed.bind(this)}>
    {showBanner ? <Fragment>

    <p style={{fontSize: '1.1em'}}>
      {text}
      {' '}
      <InfoLink />.

      <button style={buttonStyles} onClick={closeBanner.bind(this)}>
        <span class="screenreaderonly">Close Alert</span>
      </button>
    </p>

    <style jsx>{`
      #announcement button:before, #announcement button:after {
        content: "";
        position: absolute;
        display: block;
        height: 0.1em;
        width: 1em;
        top: 50%;
        left: 50%;
        border-radius: 3px;
        background-color: white;
      }
      #announcement button:before {
        transform: translate(-50%,-50%) rotate(45deg);
      }
      #announcement button:after {
        transform: translate(-50%,-50%) rotate(-45deg);
      }

      #announcement button, #announcement .screenreaderonly {
        text-indent: -1000em;
        width: 1em;
        height: 1em;
        position: absolute;
      }

      #announcement p {
        min-height: 1em;
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;
      }

      @media (max-width: 1135px) {
        #announcement button {
          right: 2em;
        }

        #announcement p {
          padding: 0;
        }
      }
    `}</style>
    </Fragment> :
    undefined}
  </div>
}
}

const red = '#ef3535'
const textAlign = 'center'

const bannerStyles = {
  zIndex: 100,
  position: "sticky",
  top: 0,
  left: 0,
  right: 0,
  padding: '1em',
  paddingBottom: '0.1em',
  marginBottom: '0.5em',
  background: "white",
  backgroundColor: red,
  color: 'white',
  textAlign,
  fontSize: '1.1em',
}

const linkStyles = {
  color: 'white',
  fontWeight: 'bold',
  fontSize: '1em',
}

const buttonStyles = {
  backgroundColor: red,
  ...linkStyles,
}

const textStyles = {
  minHeight: '1em',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: '20px',
  paddingRight: '20px',
  maxWidth: 1280,
  textAlign,
  fontSize: '1.1em',
  fontFamily: 'Mia Regular',
}

export const MoreText = (props) => {
  const _textStyles = {
    ...textStyles,
    ...props.style,
    padding: 0,
    textAlign: 'left',
  }
  const _linkStyles = {
     ...linkStyles,
     ...props.style,
  }

  return <Fragment>
    <p style={_textStyles}>
      Mia looks forward to welcoming you back to our galleries and public spaces when we can.
      {' '}<a href="https://new.artsmia.org/covid-19/" style={_linkStyles}>
        Learn more
      </a>.
    </p>
  </Fragment>
}

// export default MuseumClosedBanner
