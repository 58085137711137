import React, { useEffect } from 'react'

const EmployerMatchingGiftAsk = (props) => {
  useEffect(() => {
    if (window.doublethedonation) {
      window.doublethedonation.plugin.load_streamlined_input()
    }
	}, [])

  // if(!props.isDev) return null

  return <div>
    <strong style={{marginBottom: '1em !important'}}>Make your gift go further.</strong>
    <br />
    <div id="dd-company-name-input"></div>
    <p style={{marginTop: '1em'}}>Matching gifts help maximize Mia's corporate support. Many employers match charitable contributions made by employees, retirees, and board members.</p>
    <p>Check to see if your company has a matching gift program and take a moment to process a gift match for your contribution to Mia. Thank you!</p>
  </div>
}

export default EmployerMatchingGiftAsk

export function getDTDCompanyInfo() {
  const dtdInputs = document.querySelectorAll('.dtd-streamlined-plugin input')
  const dtdInfoRaw = [...dtdInputs].reduce((dtdInfo, input) => {
    return { ...dtdInfo, [input.name]: input.value }
  }, {})
  const hasMatchingCompany = dtdInfoRaw && dtdInfoRaw.doubledonation_status === 'found'

  const dtdInfo = hasMatchingCompany ? {
    companyId: dtdInfoRaw.doublethedonation_company_id,
    companyName: dtdInfoRaw.doublethedonation_company_name,
  } : {}
  console.info({dtdInfo})
  debugger

  return dtdInfo
}
